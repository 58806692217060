import { Container, Spinner, SpinnerProps } from "react-bootstrap";

export interface ConditionalSpinnerProps extends SpinnerProps {
    isLoading: boolean;
    containerClassName?: string;
}

function ConditionalSpinner(props: ConditionalSpinnerProps) {
    if (props.isLoading) {
        const spinnerProps = { ...props };
        delete spinnerProps.children;
        delete spinnerProps.isLoading;

        const containerClassName = props.containerClassName ?? "text-center py-5";

        return (
            <Container className={containerClassName}>
                <Spinner {...spinnerProps} animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return <>{props.children}</>;
}

export default ConditionalSpinner;
