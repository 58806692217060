import { DataOperation, VizContext } from "./DataOperation";
import EditTagObjectModal from "../../../components/dataOperationModals/editTagObjectModal/EditTagObjectModal";
import { GlobalVideoImageSelector } from "../../../utilities/videoImageSelector/VideoImageSelector";
import { Button } from "react-bootstrap";
import { convertCropToBounds } from "../../../utilities/helpers";
import NiceModal from "@ebay/nice-modal-react";

export class CreateTagObjectDataOperation extends DataOperation {
    constructor() {
        super();
        this.operationId = "createTagObject";
        this.displayName = "Create Tag Object";
        this.icon = {
            type: "image",
            url: "/images/icons/tag-snapshot_56.png",
        };
        this.priority = "primary";
        this.context = "global";
        this.category = "tags";
        NiceModal.register(this.operationId, EditTagObjectModal);
    }

    canExecute(context: VizContext) {
        const entity = context.view?.entities[0];
        if (context.container.hasInlineCropSelector) {
            return entity?.sourceObject?.mediaSource !== undefined;
        } else {
            return entity !== undefined;
        }
    }

    execute(context: VizContext) {
        if (context.container.hasInlineCropSelector) {
            GlobalVideoImageSelector.beginSelection(() => (
                <div className="gap-2 d-flex">
                    <Button variant="secondary" onClick={GlobalVideoImageSelector.endSelection}>
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        onClick={() =>
                            NiceModal.show(this.operationId, {
                                context,
                                selection: convertCropToBounds(GlobalVideoImageSelector.completeSelection()),
                            })
                        }
                    >
                        Confirm
                    </Button>
                </div>
            ));
        } else {
            NiceModal.show(this.operationId, { context });
        }
    }
}
