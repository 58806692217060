import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TagEventsDataSource } from "../../../models/viz/dataSources/TagEventsDataSource";
import { TagObjectsDataSource } from "../../../models/viz/dataSources/TagObjectsDataSource";
import { ApplicationState } from "../../../store";
import * as AnalyticsStore from "../../../store/analytics/Analytics";
import { DataViz } from "../../viz/DataViz";
import { RepresentationSelector } from "../../viz/components/Shared/RepresentationSelector";
import "./Tags.scss";
import { useView } from "../../../hooks/useView";
import { useGetAllTagEventsQuery, useGetAllTagObjectsQuery } from "../../../store/api/kinesense";

const TAG_EVENT_VIEW_ID = "tagEvents" as const;
const TAG_OBJECT_VIEW_ID = "tagObjects" as const;

// Custom view management for Tags page
function useManageTagsViews() {
    const { analytics, general } = useSelector((state: ApplicationState) => state);
    const projectId = general?.activeProjectId;
    const views = analytics?.views;

    const [activeViewId, setActiveViewId] = useState<string | undefined>(undefined);
    const { view } = useView(activeViewId);
    const dispatch = useDispatch();

    const { data: tagEvents } = useGetAllTagEventsQuery({ projectId }, { skip: projectId === undefined });
    const { data: tagObjects } = useGetAllTagObjectsQuery({ projectId }, { skip: projectId === undefined });

    // Create static views for tag events and objects
    useEffect(() => {
        if (projectId === undefined) {
            return;
        }

        const objectsDataSource = new TagObjectsDataSource(projectId, dispatch);
        dispatch(
            AnalyticsStore.actionCreators.addView(objectsDataSource, false, "Tag Objects", projectId, {
                viewId: TAG_OBJECT_VIEW_ID,
                // Generate new view when changing projects
                forceReplace: true,
            }),
        );

        const eventsDataSource = new TagEventsDataSource(projectId, dispatch);
        dispatch(
            AnalyticsStore.actionCreators.addView(eventsDataSource, false, "Tag Events", projectId, {
                viewId: TAG_EVENT_VIEW_ID,
                forceReplace: true,
            }),
        );

        if (activeViewId === undefined) {
            setActiveViewId(TAG_OBJECT_VIEW_ID);
        }
    }, [projectId]);

    // Load view data
    useEffect(() => {
        if (view?.data.state == "unloaded") {
            dispatch(AnalyticsStore.actionCreators.loadViewData(activeViewId));
        }
    }, [view?.data.state]);

    // Sync view entities with newly loaded tag events
    useEffect(() => {
        if (tagEvents !== undefined && views?.[TAG_EVENT_VIEW_ID]?.entities?.length !== tagEvents.length) {
            dispatch(AnalyticsStore.actionCreators.loadViewData(TAG_EVENT_VIEW_ID, true));
        }
    }, [tagEvents?.length, views?.[TAG_EVENT_VIEW_ID]?.entities.length]);

    // Sync view entities with newly loaded tag objects
    useEffect(() => {
        if (tagObjects !== undefined && views?.[TAG_OBJECT_VIEW_ID]?.entities?.length !== tagObjects.length) {
            dispatch(AnalyticsStore.actionCreators.loadViewData(TAG_OBJECT_VIEW_ID, true));
        }
    }, [tagObjects?.length, views?.[TAG_OBJECT_VIEW_ID]?.entities.length]);

    return {
        activeViewId,
        setActiveViewId,
    };
}

// NOTE: This component / route uses custom view management, as it only needs one view for objects and one view for events per project.
export function Tags() {
    const { activeViewId, setActiveViewId } = useManageTagsViews();

    return (
        <DataViz viewId={activeViewId}>
            <div className="tags-header">
                <span className="gap-2 tags-views d-flex align-items-center justify-content-center">
                    <Button
                        title="Tag Events"
                        size="sm"
                        variant="secondary"
                        onClick={() => setActiveViewId(TAG_EVENT_VIEW_ID)}
                        className={`btn-viz-data-description${TAG_EVENT_VIEW_ID === activeViewId ? " selected" : ""}`}
                    >
                        Events
                    </Button>
                    <Button
                        title="Tag Objects"
                        size="sm"
                        variant="secondary"
                        onClick={() => setActiveViewId(TAG_OBJECT_VIEW_ID)}
                        className={`btn-viz-data-description${TAG_OBJECT_VIEW_ID === activeViewId ? " selected" : ""}`}
                    >
                        Objects
                    </Button>
                </span>

                <RepresentationSelector viewId={activeViewId} />
            </div>
        </DataViz>
    );
}
