import { Container, Form } from "react-bootstrap";
import OrganisationsList, { OrganisationsSortBy } from "./OrganisationsList";
import "./ControlledOrganisationsList.scss";
import useControlledList, { ControlledListInputs } from "../../../../hooks/useControlledList";
import RefreshButton from "../../../../components/RefreshButton";
import { useAdminGetOrganisationsQuery } from "../../../../store/api/kinesense";

export interface ControlledOrganisationsListProps {
    maxItems?: number;
}

function ControlledOrganisationsList(props: ControlledOrganisationsListProps) {
    const control = useControlledList<OrganisationsSortBy>("name", true);
    const { refetch, isFetching } = useAdminGetOrganisationsQuery({});

    return (
        <Container className="controlled-organisations-list p-0">
            <section className="d-flex align-items-center justify-content-between">
                <Form onSubmit={(e) => e.preventDefault()} className="d-flex align-items-center gap-3 mb-3">
                    <ControlledListInputs
                        {...control}
                        sortByKeys={Object.keys(OrganisationsSortBy)}
                        filterInputPlaceholder="Search by name or country"
                    />
                </Form>

                <RefreshButton callback={refetch} isDisabled={isFetching} />
            </section>

            <OrganisationsList maxItems={props.maxItems} control={control} />
        </Container>
    );
}

export default ControlledOrganisationsList;
