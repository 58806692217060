import { useSearchParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import "./HorizontalTabs.scss";
import { Notifications } from "../utilities/Notifications/Notifications";
import { useEffect } from "react";

export interface HorizontalTabsProps {
    tabsMap: {
        [key: string]: JSX.Element;
    };
}

const TAB_SEARCH_QUERY_KEY = "tab";

function HorizontalTabs(props: HorizontalTabsProps) {
    const keys = Object.keys(props.tabsMap);

    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get(TAB_SEARCH_QUERY_KEY);

    function handleChangeTab(key: string) {
        setSearchParams({ [TAB_SEARCH_QUERY_KEY]: key }, { replace: true });
    }

    useEffect(() => {
        if (!keys?.length) {
            return;
        }

        if (activeTab) {
            if (keys.includes(activeTab)) {
                return;
            } else {
                Notifications.notify(
                    "Tab not found",
                    `Attempted to access tab '${activeTab}', which is not a valid tab.`,
                    "warning",
                );
            }
        }

        // If no tab search query is passed, or is invalid, change query to the first tab's key
        handleChangeTab(keys[0]);
    }, [keys?.length, activeTab]);

    return (
        <div className="tabs horizontal-tabs">
            <Tabs activeKey={activeTab} onSelect={handleChangeTab}>
                {keys.map((key) => (
                    <Tab
                        eventKey={key}
                        title={key}
                        key={key}
                        children={props.tabsMap[key]}
                        disabled={props.tabsMap[key] == null}
                    />
                ))}
            </Tabs>
        </div>
    );
}

export default HorizontalTabs;
