import { MutableRefObject, useRef } from "react";

function useTimelineWidth(timelineRef: MutableRefObject<SVGSVGElement | undefined>) {
    const totalWidth = useRef(1000);

    /** Returns the total timeline width, based on it's parent element's properties */
    function calculateTimelineWidth(): number {
        const parentElement = timelineRef.current.parentElement;
        const parentWidth = parentElement.clientWidth;
        const parentStyle = window.getComputedStyle(parentElement);
        const parentPadding =
            parseFloat(parentStyle.getPropertyValue("padding-left")) +
            parseFloat(parentStyle.getPropertyValue("padding-right"));

        return parentWidth - parentPadding;
    }

    /** Attempts to update the total timeline width, returning false if no update occurred */
    function updateTimelineWidth() {
        if (!timelineRef?.current?.parentElement) {
            return false;
        }

        const newTotalWidth = calculateTimelineWidth();

        if (newTotalWidth === totalWidth.current) {
            return false;
        }

        totalWidth.current = newTotalWidth;
        return true;
    }

    return {
        totalWidth,
        calculateTimelineWidth,
        updateTimelineWidth,
    };
}

export default useTimelineWidth;
