import { useEffect, useState } from "react";
import { Analyser, AnalyticsDefinition } from "../../../../../models/analysis/Config";
import { ImportWizardPagePropsBase, AlgorithmOptionsData, AlgorithmPageData } from "../ImportWizard";
import ImportWizardOptions, { ImportWizardOptionsResults } from "../ImportWizardOptions";
import { Accordion } from "react-bootstrap";

interface AlgorithmOptionsProps extends ImportWizardPagePropsBase {
    analyserConfig: AnalyticsDefinition;
    selectedAnalyser: Analyser | undefined;
    value: AlgorithmPageData;
    previewVidUrl: string | undefined;
    previewImgUrl: string | undefined;
    dataChanged: (data: AlgorithmOptionsData) => void;
}

function AlgorithmOptions(props: AlgorithmOptionsProps) {
    const initialStandardOptions = props.value?.standardOptions;
    const initialAnalyserOptions = props.value?.analyserOptions?.[props.selectedAnalyser?.analyserId ?? ""];

    const [standardOptionsValid, setStandardOptionsValid] = useState<boolean>(true);
    const [algoOptionsValid, setAlgoOptionsValid] = useState<boolean>(true);
    const [standardData, setStandardData] = useState<ImportWizardOptionsResults>(initialStandardOptions);
    const [analyserData, setAnalyserData] = useState<ImportWizardOptionsResults>(initialAnalyserOptions);

    useEffect(() => {
        props.validityChanged(standardOptionsValid && algoOptionsValid);
    }, [standardOptionsValid, algoOptionsValid]);

    useEffect(() => {
        if (props.selectedAnalyser?.analyserId === undefined) {
            return;
        }

        const data: AlgorithmOptionsData = {
            standardOptions: standardData,
            analyserOptions: {
                ...(props.value?.analyserOptions ?? {}),
                [props.selectedAnalyser.analyserId]: analyserData,
            },
        };

        props.dataChanged(data);
    }, [standardData, analyserData]);

    const hasAlgoSpecificOptions = props.selectedAnalyser?.options !== undefined;

    return (
        <div>
            <Accordion>
                <Accordion.Item eventKey="algo">
                    <Accordion.Header>Algorithm Options</Accordion.Header>
                    <Accordion.Body>
                        {hasAlgoSpecificOptions ? (
                            <ImportWizardOptions
                                options={props.selectedAnalyser.options}
                                value={analyserData}
                                validityChanged={setAlgoOptionsValid}
                                dataChanged={setAnalyserData}
                                previewVidUrl={props.previewVidUrl}
                                previewImgUrl={props.previewImgUrl}
                            />
                        ) : (
                            <div>No algorithm options.</div>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="gen">
                    <Accordion.Header>Advanced Options</Accordion.Header>
                    <Accordion.Body>
                        <ImportWizardOptions
                            options={props.analyserConfig.standardOptions}
                            value={standardData}
                            validityChanged={setStandardOptionsValid}
                            dataChanged={setStandardData}
                            previewVidUrl={props.previewVidUrl}
                            previewImgUrl={props.previewImgUrl}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default AlgorithmOptions;
