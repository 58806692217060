import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { UsersProps } from "./Users";
import NewUserCreatedModal, { CreatedUserData } from "./modals/NewUserCreatedModal";
import NewUserModal from "./modals/NewUserModal";

export type AddUserProps = UsersProps;

export default function AddUser(props: AddUserProps) {
    const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false);
    const [isNewUserCreatedModalVisible, setIsNewUserCreatedModalVisible] = useState(false);
    const [createdUserData, setCreatedUserData] = useState<CreatedUserData>(undefined);
    const [attemptedUsernames, setAttemptedUsernames] = useState<string[]>([]);

    return (
        <>
            <Button variant="success" onClick={() => setIsNewUserModalVisible(true)}>
                <FontAwesomeIcon icon={faUserPlus} className="me-1" /> Create New User
            </Button>

            <NewUserModal
                {...props}
                isVisible={isNewUserModalVisible}
                setIsVisible={setIsNewUserModalVisible}
                setIsNewUserCreatedModalVisible={setIsNewUserCreatedModalVisible}
                setCreatedUserData={setCreatedUserData}
                attemptedUsernames={attemptedUsernames}
                setAttemptedUsernames={setAttemptedUsernames}
            />

            {createdUserData !== undefined && (
                <NewUserCreatedModal
                    isVisible={isNewUserCreatedModalVisible}
                    setIsVisible={setIsNewUserCreatedModalVisible}
                    createdUserData={createdUserData}
                    setCreatedUserData={setCreatedUserData}
                />
            )}
        </>
    );
}
