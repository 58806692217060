import { Entity } from "../Entity";
import { EntityFilter } from "./EntityFilter";
import { isInRange } from "../../../utilities/helpers";
import DateTimeFilter from "../../../views/viz/components/Filtering/DateTimeFilter";
import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import { DateEntityProperty } from "../entityProperties/DateEntityProperty";

export type DateTimeEntityFilterType = "before" | "after" | "within";
export type DateTimeEntityFilterParameter = { date: Date; type: DateTimeEntityFilterType; withinMilliseconds?: number };

export class DateTimeEntityFilter extends EntityFilter {
    propertyKey: string;

    constructor(displayName: string, propertyKey: string) {
        super(displayName);
        this.propertyKey = propertyKey;
    }

    predicate(entities: Entity[], params: DateTimeEntityFilterParameter): Entity[] {
        return entities.filter((e) => {
            const entityDate = (e.properties[this.propertyKey] as DateEntityProperty)?.value().valueOf();

            if (entityDate == undefined) {
                return false;
            }

            const paramMilliseconds = params.date.valueOf();

            switch (params.type) {
                case "before":
                    return entityDate < paramMilliseconds;
                case "after":
                    return entityDate > paramMilliseconds;
                case "within":
                    if (!params.withinMilliseconds) {
                        console.error(
                            "The parameter 'withinMilliseconds' for the DateTime entity filter is invalid. Could not filter.",
                        );
                        return false;
                    }

                    return isInRange(entityDate, [
                        paramMilliseconds - params.withinMilliseconds,
                        paramMilliseconds + params.withinMilliseconds,
                    ]);
            }
        });
    }

    createComponent(filterId: string, view: AnalyticsStateView, options?: unknown): JSX.Element {
        return <DateTimeFilter filterId={filterId} viewId={view.viewId} />;
    }
}
