import { useEffect, useRef, useState } from "react";
import ReactCrop, { PercentCrop, type Crop } from "react-image-crop";
import { AreaBounds } from "cloud-core/spatial/Spatial";
import { ImportWizardInputProps } from "../ImportWizardOption";

export interface ImportWizardRegionInputProps extends ImportWizardInputProps {
    previewImgUrl: string | undefined;
    previewVidUrl: string | undefined;
}

function ImportWizardRegionInput(props: ImportWizardRegionInputProps) {
    const defaults = (props.default as AreaBounds) ?? [0, 0, 0.1, 0.1];

    const [crop, setCrop] = useState<Crop>({
        unit: "%",
        x: defaults[0] * 100,
        y: defaults[1] * 100,
        width: (defaults[2] - defaults[0]) * 100,
        height: (defaults[3] - defaults[1]) * 100,
    });

    const previewVideoRef = useRef<HTMLVideoElement>(undefined);

    // Only executes when resizing / moving action is finished i.e. mouse button up
    function handleOnComplete(_: Crop, crop: PercentCrop) {
        const x1 = crop.x / 100;
        const y1 = crop.y / 100;
        const x2 = (crop.width + crop.x) / 100;
        const y2 = (crop.height + crop.y) / 100;
        props.onChanged([x1, y1, x2, y2]);
    }

    // Setup video preview (local file)
    useEffect(() => {
        const video = previewVideoRef.current;
        if (!props.previewVidUrl || video?.src === props.previewVidUrl || props.previewImgUrl) {
            return;
        }

        URL.revokeObjectURL(video.src);
        video.src = props.previewVidUrl;
    }, [props.previewVidUrl]);

    return (
        <div>
            <ReactCrop crop={crop} onChange={(crop) => setCrop(crop)} onComplete={handleOnComplete}>
                {props.previewImgUrl !== undefined ? (
                    <img src={props.previewImgUrl} />
                ) : props.previewVidUrl !== undefined ? (
                    <video preload="metadata" controls={false} autoPlay={false} muted={true} ref={previewVideoRef} />
                ) : (
                    <img data-testid="default-preview-image" src="/images/default_preview.png" />
                )}
            </ReactCrop>
        </div>
    );
}

export default ImportWizardRegionInput;
