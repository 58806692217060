import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "react-bootstrap";
import { DataOperation, VizContext } from "../../../../models/viz/operations/DataOperation";

export function DataOperationButton(props: {
    operation: DataOperation;
    context: VizContext;
    buttonProps?: ButtonProps;
}) {
    const { operation, context } = props;
    const buttonProps = props.buttonProps ?? {};

    return (
        <span title={operation.displayName}>
            <Button
                variant="secondary"
                className="data-operation-button"
                onClick={() => operation.execute(context)}
                disabled={!operation.canExecute(context)}
                {...buttonProps}
            >
                {operation.icon.type === "fontawesome" ? (
                    <FontAwesomeIcon icon={operation.icon.icon} className="fa-fw" />
                ) : (
                    <img src={operation.icon.url} />
                )}
            </Button>
        </span>
    );
}
