import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { ListGroup } from "react-bootstrap";
import { DateFormats } from "../../../../utilities/dates";
import { Project } from "../../../../models/Project";
import { useDispatch, useSelector } from "react-redux";
import * as GeneralStore from "../../../../store/general/General";
import { ApplicationState } from "../../../../store";
import { motion } from "framer-motion";

export interface ProjectListItemProps {
    data: Project;
}

function ProjectListItem(props: ProjectListItemProps) {
    const dispatch = useDispatch();
    const { general } = useSelector((state: ApplicationState) => state);

    const isSelected = general?.activeProjectId == props.data.projectId;

    function handleOnSelect(e: React.MouseEvent) {
        e.preventDefault();
        dispatch(GeneralStore.actionCreators.setActiveProject(props.data.projectId));
    }

    return (
        <ListGroup.Item
            action
            as={motion.button}
            layout
            onClick={handleOnSelect}
            className={`${isSelected ? "selected" : ""}`}
        >
            <motion.span layout className="gap-2 d-flex align-items-center text-truncate" title={props.data.name}>
                <FontAwesomeIcon icon={faFolder} />
                <motion.span layout className="text-truncate">
                    {props.data.name}
                </motion.span>
            </motion.span>
            <motion.span layout className="text-secondary">
                {props.data.mediaCount}
            </motion.span>
            <motion.span layout className="text-secondary align-self-end">
                {dayjs(props.data.createdAt).format(DateFormats.yearMonthDayWithTime)}
            </motion.span>
        </ListGroup.Item>
    );
}

export default ProjectListItem;
