/** Map of date template strings to be used with the dayjs format function */
export const DateFormats = {
    dayMonthYear: "DD/MM/YYYY",
    dayMonthYearWithTime: "DD/MM/YYYY HH:mm",
    dayMonthYearWithTimeSeconds: "DD/MM/YYYY HH:mm:ss",
    dayMonthTime: "DD/MM HH:mm",
    yearMonthDay: "YYYY-MM-DD",
    yearMonthDayWithTime: "YYYY-MM-DD HH:mm",
    yearMonthDayWithTimeSeconds: "YYYY-MM-DD HH:mm:ss",
    yearMonthDayWithTimeMilliseconds: "YYYY-MM-DD HH:mm:ss#SSS",
    time: "HH:mm",
    timeSeconds: "HH:mm:ss",
    timeMilliseconds: "HH:mm:ss#SSS",
    durationTimeDaysToMilliseconds: "DD:HH:mm:ss#SSS",
    milliseconds: "SSS",
    fileNameYearMonthDaysWithTimeMilliseconds: "YYYY_MM_DD_HH_mm_ss_SSS",
} as const;
