import { NoOpEntitySorter } from "../sorters/EntitySorter";
import { StaticStringEntityInformationGenerator } from "../entityInformation/StaticStringEntityInformationGenerator";
import { EmptyEntityAdapter } from "../entityAdapters/EmptyEntityAdapter";
import { DataDescription } from "./DataDescription";

export class EmptyDataDescription extends DataDescription {
    constructor() {
        super();
        this.type = "empty";
        this.infoGenerator = {
            titleGenerator: new StaticStringEntityInformationGenerator("", ""),
            subtitleGenerator: new StaticStringEntityInformationGenerator("", ""),
        };
        this.entityAdapter = new EmptyEntityAdapter(this);
        this.properties = {};
        this.filters = {};
        this.defaultSorter = new NoOpEntitySorter("noop", "No Op");
        this.sorters = [this.defaultSorter];
        this.operations = [];
        this.childDataDescriptions = [];
    }
}
