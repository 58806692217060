import { Entity } from "../Entity";
import { PropertyDescription } from "../dataDescriptions/PropertyDescription";
import { EntitySorter, SortParameterType } from "./EntitySorter";

export class StringSorter extends EntitySorter {
    propertyKey: string;

    constructor(id: string, displayName: string, propertyKey: string) {
        super(id, displayName);
        this.propertyKey = propertyKey;
    }

    getCompareFn(param: undefined | SortParameterType): (a: Entity, b: Entity) => number {
        return (a: Entity, b: Entity) => {
            const stringA = a.properties[this.propertyKey].display() ?? "";
            const stringB = b.properties[this.propertyKey].display() ?? "";

            return stringA.localeCompare(stringB);
        };
    }

    static fromProperty(property: PropertyDescription) {
        return new StringSorter(property.key, property.displayName, property.key);
    }
}
