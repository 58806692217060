import { StandardTagEventAction } from "cloud-core/tags/TagEvent";
import { TagObject } from "../../../../models/tags/TagObject";
import OverlaySpinner from "../../../OverlaySpinner";
import { TagObjectReactSelectOption } from "../EditTagEventModal";
import { SetStateAction, useMemo } from "react";
import Select, { MultiValue } from "react-select";
import { TagEventModalAddTagObjectButton } from "./TagEventModalAddTagObjectButton";
import { VizContext } from "../../../../models/viz/operations/DataOperation";

export interface TagEventModalObjectProps {
    context: VizContext;
    tagObjects: TagObject[];
    isFetchingTagObjects: boolean;
    selectedObjects: TagObjectReactSelectOption[];
    selectedSubjects: TagObjectReactSelectOption[];
    setSelectedObjects: (_: SetStateAction<TagObjectReactSelectOption[]>) => void;
    selectedAction: StandardTagEventAction;
    showObject: boolean;
}

function TagEventModalObject(props: TagEventModalObjectProps) {
    function handleOnChangeObject(e: MultiValue<TagObjectReactSelectOption>) {
        props.setSelectedObjects([...e]);
    }

    function filterObjects(tagObjects: TagObject[]): TagObject[] {
        if (props.selectedAction.action != "enters" && props.selectedAction.action != "exits") {
            return tagObjects.filter((o) => o.type !== "location");
        } else {
            // So that "enters" and "exits" make sense
            return tagObjects.filter((o) => o.type === "vehicle" || o.type === "object");
        }
    }

    const options = useMemo(() => {
        return (
            filterObjects(props.tagObjects ?? [])
                // Don't include options already selected as a subject
                .filter((o) => !props.selectedSubjects.find((s) => s.value == o.objectId))
                .map((tagObject: TagObject) => ({
                    value: tagObject.objectId,
                    label: tagObject.name,
                }))
        );
    }, [props.isFetchingTagObjects, props.selectedSubjects.length]);

    function onCreatedCallback(obj: TagObject) {
        if (filterObjects([obj]).length == 0) {
            return;
        }

        props.setSelectedObjects((prev) => {
            return [...prev, { value: obj.objectId, label: obj.name }];
        });
    }

    return (
        <div className="gap-1 d-flex">
            <div className="position-relative flex-grow-1">
                <OverlaySpinner isLoading={props.isFetchingTagObjects} />
                {props.showObject && (
                    <Select
                        placeholder="Select an object"
                        classNamePrefix="react-select"
                        className="flex-grow-1"
                        value={props.selectedObjects}
                        options={options}
                        onChange={handleOnChangeObject}
                        isMulti
                        required
                    />
                )}
            </div>
            <TagEventModalAddTagObjectButton context={props.context} onCreatedCallback={onCreatedCallback} />
        </div>
    );
}

export default TagEventModalObject;
