import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ReactNode } from "react";
import "./ErrorPage.scss";

export interface ErrorPageProps {
    title?: string;
    subTitle: string;
    errorMessageElement: ReactNode;
}

function ErrorPage(props: ErrorPageProps) {
    const navigate = useNavigate();

    function goBack() {
        if (history.length > 1) {
            navigate(-1);
        } else {
            navigate("/", { replace: true });
        }
    }

    return (
        <main className="mx-auto mt-0 text-center d-flex flex-column justify-content-center align-items-center error-page">
            {props.title !== undefined && <h2 className="mb-1 text-danger text-shadow fw-bold">{props.title}</h2>}
            <h1 className="mb-4 fs-2">{props.subTitle}</h1>

            <section className="px-3 mb-4">{props.errorMessageElement}</section>

            <Button className="shadow" onClick={goBack} variant="secondary" aria-label="back">
                <FontAwesomeIcon icon={faArrowLeft} />
                <span className="ms-2">Back</span>
            </Button>
        </main>
    );
}

export default ErrorPage;
