import { MutableRefObject, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user/User";
import TotpFormGroup from "./components/TotpFormGroup";
import { MfaSetupParams } from "../../store/user/User";
import SpinnerButton from "../../components/SpinnerButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface MfaSetupProps {
    onSubmitCallbackRef: MutableRefObject<() => void>;
}

function MfaSetup(props: MfaSetupProps) {
    const dispatch = useDispatch();
    const loginIssueParams = useSelector((state: ApplicationState) => state.user.loginIssueParams);
    const otpKey = (loginIssueParams as MfaSetupParams)?.otpKey;
    const isUpdatingAuth = loginIssueParams?.isUpdatingAuth ?? false;

    const [totp, setTotp] = useState("");

    if (props.onSubmitCallbackRef?.current) {
        props.onSubmitCallbackRef.current = () => dispatch(UserStore.actionCreators.completeMfaSetup(totp));
    }

    return (
        <div className="text-center">
            <h3>Configure two factor authentication</h3>
            <div className="my-3 d-flex justify-content-center">
                <div className="p-3 bg-white">
                    <QRCode value={`otpauth://totp/Kinesense?secret=${otpKey}`} level="Q" />
                </div>
            </div>
            <div className="mt-2 alert alert-info">
                <h5>Text Code</h5>
                <div>
                    <span style={{ fontFamily: "monospace" }}>{otpKey}</span>
                </div>
            </div>

            <h6 className="px-4 mt-4 fs-6">
                Please setup your two factor authentication by scanning the above QR code above with an{" "}
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            Some examples include Google Authenticator, 1Password, Aegis and Authy. You can use any OTP
                            generator.
                        </Tooltip>
                    }
                >
                    <span style={{ borderBottom: "2px dotted blue" }}>authenticator app</span>
                </OverlayTrigger>
                , then entering your code below.
            </h6>

            <TotpFormGroup totp={totp} setTotp={setTotp} />

            <SpinnerButton
                className="mt-4"
                buttonProps={{
                    type: "submit",
                }}
                label="Confirm"
                isLoading={isUpdatingAuth}
            />
        </div>
    );
}

export default MfaSetup;
