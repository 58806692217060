import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { cleanId } from "../../../../../utilities/helpers";
import { ImportWizardInputProps } from "../ImportWizardOption";

function ImportWizardBooleanInput(props: ImportWizardInputProps) {
    const defaultValue = typeof props.option.default == "boolean" ? props.option.default : true;
    const initialValue = typeof props.default == "boolean" ? props.default : defaultValue;
    const displayId = cleanId(props.option.displayName);
    const yesId = `${displayId}-yes`;
    const noId = `${displayId}-no`;

    const [value, setValue] = useState<boolean>(initialValue);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.currentTarget.value === "true" ? true : false);
        props.onChanged(event.currentTarget.value === "true" ? true : false);
    }, []);

    return (
        <>
            <Form.Group>
                <Form.Check
                    inline
                    id={yesId}
                    name={props.option.displayName}
                    type="radio"
                    value="true"
                    checked={value}
                    onChange={onChange}
                />
                <Form.Label htmlFor={yesId}>Yes</Form.Label>
            </Form.Group>
            <Form.Group>
                <Form.Check
                    inline
                    id={noId}
                    name={props.option.displayName}
                    type="radio"
                    checked={!value}
                    onChange={onChange}
                />
                <Form.Label htmlFor={noId}>No</Form.Label>
            </Form.Group>
        </>
    );
}

export default ImportWizardBooleanInput;
