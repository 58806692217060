/* https://github.com/hakanensari/frankfurter */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

function extractExchangeRate(data: { rates: { EUR: number } }) {
    return data?.rates?.EUR;
}

export const baseUrlExchangeRate = "https://api.frankfurter.app";

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrlExchangeRate,
});

export const exchangeRatesApiSlice = createApi({
    reducerPath: "apiExchangeRates",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getExchangeRateUsdToEur: builder.query<number, void>({
            query: () => "/latest?from=USD&to=EUR&amount=1",
            transformResponse: extractExchangeRate,
        }),
    }),
});

export const { useGetExchangeRateUsdToEurQuery } = exchangeRatesApiSlice;
