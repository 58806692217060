import { Form } from "react-bootstrap";
import { ReactNode } from "react";
import { AnalyserConfigOptionValue, AnalyserConfigOption } from "../../../../models/analysis/Config";
import ImportWizardSelectionInput from "./inputs/ImportWizardSelectionInput";
import ImportWizardNumberInput from "./inputs/ImportWizardNumberInput";
import ImportWizardBooleanInput from "./inputs/ImportWizardBooleanInput";
import ImportWizardRegionInput from "./inputs/ImportWizardRegionInput";

export interface BaseImportWizardOptionProps {
    option: AnalyserConfigOption;
    onChanged: (update: AnalyserConfigOptionValue) => void;
}

export interface ImportWizardOptionProps extends BaseImportWizardOptionProps {
    value: AnalyserConfigOptionValue;
    previewImgUrl: string | undefined;
    previewVidUrl: string | undefined;
}

export interface ImportWizardInputProps extends BaseImportWizardOptionProps {
    default?: AnalyserConfigOptionValue;
}

function ImportWizardOption(props: ImportWizardOptionProps) {
    const defaultValue = props.value ?? props.option.default;
    const inputProps: ImportWizardInputProps = {
        option: props.option,
        onChanged: props.onChanged,
        default: defaultValue,
    };

    let formInput: ReactNode;
    switch (props.option.type) {
        case "integer":
            formInput = <ImportWizardNumberInput {...inputProps} />;
            break;
        case "percentage":
            formInput = <ImportWizardNumberInput {...inputProps} step={10} />;
            break;
        case "selection":
            formInput = <ImportWizardSelectionInput {...inputProps} />;
            break;
        case "boolean":
            formInput = <ImportWizardBooleanInput {...inputProps} />;
            break;
        case "region":
            formInput = (
                <ImportWizardRegionInput
                    {...inputProps}
                    previewVidUrl={props.previewVidUrl}
                    previewImgUrl={props.previewImgUrl}
                />
            );
    }

    return (
        <Form.Group as="section">
            <Form.Label className="fw-semibold">{props.option.displayName}</Form.Label>
            {formInput}
        </Form.Group>
    );
}

export default ImportWizardOption;
