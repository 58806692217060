export class PropertyDescription {
    key: string;
    displayName: string;
    isRequired: boolean;
    isList: boolean = false;
    type: "string" | "number" | "date" | "object";
    userRelevance: UserRelevance;
    confidenceKey?: string;
    possibleValues?: string[];
    conditionalOn?: PropertyDescriptionCondition;

    constructor(key: string, props?: Partial<Omit<PropertyDescription, "key">>) {
        this.key = key;
        Object.assign(this, props);
    }

    withUserRelevance(userRelevance: UserRelevance) {
        this.userRelevance = userRelevance;
        return this;
    }

    withIsRequired(isRequired: boolean) {
        this.isRequired = isRequired;
        return this;
    }

    withConditionalOn(condition: PropertyDescriptionCondition) {
        this.conditionalOn = condition;
        return this;
    }

    withPossibleValues(possibleValues: string[]) {
        this.possibleValues = possibleValues;
        return this;
    }
}

export type UserRelevance = "hidden" | "detailed" | "basic";

export interface PropertyDescriptionCondition {
    propertyKey: string;
    value: unknown;
}
