import { useRef } from "react";
import Dashboard, { DashboardCards } from "../../components/dashboard/Dashboard";
import Analytics from "./components/analytics/Analytics";
import "./Home.scss";
import VideoList from "./components/videos/VideoList";
import ProjectList from "./components/projects/ProjectList";

function Home() {
    const cards = useRef<DashboardCards>({
        projects: <ProjectList />,
        videos: <VideoList />,
        // TODO: populate with real user data
        /* analytics: <Analytics />, */
    });

    return (
        <main className="container home">
            <Dashboard dashboardId="home" cards={cards} />
        </main>
    );
}

export default Home;
