import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { useEffect, useState } from "react";
import {
    DateTimeEntityFilterParameter,
    DateTimeEntityFilterType,
} from "../../../../models/viz/filters/DateTimeEntityFilter";
import { capitaliseFirst } from "../../../../utilities/helpers";
import { ActivateViewFilterAction, DeactivateViewFilterAction } from "../../../../store/analytics/Analytics";
import { DateTimeInput } from "../../../../components/DateTimeInput";
import { Form } from "react-bootstrap";
import Select from "react-select";

type FilterOption = { value: DateTimeEntityFilterType | "any"; label: string };
const FILTER_OPTIONS: Readonly<FilterOption[]> = [
    { value: "any", label: "Any" },
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "within", label: "Within" },
];

type TimeMagnitude = keyof typeof TIME_MAGNITUDE_MAPPING;
type UnitOption = { value: TimeMagnitude; label: string };
const TIME_MAGNITUDE_MAPPING = { seconds: 1000, minutes: 60000, hours: 3600000, days: 86400000 } as const;
const UNIT_OPTIONS: Readonly<UnitOption[]> = Object.keys(TIME_MAGNITUDE_MAPPING).map((unit: TimeMagnitude) => ({
    value: unit,
    label: capitaliseFirst(unit),
}));

const FALLBACK_DATE: Readonly<Date> = new Date();
FALLBACK_DATE.setSeconds(0);

export interface DateTimeFilterProps {
    viewId: string;
    filterId: string;
}

function DateTimeFilter({ viewId, filterId }: DateTimeFilterProps): JSX.Element {
    const dispatch = useDispatch();
    const defaultParams = useSelector(
        (state: ApplicationState) => state.analytics.views[viewId].filters[filterId]?.params,
    ) as DateTimeEntityFilterParameter;
    const [datetime, setDatetime] = useState<Date>(defaultParams?.date ?? FALLBACK_DATE);
    const [type, setType] = useState<"any" | DateTimeEntityFilterType>(defaultParams?.type ?? "any");

    const [selectedUnitOption, setSelectedUnit] = useState<UnitOption>(UNIT_OPTIONS[0]);
    const [unitCount, setUnitCount] = useState<number>(1);

    const withinMilliseconds = TIME_MAGNITUDE_MAPPING[selectedUnitOption.value] * unitCount;

    function dispatchActivateViewFilter(options?: {
        overrideType?: DateTimeEntityFilterType;
        overrideDate?: Date;
        withinMilliseconds?: number;
    }) {
        const parameter: DateTimeEntityFilterParameter = {
            date: options.overrideDate ?? datetime,
            // Type can be coerced as this function should not be called if the filter type is "any"
            type: options?.overrideType ?? (type as DateTimeEntityFilterType),
        };

        if (withinMilliseconds !== undefined) {
            parameter.withinMilliseconds = withinMilliseconds;
        }

        dispatch({
            type: "analytics/activateViewFilter",
            payload: {
                viewId,
                filterId,
                parameter,
            },
        } as ActivateViewFilterAction);
    }

    function onOptionChange(option: FilterOption) {
        setType(option.value);

        switch (option.value) {
            case "any":
                dispatch({
                    type: "analytics/deactivateViewFilter",
                    payload: { viewId, filterId },
                } as DeactivateViewFilterAction);
                break;
            case "before":
            case "after":
                dispatchActivateViewFilter({ overrideType: option.value });
                break;
            case "within":
                dispatchActivateViewFilter({ overrideType: option.value, withinMilliseconds });
        }
    }

    function handleDateChanged(date: Date) {
        setDatetime(date);
        dispatchActivateViewFilter({ overrideDate: date });
    }

    function handleDateChangedWithin(date: Date) {
        setDatetime(date);
        dispatchActivateViewFilter({ overrideDate: date, withinMilliseconds });
    }

    function renderInputs() {
        switch (type) {
            case "before":
            case "after":
                return <DateTimeInput defaultDate={datetime} onDateChanged={handleDateChanged} />;
            case "within":
                return (
                    <>
                        <Form.Group className="gap-2 d-flex">
                            <Form.Control
                                type="number"
                                min="1"
                                value={unitCount.toString()}
                                style={{ width: "3.5em" }}
                                onChange={(e) => {
                                    const num = parseInt(e.target.value);
                                    if (!isNaN(num) && num > 0 && num < 999) {
                                        setUnitCount(num);
                                    }
                                }}
                            />
                            <Select
                                classNamePrefix="react-select"
                                className="flex-grow-1"
                                value={selectedUnitOption}
                                defaultValue={selectedUnitOption}
                                options={UNIT_OPTIONS}
                                onChange={(e) => setSelectedUnit(e)}
                            />
                        </Form.Group>

                        <DateTimeInput defaultDate={datetime} onDateChanged={handleDateChangedWithin} />
                    </>
                );
        }
    }

    useEffect(() => {
        if (type == "within") {
            dispatchActivateViewFilter({ withinMilliseconds });
        }
    }, [withinMilliseconds]);

    return (
        <div className="gap-2 d-flex flex-column">
            <Select
                classNamePrefix="react-select"
                options={FILTER_OPTIONS}
                defaultValue={FILTER_OPTIONS.find((o) => o.value == type)}
                onChange={onOptionChange}
            />
            {renderInputs()}
        </div>
    );
}

export default DateTimeFilter;
