import { EntityProperty } from "./EntityProperty";

export class NumberEntityProperty extends EntityProperty<number> {
    staticValue: number;

    constructor(value: number, confidence?: number) {
        super([confidence]);
        this.valueType = "number";
        this.isList = false;
        this.staticValue = value;
    }

    display(): string {
        return this.staticValue.toString();
    }

    value(): number {
        return this.staticValue;
    }
}
