import { useSelector } from "react-redux";
import ConditionalSpinner from "../../components/ConditionalSpinner";
import { useView } from "../../hooks/useView";
import { ApplicationState } from "../../store";
import "./DataViz.scss";
import { VizFilters } from "./components/Filtering/VizFilters";
import { VizContainer } from "./components/VizContainer";
import { VizCountSummary } from "./components/VizCountSummary";
import { VizDetails } from "./components/VizDetails";
import { VizOperations } from "./components/VizOperations";
import { VizPreview } from "./components/VizPreview";
import { VizSorters } from "./components/VizSorters";
import NoMediaSourcesErrorPage from "../import/components/NoMediaSourcesErrorPage";
import useMediaSources from "../../hooks/useMediaSources";

export interface DataVizProps {
    children?: React.ReactNode;
    viewId?: string;
}

export function DataViz(props: DataVizProps) {
    const { analytics, general } = useSelector((state: ApplicationState) => state);
    const { viewId } = useView(props.viewId ?? analytics?.primaryViewId);
    const projectId = general?.activeProjectId;

    const { allMediaSources, hasLoadedMediaSources } = useMediaSources(viewId);

    if (hasLoadedMediaSources && !allMediaSources?.length) {
        return <NoMediaSourcesErrorPage />;
    }

    return (
        <div key={projectId} className="data-viz">
            <div className="viz-header placeholder-glow">{props.children}</div>

            <ConditionalSpinner isLoading={!hasLoadedMediaSources} className="viz-loading">
                <div className="viz-leftbar">
                    <VizSorters viewId={viewId} />
                    <VizFilters viewId={viewId} />
                    <VizCountSummary viewId={viewId} />
                </div>
                <div className="viz-main">
                    <VizContainer viewId={viewId} />
                </div>
                <div className="viz-rightbar">
                    <VizPreview viewId={viewId} />
                    <VizDetails viewId={viewId} />
                </div>
                <div className="viz-footer viz-panel">
                    <VizOperations viewId={viewId} />
                </div>
            </ConditionalSpinner>
        </div>
    );
}
