import { faArrowsMaximize, faArrowsMinimize } from "@fortawesome/pro-regular-svg-icons";
import { DataOperation, VizContext } from "./DataOperation";
import * as AnalyticsStore from "../../../store/analytics/Analytics";
import { KnownAction } from "../../../store/user/User";

const ICON_EXPAND = faArrowsMaximize;
const ICON_MINIMISE = faArrowsMinimize;

export class ToggleAllowVideoStretching extends DataOperation {
    constructor() {
        super();
        this.operationId = "toggleAllowVideoStretching";
        this.displayName = "Disable video stretching beyond its resolution";
        this.icon = {
            type: "fontawesome",
            icon: ICON_MINIMISE,
        };
        this.priority = "primary";
        this.context = "selection";
        this.category = "view";
    }

    canExecute(context: VizContext) {
        return context.view?.viewId !== undefined && context.container.hasInlineCropSelector;
    }

    execute(context: VizContext) {
        const viewId = context.view.viewId;
        const isAllowed = context.view.allowVideoStretching;

        context.react.dispatch(
            isAllowed
                ? (AnalyticsStore.actionCreators.disableVideoStretching(viewId) as unknown as KnownAction)
                : (AnalyticsStore.actionCreators.enableVideoStretching(viewId) as unknown as KnownAction),
        );

        this.icon = {
            type: "fontawesome",
            icon: isAllowed ? ICON_EXPAND : ICON_MINIMISE,
        };

        this.displayName = `${isAllowed ? "Enable" : "Disable"} video stretching beyond its resolution`;
    }
}
