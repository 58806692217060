import dayjs from "dayjs";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { DateFormats } from "../utilities/dates";
import "./DateTimeInput.scss";

export interface DateTimeInputProps {
    defaultDate?: Date;
    onDateChanged: (date: Date) => void;
}

export function DateTimeInput(props: DateTimeInputProps) {
    const [datetime, setDatetime] = useState<Date>(props.defaultDate ?? new Date());

    const dateChanged = (date: Date) => {
        const newDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            datetime.getHours(),
            datetime.getMinutes(),
            datetime.getSeconds(),
        );

        setDatetime(newDate);
        props.onDateChanged(newDate);
    };

    const timeChanged = (date: Date) => {
        const newTime = new Date(
            datetime.getFullYear(),
            datetime.getMonth(),
            datetime.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
        );

        setDatetime(newTime);
        props.onDateChanged(newTime);
    };

    return (
        <div className="gap-2 datetime-input">
            <DateInput defaultDate={props.defaultDate} onDateChanged={dateChanged} />
            <TimeInput defaultTime={props.defaultDate} onTimeChanged={timeChanged} />
        </div>
    );
}

export function DateInput(props: { defaultDate?: Date; onDateChanged: (date: Date) => void }) {
    const defaultTime = new Date();
    defaultTime.setSeconds(0);

    const [datetime, setDatetime] = useState<Date>(props.defaultDate ?? defaultTime);
    const [dateString, setDateString] = useState<string>(dayjs(datetime).format(DateFormats.yearMonthDay));

    const handleDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dateInputValue = event.target.value;

        if (dateInputValue == undefined || dateInputValue.length == 0) {
            return;
        }

        setDateString(dateInputValue);

        const dateBits = dateInputValue.split("-").map(Number);
        if (dateBits.length != 3 || dateBits.some(isNaN) || dateBits[1] < 1) {
            return;
        }

        datetime.setFullYear(dateBits[0]);
        datetime.setMonth(dateBits[1] - 1);
        datetime.setDate(dateBits[2]);

        setDatetime(datetime);

        props.onDateChanged(datetime);
    };

    return <Form.Control type="date" value={dateString} onChange={handleDateChanged} />;
}

export function TimeInput(props: { defaultTime?: Date; onTimeChanged: (date: Date) => void }) {
    const defaultTime = new Date();
    defaultTime.setSeconds(0);

    const [datetime, setDatetime] = useState<Date>(props.defaultTime ?? defaultTime);
    const [timeString, setTimeString] = useState<string>(dayjs(datetime).format(DateFormats.timeSeconds));

    const handleTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const timeInputValue = event.target.value;

        if (timeInputValue == undefined || timeInputValue.length == 0) {
            return;
        }

        setTimeString(timeInputValue);

        const timeBits = timeInputValue.split(":").map(Number);
        if (timeBits.length != 3 || timeBits.some(isNaN)) {
            return;
        }

        datetime.setHours(timeBits[0]);
        datetime.setMinutes(timeBits[1]);
        datetime.setSeconds(timeBits[2]);

        setDatetime(datetime);

        props.onTimeChanged(datetime);
    };

    return <Form.Control type="time" value={timeString} onChange={handleTimeChanged} />;
}
