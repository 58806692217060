import { MutableRefObject, useEffect, useState } from "react";
import useDebouncedCallback from "./useDebouncedCallback";

export const RESIZE_CALLBACK_DEBOUNCE_MILLISECONDS = 50 as const;

function useMaxContainerDimensions(elementRef: MutableRefObject<HTMLElement | SVGSVGElement>): [number, number] {
    const [maxHeight, setMaxHeight] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0);

    function updateDimensions() {
        if (!elementRef?.current?.parentElement) {
            return;
        }

        const style = window.getComputedStyle(elementRef.current.parentElement);

        const xPadding =
            parseFloat(style.getPropertyValue("padding-left")) + parseFloat(style.getPropertyValue("padding-right"));
        const yPadding =
            parseFloat(style.getPropertyValue("padding-top")) + parseFloat(style.getPropertyValue("padding-bottom"));

        const nextMaxWidth = elementRef.current?.parentElement.clientWidth - xPadding;
        if (nextMaxWidth != maxWidth && !isNaN(nextMaxWidth)) {
            setMaxWidth(nextMaxWidth);
        }

        const nextMaxHeight = elementRef.current?.parentElement.clientHeight - yPadding;
        if (nextMaxHeight != maxHeight && !isNaN(nextMaxHeight)) {
            setMaxHeight(nextMaxHeight);
        }
    }

    const debouncedUpdateDimensions = useDebouncedCallback(updateDimensions, RESIZE_CALLBACK_DEBOUNCE_MILLISECONDS);

    useEffect(() => {
        debouncedUpdateDimensions();

        // Observe parent element for resize events
        const observer = new ResizeObserver(debouncedUpdateDimensions as () => void);
        observer.observe(elementRef.current.parentElement);

        return () => observer.disconnect();
    }, []);

    return [maxWidth, maxHeight];
}

export default useMaxContainerDimensions;
