import { Entity } from "../Entity";
import { EntityInformationGenerator } from "../entityInformation/EntityInformationGenerator";

export class StaticStringEntityInformationGenerator implements EntityInformationGenerator {
    staticString: string;
    displayName: string;

    constructor(staticString: string, displayName: string) {
        this.staticString = staticString;
        this.displayName = displayName;
    }

    generateInformation(entity: Entity): string {
        return this.staticString;
    }
}
