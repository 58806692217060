import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { ListGroup } from "react-bootstrap";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { Processor } from "../../../../models/admin/Processor";
import { useAdminGetAllProcessorsQuery } from "../../../../store/api/kinesense";
import ProcessorListItem from "../processors/ProcessorListItem";
import "./Processors.scss";

export const ProcessorsSortBy = {
    processorId: "Processor ID",
    lastStartedAt: "Last Started At",
    type: "Type",
} as const;
export type ProcessorsSortBy = (typeof ProcessorsSortBy)[keyof typeof ProcessorsSortBy];

export interface ProscessorsListProps {
    maxItems?: number;
    control?: {
        sortBy: ProcessorsSortBy;
        setSortBy: (sortBy: ProcessorsSortBy) => void;
        isSortOrderAscending: boolean;
        setIsSortOrderAscending: (isAscending: boolean) => void;
        toggleSortByOrder: () => void;
        filter: string;
    };
}

function ProcessorsList(props: ProscessorsListProps) {
    const { data: processors, isSuccess, isFetching } = useAdminGetAllProcessorsQuery();

    const isControlled = useMemo(() => props.control !== undefined, [props.control]);

    let displayedProcessors: Processor[] = [];

    if (isSuccess) {
        const limit = props.maxItems ?? processors.length;

        displayedProcessors = processors.slice(0, limit);
    }

    if (isControlled) {
        // FILTER
        const filterRegex = new RegExp(props.control.filter, "i");

        displayedProcessors = displayedProcessors.filter(
            (proc) => filterRegex.test(proc.processorId) || filterRegex.test(proc.metadata?.ec2InstanceType),
        );

        // SORT
        const isAscending = props.control.isSortOrderAscending ?? true;
        const isAscendingMultiplier = isAscending ? 1 : -1;

        switch (props.control.sortBy) {
            case ProcessorsSortBy.processorId:
                displayedProcessors.sort((a, b) => a.processorId.localeCompare(b.processorId) * isAscendingMultiplier);
                break;

            case ProcessorsSortBy.lastStartedAt:
                displayedProcessors.sort((a, b) => (a.lastStartedAt - b.lastStartedAt) * isAscendingMultiplier);
                break;

            case ProcessorsSortBy.type:
                displayedProcessors.sort((a, b) => a.type.localeCompare(b.type) * isAscendingMultiplier);
        }
    }

    function getOnClickHeadingHandler(key: ProcessorsSortBy) {
        return (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            // Do nothing if the list isn't being controlled
            if (!isControlled) {
                return;
            }

            if (props.control?.sortBy == ProcessorsSortBy[key]) {
                props.control.toggleSortByOrder();
                return;
            }

            props.control.setSortBy(ProcessorsSortBy[key]);
            props.control.setIsSortOrderAscending(true);
        };
    }

    return (
        <section className="processors-list">
            <header>
                {Object.keys(ProcessorsSortBy).map((key: ProcessorsSortBy) => (
                    <button
                        className="px-0 btn text-start"
                        key={`processor-table-heading-${key}`}
                        onClick={getOnClickHeadingHandler(key)}
                        aria-label={`Sort by ${ProcessorsSortBy[key]}`}
                    >
                        <span className="gap-2 m-0 h6 pe-4 d-flex position-relative">
                            {ProcessorsSortBy[key]}

                            {isControlled && props.control.sortBy == ProcessorsSortBy[key] && (
                                <span className="position-absolute top-50 end-0 translate-middle">
                                    <FontAwesomeIcon
                                        icon={props.control.isSortOrderAscending ? faCaretDown : faCaretUp}
                                    />
                                </span>
                            )}
                        </span>
                    </button>
                ))}

                <span className="m-0 h6" aria-hidden></span>
            </header>

            <ListGroup>
                <ConditionalSpinner isLoading={isFetching}>
                    {displayedProcessors?.map((processor) => (
                        <ProcessorListItem data={processor} key={`processors-${processor.processorId}`} />
                    ))}
                </ConditionalSpinner>
            </ListGroup>
        </section>
    );
}

export default ProcessorsList;
