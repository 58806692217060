import ErrorPage from "../components/ErrorPage";

function PageNotFound() {
    return (
        <ErrorPage
            title="404"
            subTitle="Sorry, the page you are looking for could not be found."
            errorMessageElement={<p>Please double-check that the URL in the address bar is correct.</p>}
        />
    );
}

export default PageNotFound;
