import { Container } from "react-bootstrap";
import AddOrganisation from "./AddOrganisation";
import ControlledOrganisationsList from "./ControlledOrganisationsList";

function Organisations() {
    return (
        <main>
            <Container className="d-flex justify-content-between align-items-start mb-3 p-0">
                <h4 className="fs-4 m-0">Organisations</h4>
                <AddOrganisation />
            </Container>

            <ControlledOrganisationsList />
        </main>
    );
}

export default Organisations;
