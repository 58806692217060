import { Entity } from "../Entity";

export type SortParameter =
    | {
          type: "date";
          defaultValue: Date;
      }
    | {
          type: "number";
          defaultValue: number;
      }
    | {
          type: "string";
          defaultValue: string;
      };
export type SortParameterType = SortParameter["defaultValue"];

export abstract class EntitySorter {
    id: string;
    displayName: string;
    sortParameter: undefined | SortParameter;

    constructor(id: string, displayName: string) {
        this.id = id;
        this.displayName = displayName;
    }

    abstract getCompareFn(param: undefined | SortParameterType): (a: Entity, b: Entity) => number;

    withSortParameterType(sortParameter: SortParameter): this {
        this.sortParameter = sortParameter;
        return this;
    }
}

export class NoOpEntitySorter extends EntitySorter {
    getCompareFn(): (a: Entity, b: Entity) => number {
        return () => 0;
    }
}
