import { useState } from "react";

function useLocalStorage<T>(
    key: string,
    initialValue: T,
): [value: T, setValue: (value: React.SetStateAction<T>) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        const item = window.localStorage.getItem(key);

        try {
            return item ? JSON.parse(item) : initialValue;
        } catch {
            return initialValue;
        }
    });

    function setValue(setStateAction: React.SetStateAction<T>) {
        // Allow value to be a function so we have same API as useState
        const valueToStore = setStateAction instanceof Function ? setStateAction(storedValue) : setStateAction;

        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }

    return [storedValue, setValue];
}

export default useLocalStorage;
