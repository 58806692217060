import {
    TagEventBase,
    StandardAction,
    TagEventPrototype,
    STANDARD_ACTION_PROTOTYPE_MAPPING,
} from "cloud-core/tags/TagEvent";
import { TagObject } from "./TagObject";

export class TagEvent extends TagEventBase<number> {
    subjects: TagObject[];
    objects: TagObject[];
    locations: TagObject[];

    static createSummary(event: TagEvent): string {
        const action = TagEvent.getActionText(event);
        const subjects = event.subjects.map((s) => s.name).join(", ");
        const objects = event.objects.map((o) => o.name).join(", ");
        const locations = event.locations.map((l) => l.name).join(", ");

        if (event.action.type === "standard") {
            const prototype = STANDARD_ACTION_PROTOTYPE_MAPPING[event.action.action];
            switch (prototype) {
                case "subject-object":
                    return `${subjects} ${action} ${objects}`;
                case "subject":
                    return `${subjects} ${action}`;
                case "subject-location":
                    return `${subjects} ${action} ${locations}`;
                case "passive":
                    return `Comments`;
            }
        }

        return action;
    }

    private static getActionText(event: TagEvent): string {
        if (event.action === undefined) {
            return "?";
        }

        if (event.action.type === "custom") {
            return event.action.name;
        }

        return TAG_EVENT_STANDARD_ACTIONS[event.action.action].label.toLocaleLowerCase();
    }
}

export const TAG_EVENT_STANDARD_ACTIONS: Record<StandardAction, { label: string; prototype: TagEventPrototype }> = {
    sees: { label: "Sees", prototype: "subject-object" },
    meets: { label: "Meets", prototype: "subject-object" },
    enters: { label: "Enters", prototype: "subject-object" },
    exits: { label: "Exits", prototype: "subject-object" },
    entersScene: { label: "Enters scene", prototype: "subject" },
    exitsScene: { label: "Exits scene", prototype: "subject" },
    other: { label: "Other", prototype: "subject" },
    transaction: { label: "Transaction", prototype: "subject" },
    departsFrom: { label: "Departs from", prototype: "subject-location" },
    arrivesAt: { label: "Arrives at", prototype: "subject-location" },
    passes: { label: "Passes", prototype: "subject-location" },
    comments: { label: "Comments", prototype: "passive" },
} as const;
