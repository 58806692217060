import { TagEventRelevance, TagEventRelevances } from "cloud-core/tags/TagEvent";
import { Form } from "react-bootstrap";
import { SetStateAction } from "react";
import { capitaliseFirst } from "../../../../utilities/helpers";

export interface TagEventModalRelevanceProps {
    selectedRelevance: TagEventRelevance;
    setSelectedRelevance: (_: SetStateAction<TagEventRelevance>) => void;
}

function TagEventModalRelevance(props: TagEventModalRelevanceProps) {
    function getRelevanceLabel(relevance: TagEventRelevance): string {
        let label: string;
        switch (relevance) {
            case "unset":
                label = "Not set";
                break;
            default:
                label = capitaliseFirst(relevance);
        }
        return label;
    }

    return (
        <>
            <Form.Select
                name="vehicleType"
                value={props.selectedRelevance}
                onChange={(e) => props.setSelectedRelevance(e.target.value as TagEventRelevance)}
                required
            >
                {TagEventRelevances.map((r) => (
                    <option value={r} key={r}>
                        {getRelevanceLabel(r)}
                    </option>
                ))}
            </Form.Select>
        </>
    );
}

export default TagEventModalRelevance;
