import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import OverlaySpinner from "../../../../components/OverlaySpinner";
import { useAdminAddProcessorMutation, useAdminGetProcessorTypesQuery } from "../../../../store/api/kinesense";
import { Notifications } from "../../../../utilities/Notifications/Notifications";
import ProcessorTypeDetails from "./ProcessorTypeDetails";

function AddProcessor() {
    const { data: processorTypes, isSuccess } = useAdminGetProcessorTypesQuery();
    const [requestAddProcessor, result] = useAdminAddProcessorMutation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const [selectedProcessorName, setSelectedProcessorName] = useState<string>("");

    function onChangeProcessorType(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        setSelectedProcessorName(e.currentTarget.value);
    }

    async function handleOnSubmitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        requestAddProcessor({ type: processorTypes[selectedProcessorName].type })
            .unwrap()
            // On success
            .then((result) => {
                Notifications.notify(
                    "Processor Created",
                    `A new processor of type ${result.type} has been created, with ID ${result.processorId}`,
                    "info",
                );

                setSelectedProcessorName("");
                hideModal();
            })
            // On Error
            .catch((e) => Notifications.notify("Error creating a new processor", JSON.stringify(e), "important"));
    }

    return (
        <section className="add-processor">
            <Button variant="success" onClick={showModal}>
                <FontAwesomeIcon icon={faPlusCircle} className="me-1" /> Add Processor
            </Button>

            <Modal
                show={isModalVisible}
                onHide={hideModal}
                aria-labelledby="Add Processor Modal"
                centered
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a New Processor</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleOnSubmitForm} className="position-relative">
                    <OverlaySpinner isLoading={result.isLoading} />

                    <Modal.Body>
                        <Form.Group as="section" className="mb-3" controlId="type">
                            <Form.Select
                                required
                                disabled={result.isLoading}
                                value={selectedProcessorName}
                                onChange={onChangeProcessorType}
                            >
                                <option disabled hidden value="">
                                    Select processor type
                                </option>

                                {isSuccess &&
                                    Object.keys(processorTypes).map((key) => (
                                        <option value={key} key={`processorType-${key}`}>
                                            {processorTypes[key].name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>

                        <ProcessorTypeDetails
                            data={
                                // Default value provided to show an empty table
                                selectedProcessorName === ""
                                    ? { costPerHour: 0, name: "-", type: "-" }
                                    : processorTypes[selectedProcessorName]
                            }
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button disabled={result.isLoading} type="submit" variant="success">
                            Add Processor
                        </Button>
                        <Button disabled={result.isLoading} type="button" variant="secondary" onClick={hideModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    );
}

export default AddProcessor;
