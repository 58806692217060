import Organisations from "./components/organisations/Organisations";
import { Route, Routes } from "react-router-dom";
import Organisation from "./components/organisations/Organisation";
import { Button } from "react-bootstrap";
import { Notifications } from "../../utilities/Notifications/Notifications";
import Processors from "./components/processors/Processors";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import ProcessorDetails from "./components/processors/ProcessorDetails";
import PageNotFound from "../404";

function Admin() {
    return (
        <main className="container admin">
            <Routes>
                <Route index element={<AdminDashboard />} />

                <Route path="organisations">
                    <Route index element={<Organisations />} />
                    <Route path=":organisationId" element={<Organisation />} />
                </Route>

                <Route path="processors">
                    <Route index element={<Processors />} />
                    <Route path=":processorId" element={<ProcessorDetails />} />
                </Route>

                <Route path="*" element={<PageNotFound />} />
            </Routes>

            {/* Temporary buttons to allow for testing/demonstration of notifications */}
            {/* <div className="mt-4 mb-4">
                <Button
                    onClick={() =>
                        Notifications.notify(
                            "Title " + Math.random(),
                            "This in an info message, it will auto hide unless clicked"
                        )
                    }
                >
                    Info
                </Button>
                <Button
                    className="ms-1"
                    onClick={() =>
                        Notifications.notify(
                            "Title " + Math.random(),
                            "This is a important message, it wont auto hide",
                            "important"
                        )
                    }
                >
                    Important
                </Button>
                <Button
                    className="ms-1"
                    onClick={() =>
                        Notifications.notify(
                            "Title " + Math.random(),
                            "This is an warning message, it wont auto hide",
                            "warning"
                        )
                    }
                >
                    Warn
                </Button>
            </div> */}
        </main>
    );
}

export default Admin;
