import { AnalyticsRun } from "../../../store/analytics/Analytics";
import { MediaSourceWithRunSummaries } from "../../../store/media/MediaItems";
import { MediaSource } from "../../media/MediaSource";
import { Entity } from "../Entity";
import { FragmentGroupWithMedia } from "../entityAdapters/FragmentGroupEntityAdapter";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { getDataDescriptionsForAnalyser } from "../dataDescriptions/getDataDescriptionsForAnalyser";
import { DataSource, DataSourceLoadResult, DispatchType } from "./DataSource";
import { FragmentGroup, convertFetchedFragmentGroups } from "../../media/FragmentGroup";
import { kinesenseApiSlice } from "../../../store/api/kinesense";

export class FragmentGroupDataSource implements DataSource {
    type = "frag-group";
    runs: AnalyticsRun[];
    dispatch: DispatchType;
    fragmentGroups: FragmentGroupWithMedia[];
    primaryMediaId: string;
    private isLoaded = false;
    private dataDescriptions: DataDescription[];

    constructor(mediaId: string, runs: AnalyticsRun[], dispatch: DispatchType) {
        this.primaryMediaId = mediaId;
        this.runs = runs;
        this.dispatch = dispatch;
    }

    async load(forceRefetch: boolean = false): Promise<DataSourceLoadResult> {
        const mediaSources = await getMediaSources(this.runs, this.dispatch);

        if (mediaSources == undefined) {
            return;
        }

        await this.loadFragmentGroups(mediaSources, forceRefetch);
        this.loadDataDescriptions(mediaSources);

        this.isLoaded = true;

        const result: DataSourceLoadResult = { state: "loaded" };
        return result;
    }

    getEntities(dataDescription: DataDescription): Entity[] {
        if (!this.isLoaded) {
            return [];
        }

        const entities = this.fragmentGroups
            .map((row) => dataDescription.entityAdapter.convertToEntity(row))
            .filter((e) => e != undefined) as Entity[];

        return entities;
    }

    getDataDescriptions(): DataDescription[] {
        return this.dataDescriptions;
    }

    isSourceFor(signature: unknown): boolean {
        if (!Array.isArray(signature)) {
            return false;
        }

        if (signature.length == 0 || this.runs.length != signature.length) {
            return false;
        }

        return this.runs.every(
            (run, index) => run.mediaId == signature[index].mediaId && run.runId == signature[index].runId,
        );
    }

    async loadFragmentGroups(mediaSources: MediaSourceWithRunSummaries[], forceRefetch: boolean): Promise<void> {
        const mediaSourcesMap = new Map<string, MediaSource>(mediaSources.map((media) => [media.mediaId, media]));
        this.fragmentGroups = [];

        for (const run of this.runs) {
            const mediaSource = mediaSourcesMap.get(run.mediaId);
            try {
                const groups = await getFragmentGroups(mediaSource, run.runId, this.dispatch, forceRefetch);
                const groupsWithMedia = groups.map((group) => ({
                    group,
                    media: mediaSourcesMap.get(run.mediaId),
                    runId: run.runId,
                }));
                this.fragmentGroups.push(...groupsWithMedia);
            } catch (e) {
                console.error(`Error encountered while fetching fragment groups: ${e}`);
            }
        }
    }

    loadDataDescriptions(mediaSources: MediaSourceWithRunSummaries[]) {
        const validDataDescriptions = mediaSources
            .flatMap((media) => media.runs)
            .flatMap((run) => getDataDescriptionsForAnalyser(run.analyserId));

        const uniqueDataDescriptions = [];
        for (const dataDescription of validDataDescriptions) {
            if (!uniqueDataDescriptions.includes(dataDescription)) {
                uniqueDataDescriptions.push(dataDescription);
            }
        }

        this.dataDescriptions = uniqueDataDescriptions;
    }
}

export async function getMediaSources(
    ids: { projectId: string; mediaId: string }[],
    dispatch: DispatchType,
): Promise<MediaSourceWithRunSummaries[]> {
    const mediaSources = [];

    for (const id of ids) {
        // might want to see if we need to do .select first and only .initiate if we don't have data yet
        const mediaSource = await dispatch(
            kinesenseApiSlice.endpoints.getMediaSource.initiate(id) as DispatchType,
        ).unwrap();

        if (mediaSource != undefined) {
            mediaSources.push(mediaSource);
        }
    }

    return mediaSources;
}

export async function getFragmentGroups(
    mediaSource: MediaSource,
    runId: number,
    dispatch: DispatchType,
    forceRefetch: boolean,
): Promise<FragmentGroup[]> {
    const groupsQuery = {
        mediaId: mediaSource.mediaId,
        runId: runId,
        filterId: undefined,
    };

    const fragmentGroups = await dispatch(
        kinesenseApiSlice.endpoints.getFragmentGroups.initiate(groupsQuery, { forceRefetch }) as DispatchType,
    ).unwrap();

    return convertFetchedFragmentGroups(fragmentGroups, mediaSource.startsAt);
}
