import { Entity } from "../Entity";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { DataSource, DataSourceLoadResult, DispatchType } from "./DataSource";
import { kinesenseApiSlice } from "../../../store/api/kinesense";
import { TagEventDataDescription } from "../dataDescriptions/TagEventDataDescription";
import { TagEvent } from "../../tags/TagEvent";
import { MediaSource } from "../../media/MediaSource";

export class TagEventsDataSource implements DataSource {
    type = "tag-events";
    private isLoaded = false;
    private projectId: string;
    private dispatch: DispatchType;
    private tagEvents: TagEvent[];
    private dataDescriptions: DataDescription[];
    private mediaSources: Map<string, MediaSource[]>;

    constructor(projectId: string, dispatch: DispatchType) {
        this.projectId = projectId;
        this.dispatch = dispatch;
        this.dataDescriptions = [new TagEventDataDescription()];
    }

    async load(forceRefetch: boolean = false): Promise<DataSourceLoadResult> {
        this.mediaSources = await getMediaSources(this.projectId, this.dispatch, forceRefetch);
        this.tagEvents = await this.dispatch(
            kinesenseApiSlice.endpoints.getAllTagEvents.initiate(
                { projectId: this.projectId },
                { forceRefetch },
            ) as DispatchType,
        ).unwrap();

        this.isLoaded = true;
        const result: DataSourceLoadResult = { state: "loaded" };
        return result;
    }

    getEntities(dataDescription: DataDescription): Entity[] {
        if (!this.isLoaded) {
            return [];
        }

        const entities = this.tagEvents
            .map((event) =>
                dataDescription.entityAdapter.convertToEntity({
                    tagEvent: event,
                    media: this.mediaSources.get(event.occurrences[0].mediaId),
                }),
            )
            .filter((e) => e != undefined) as Entity[];

        return entities;
    }

    getDataDescriptions(): DataDescription[] {
        return this.dataDescriptions;
    }

    isSourceFor(signature: unknown): boolean {
        if (typeof signature !== "string") {
            return false;
        }

        return this.projectId === signature;
    }
}

export async function getMediaSources(
    projectId: string,
    dispatch: DispatchType,
    forceRefetch: boolean,
): Promise<Map<string, MediaSource[]>> {
    const mediaSources = await dispatch(
        kinesenseApiSlice.endpoints.getAllMedia.initiate({ projectId }, { forceRefetch }) as DispatchType,
    ).unwrap();

    return new Map<string, MediaSource[]>(mediaSources.map((media) => [media.mediaId, media]));
}
