import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { ApplicationState } from "../../../store";
import NewPasswordInput, { PasswordChangedEventArgs } from "../../auth/components/NewPasswordInput";
import { Notifications } from "../../../utilities/Notifications/Notifications";

function SecurityTab() {
    const user = useSelector((state: ApplicationState) => state.user);

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
    const clearPasswordInputsRef = useRef<() => void>(undefined);

    function handleNewPasswordChanged(data: PasswordChangedEventArgs) {
        setNewPassword(data.password);
        setIsPasswordValid(data.isValid);
    }

    function onPasswordVisibilityChanged(isVisible: boolean) {
        setIsPasswordRevealed(isVisible);
    }

    function handlePasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setPassword(event.target.value);
    }

    function clearInputs() {
        setPassword("");
        setNewPassword("");
        setIsPasswordRevealed(false);
        setIsPasswordValid(false);

        if (clearPasswordInputsRef?.current !== undefined) {
            clearPasswordInputsRef.current();
        }
    }

    function resetPassword() {
        if (!isPasswordValid) {
            return;
        }

        user.user.changePassword(password, newPassword, (err, _result) => {
            if (err?.message !== undefined) {
                Notifications.notify("Error resetting password", err.message, "important");
                return;
            }

            Notifications.notify("Password reset", "Your password has been updated", "important");
            clearInputs();
        });
    }

    return (
        <div className="security-tab">
            <section>
                <h5 className="h6">Reset Password</h5>

                <div className="gap-2 d-flex flex-column">
                    <Form.Control
                        size="sm"
                        type={isPasswordRevealed ? "text" : "password"}
                        placeholder="Current Password"
                        onChange={handlePasswordChanged}
                        value={password}
                        isInvalid={password !== "" && password.length < 10}
                    />

                    <NewPasswordInput
                        size="sm"
                        passwordChanged={handleNewPasswordChanged}
                        passwordVisibilityChanged={onPasswordVisibilityChanged}
                        clearInputsFnRef={clearPasswordInputsRef}
                    />

                    <div className="mt-1 text-center">
                        <Button onClick={resetPassword} disabled={!isPasswordValid}>
                            Confirm Password
                        </Button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SecurityTab;
