import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatWizard } from "@fortawesome/free-solid-svg-icons";

function NoMediaSourcesErrorPage() {
    return (
        <main className="mx-auto mt-0 text-center d-flex flex-column justify-content-center align-items-center error-page">
            <h1 className="mb-1 fs-2 text-shadow fw-bold">No videos found</h1>
            <h2 className="mb-4 fs-4">Add a video using the Import Wizard</h2>

            <Link className="gap-2 btn btn-primary d-flex align-items-center" to="/import-wizard">
                <FontAwesomeIcon icon={faHatWizard} /> Import
            </Link>
        </main>
    );
}

export default NoMediaSourcesErrorPage;
