import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { AnalyticsMetadata } from "../../../../models/analysis/Config";
import { AnalyticsMetadataGroupWithIds, AnalyticsMetadataWithIds } from "./MetadataEditor";
import MetadataEditorFields from "./MetadataEditorFields";

export interface MetadataEditorGroupProps {
    isLoaded: boolean;
    localMetadata: AnalyticsMetadataWithIds;
    setLocalMetadata: (update: React.SetStateAction<AnalyticsMetadataWithIds>) => void;
    onChanged: () => void;
    metadata: AnalyticsMetadata;
    groupIndex: number;
}

function MetadataEditorGroup(props: MetadataEditorGroupProps) {
    const group = props.localMetadata.groups[props.groupIndex];

    function isGroupNameInvalid(groupName: string): boolean {
        return groupName === "" || props.localMetadata.groups.filter((g) => g.name === groupName).length > 1;
    }

    function deleteGroup() {
        props.setLocalMetadata((oldMetadata) => {
            const newMetadata: AnalyticsMetadataWithIds = {
                groups: [
                    ...oldMetadata.groups.slice(0, props.groupIndex),
                    ...oldMetadata.groups.slice(props.groupIndex + 1),
                ],
            };

            return newMetadata;
        });
        props.onChanged();
    }

    function handleOnChangeGroupNameInput(e: React.ChangeEvent<HTMLInputElement>) {
        props.setLocalMetadata((oldMetadata) => {
            const newGroup: AnalyticsMetadataGroupWithIds = {
                groupId: group.groupId,
                name: e.target.value,
                entries: [...group.entries],
            };

            const newMetadata: AnalyticsMetadataWithIds = { groups: [...oldMetadata.groups] };
            newMetadata.groups[props.groupIndex] = newGroup;

            return newMetadata;
        });
        props.onChanged();
    }

    return (
        <section className="p-4 rounded border shadow-sm group">
            <Form.Control
                value={group.name}
                onChange={handleOnChangeGroupNameInput}
                isInvalid={isGroupNameInvalid(group.name)}
                title="Rename group"
            />
            <button className="button-with-icon" onClick={deleteGroup} title="Delete group">
                <FontAwesomeIcon icon={faTrash} />
            </button>

            <MetadataEditorFields {...props} />
        </section>
    );
}

export default MetadataEditorGroup;
