import { ListGroup } from "react-bootstrap";
import useToggleUserConfigOption from "./useToggleUserConfigOption";
import UserConfigSwitch from "./UserConfigSwitch";
import useUserConfig from "../../../hooks/useUserConfig";

function NotificationsTab() {
    const { userConfig, updateUserConfig } = useUserConfig();

    // ANALYSIS COMPLETE EMAIL
    const [localEnableAnalysisCompleteEmail, isUpdatingEnableAnalsysCompleteEmail, toggleEnableAnalysisCompleteEmail] =
        useToggleUserConfigOption(
            userConfig.enableAnalysisCompleteEmail,
            (enableAnalysisCompleteEmail: boolean) => updateUserConfig({ enableAnalysisCompleteEmail }).unwrap(),
            "Error toggling analysis complete email notification",
        );

    return (
        <ListGroup className="notifications-tab">
            <UserConfigSwitch
                label="Analysis completed email"
                title={"Enable/Disable email notifications for when an analysis you requested is completed"}
                isChecked={localEnableAnalysisCompleteEmail}
                isUpdating={isUpdatingEnableAnalsysCompleteEmail}
                onChange={toggleEnableAnalysisCompleteEmail}
            />
        </ListGroup>
    );
}

export default NotificationsTab;
