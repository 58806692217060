import { useDispatch } from "react-redux";
import { Entity } from "../../../../models/viz/Entity";
import * as AnalyticsStore from "../../../../store/analytics/Analytics";
import { VizListProps } from "./VizList";
import { LargeIconListItem } from "./LargeIconListItem";
import { useVizContext } from "../../../../models/viz/operations/DataOperation";

export function LargeIconList(props: VizListProps) {
    const dispatch = useDispatch();
    const context = useVizContext(props.viewId);
    const view = context.view;
    const selectedEntities = view?.selectedEntities ?? [];

    function itemClicked(entity: Entity, event: React.MouseEvent) {
        dispatch(AnalyticsStore.actionCreators.setSelectedEntity(props.viewId, entity));

        // By default, replace selected entities
        let newSelectedEntities = [entity];

        // If holding <Ctrl>, append the selected entity to the selected entities
        if (event.ctrlKey) {
            newSelectedEntities = [...selectedEntities, entity];
        }
        // If holding <Shift>, append all entities between the last selected entity and the current one
        else if (event.shiftKey) {
            newSelectedEntities = [...selectedEntities];
            const allEntities = view?.filteredEntities ?? [];
            let start = allEntities.findIndex((e) => e.id === view?.selectedEntity?.id);
            let end = allEntities.findIndex((e) => e.id === entity.id);
            if (start !== undefined && end !== undefined) {
                if (start > end) {
                    [start, end] = [end, start];
                }

                for (const e of allEntities.slice(start, end + 1)) {
                    if (!newSelectedEntities.includes(e)) {
                        newSelectedEntities.push(e);
                    }
                }
            }
        }

        dispatch(AnalyticsStore.actionCreators.setSelectedEntities(props.viewId, newSelectedEntities));
    }

    return (
        <>
            {context.view?.filteredEntities?.map((e) => {
                return (
                    <LargeIconListItem
                        key={e.id}
                        isSelected={selectedEntities.find((entity) => entity.id == e.id) !== undefined}
                        onClick={itemClicked}
                        entity={e}
                        context={context}
                    />
                );
            })}
        </>
    );
}
