import { TimelineEntityVm } from "../views/timeline/components/TimelineEntityVm";

export interface DateRowData {
    startsAt: number;
    endsAt: number;
    dataRow: number;
}

export interface Point {
    x: number;
    y: number;
}

export interface Line {
    a: Point;
    b: Point;
}

export function distributeRowData(data: TimelineEntityVm[], maxRowCount: number) {
    // sort data in ascending order with respect to endsAt date
    data = data.sort((a: TimelineEntityVm, b: TimelineEntityVm) => b.endsAt - a.endsAt);

    // consider whole data is overlapped, as every point dataRow is 0 initially
    let overlappedData = data;

    // iterate till maxRowCount
    for (let rowCount = 0; rowCount <= maxRowCount; rowCount += 1) {
        overlappedData = overlappedData.filter((overlapped) => overlapped.dataRow === rowCount);

        if (overlappedData.length === 0) {
            break;
        }

        overlappedData = iterateOverData(overlappedData, maxRowCount);
    }

    return data;
}

function iterateOverData(data: TimelineEntityVm[], maxRowCount: number) {
    for (let i = 0; i < data.length - 1; i += 1) {
        for (let j = i + 1; j < data.length; j += 1) {
            if (doTimesOverlap(data[i].startsAt, data[i].endsAt, data[j].startsAt, data[j].endsAt)) {
                data[j].dataRow =
                    data[j].dataRow + 1 < maxRowCount // check row number is less than maxRowCount
                        ? data[j].dataRow + 1 // if it less then assign rownumber
                        : Math.floor(Math.random() * (maxRowCount - 0) + 0); // else assign any random row number betweeen 0 to maxRowCount
            }
        }
    }

    return data;
}

function doTimesOverlap(firstTimeStart: number, firstTimeEnd: number, secondTimeStart: number, secondTimeEnd: number) {
    return (
        (firstTimeStart < secondTimeStart && secondTimeStart < firstTimeEnd) || // second time starts in first time
        (firstTimeStart < secondTimeEnd && secondTimeEnd < firstTimeEnd) || // second time ends in first time
        (secondTimeStart < firstTimeStart && firstTimeEnd < secondTimeEnd)
    ); // firstDate in secondDate
}

function distancePointToLine(point: Point, line: Line): number {
    const dividend = (line.b.x - line.a.x) * (line.a.y - point.y) - (line.a.x - point.x) * (line.b.y - line.a.y);
    const divisor = Math.pow(line.b.x - line.a.x, 2) + Math.pow(line.b.y - line.a.y, 2);

    return Math.abs(dividend) / Math.sqrt(divisor);
}

export function getClosestPoint(points: Array<{ x: number; y: number }>, pointX: number, pointY: number) {
    return points.reduce(
        ({ distance, prev, index }, point, currentIndex) => {
            const line = { a: prev, b: point };
            const _distance = distancePointToLine({ x: pointX, y: pointY }, line);
            index = distance < _distance ? index : currentIndex;
            distance = distance < _distance ? distance : _distance;
            prev = point;
            return { index, distance, prev, line };
        },
        { distance: 1000, prev: points[points.length - 1], index: -1 },
    );
}
