import { FragmentGroup as FragmentGroupBase } from "cloud-core/analytics/FragmentGroup";

export type FetchedFragmentGroup = FragmentGroupBase;
export interface FragmentGroup extends Omit<FetchedFragmentGroup, "startOffset" | "endOffset"> {
    startsAt: number;
    endsAt: number;
}

export function convertFetchedFragmentGroups(fetchedFragmentGroups: FetchedFragmentGroup[], mediaStartsAt: number) {
    const convertedFragmentGroups: FragmentGroup[] = [];

    for (const fetchedFragmentGroup of fetchedFragmentGroups) {
        const { startOffset, endOffset, ...props } = fetchedFragmentGroup;

        convertedFragmentGroups.push({
            ...props,
            startsAt: mediaStartsAt + startOffset,
            endsAt: mediaStartsAt + endOffset,
        });
    }

    return convertedFragmentGroups;
}
