import { SetStateAction, useMemo } from "react";
import Select, { MultiValue } from "react-select";
import { TagObjectReactSelectOption as TagObjectReactSelectOption } from "../EditTagEventModal";
import OverlaySpinner from "../../../OverlaySpinner";
import { TagObject } from "../../../../models/tags/TagObject";
import { TagEventModalAddTagObjectButton } from "./TagEventModalAddTagObjectButton";
import { VizContext } from "../../../../models/viz/operations/DataOperation";

export interface TagEventModalLocationProps {
    context: VizContext;
    tagObjects: TagObject[];
    isFetchingTagObjects: boolean;
    selectedLocations: TagObjectReactSelectOption[];
    setSelectedLocations: (_: SetStateAction<TagObjectReactSelectOption[]>) => void;
    showLocation: boolean;
}
function TagEventModalLocation(props: TagEventModalLocationProps) {
    function handleOnChangeLocation(e: MultiValue<TagObjectReactSelectOption>) {
        props.setSelectedLocations([...e]);
    }

    const options = useMemo(() => {
        return (props.tagObjects ?? [])
            .filter((o) => o.type === "location")
            .map((tagObject: TagObject) => ({
                value: tagObject.objectId,
                label: tagObject.name,
            }));
    }, [props.isFetchingTagObjects]);

    function onCreatedCallback(obj: TagObject) {
        if (obj?.type !== "location") {
            return;
        }

        props.setSelectedLocations((prev) => {
            return [...prev, { value: obj.objectId, label: obj.name }];
        });
    }

    return (
        <div className="gap-1 d-flex">
            <div className="position-relative flex-grow-1">
                <OverlaySpinner isLoading={props.isFetchingTagObjects} />
                {props.showLocation && (
                    <Select
                        placeholder="Select a location"
                        classNamePrefix="react-select"
                        className="flex-grow-1"
                        value={props.selectedLocations}
                        options={options}
                        onChange={handleOnChangeLocation}
                        isMulti
                        required
                    />
                )}
            </div>
            <TagEventModalAddTagObjectButton context={props.context} onCreatedCallback={onCreatedCallback} />
        </div>
    );
}

export default TagEventModalLocation;
