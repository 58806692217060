import { VizContainerProps } from "./VizContainer";
import { useVizContext } from "../../../models/viz/operations/DataOperation";
import { DataOperationButton } from "./Shared/DataOperationButton";

export type VizOperationsProps = VizContainerProps;

export function VizOperations(props: VizOperationsProps) {
    const context = useVizContext(props.viewId);
    const view = context.view;

    if (view?.currentDataDescription === undefined) {
        return;
    }

    return (
        <div className="gap-3 viz-operations d-flex">
            {view?.currentDataDescription.operations
                .filter((operation) => operation.context == "global" || operation.context == "entity")
                .map((operation, i) => {
                    return (
                        <DataOperationButton
                            key={i}
                            operation={operation}
                            context={context}
                            buttonProps={{ size: "sm" }}
                        />
                    );
                })}
        </div>
    );
}
