import { Button, ListGroup, Modal, Table } from "react-bootstrap";
import { FormData, FormField } from "./NewUserModal";
import CopySnippetToClipboard from "../../../../../components/CopySnippetToClipboard";

export type CreatedUserData = FormData & { temporaryPassword: string };

export interface NewUserCreatedModalProps {
    isVisible: boolean;
    setIsVisible: (nextIsVisible: boolean) => void;
    createdUserData: CreatedUserData;
    setCreatedUserData: (createdUserData: CreatedUserData | undefined) => void;
}

export default function NewUserCreatedModal(props: NewUserCreatedModalProps) {
    const resetCreatedUserData = () => props.setCreatedUserData(undefined);
    const hideModal = () => props.setIsVisible(false);

    return (
        <Modal
            id="NewUserCreatedModal"
            show={props.isVisible}
            backdrop="static"
            keyboard={false}
            centered
            onExited={resetCreatedUserData}
        >
            <Modal.Header>
                <Modal.Title className="fw-bold text-success">A new user has been created!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive>
                    <tbody>
                        <tr>
                            <th scope="row">Name</th>
                            <td>{props.createdUserData[FormField.Name]}</td>
                        </tr>
                        <tr>
                            <th scope="row">Username</th>
                            <td>{props.createdUserData[FormField.Username]}</td>
                        </tr>
                        <tr>
                            <th scope="row">Email</th>
                            <td>{props.createdUserData[FormField.Email]}</td>
                        </tr>
                    </tbody>
                </Table>

                <ListGroup>
                    <ListGroup.Item>
                        <strong className="my-auto">Temporary Password</strong>
                    </ListGroup.Item>
                    <ListGroup.Item className="p-0">
                        <CopySnippetToClipboard snippet={props.createdUserData.temporaryPassword} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <p className="mt-3 fs-6">
                            <span className="text-decoration-underline fw-bold text-danger">Important!</span> Copy and
                            send the new user this password. You can not view this password again after you close this
                            dialog.
                        </p>
                        <p>The user will create a new password on first log in.</p>
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
