import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import OptionFilter from "../../../views/viz/components/Filtering/OptionFilter";
import { MediaSource } from "../../media/MediaSource";
import { Entity } from "../Entity";
import { EntityFilter } from "./EntityFilter";
import { StringPropertyGetter } from "./StringEntityFilter";

export type AvailableOption = { value: string; label: string; isFixed: boolean };
export const DEFAULT_OPTION = { value: "all", label: "All", isFixed: true } as const;

export type OptionEntityFilterParameter = { options: string[] };

export class OptionEntityFilter extends EntityFilter {
    availableOptions: AvailableOption[];
    predicate: (entities: Entity[], parameter: OptionEntityFilterParameter) => Entity[];

    constructor(
        displayName: string,
        predicate: (entities: Entity[], parameter: OptionEntityFilterParameter) => Entity[],
        availableOptions: { value: string; label: string }[],
    ) {
        super(displayName);
        this.predicate = predicate;
        this.availableOptions = availableOptions.map((o) => ({ ...o, isFixed: false }));
        this.availableOptions.unshift(DEFAULT_OPTION);
    }

    createComponent(filterId: string, view: AnalyticsStateView, options?: unknown): JSX.Element {
        return <OptionFilter filterId={filterId} viewId={view.viewId} availableOptions={this.availableOptions} />;
    }
}

export class DataOptionEntityFilter extends OptionEntityFilter {
    availableOptionsGenerator: (entities: Entity[]) => AvailableOption[];

    constructor(
        displayName: string,
        predicate: (entities: Entity[], parameter: OptionEntityFilterParameter) => Entity[],
        availableOptionsGenerator: (entities: Entity[]) => AvailableOption[],
    ) {
        super(displayName, predicate, []);
        this.availableOptionsGenerator = availableOptionsGenerator;
    }

    override createComponent(filterId: string, view: AnalyticsStateView, options?: unknown): JSX.Element {
        const availableOptions = this.availableOptionsGenerator(view.entities);
        availableOptions.unshift(DEFAULT_OPTION);

        return <OptionFilter filterId={filterId} viewId={view.viewId} availableOptions={availableOptions} />;
    }
}

export function StringPropertyOptionFilterPredicate(
    key: string,
): (entities: Entity[], parameter: OptionEntityFilterParameter) => Entity[] {
    const propertyGetter = StringPropertyGetter(key);

    return (entities: Entity[], parameter: OptionEntityFilterParameter) => {
        return entities.filter((e) => parameter.options.includes(propertyGetter(e)));
    };
}
