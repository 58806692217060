import { VizContainerProps } from "./VizContainer";
import "../Viz.scss";
import { EntityImageDisplay } from "./Shared/EntityImageDisplay";
import { useView } from "../../../hooks/useView";

export type VizPreviewProps = VizContainerProps;

export function VizPreview(props: VizPreviewProps) {
    const { view } = useView(props.viewId);
    const selectedEntity = view?.selectedEntity;

    return (
        <div className="viz-preview viz-panel">
            <div className="viz-preview-title">{selectedEntity?.getTitle()}</div>
            <div className="viz-preview-thumbnail position-relative">
                {selectedEntity && (
                    <EntityImageDisplay
                        key={selectedEntity.id}
                        entityImage={selectedEntity.images.getMainImage()}
                        mediaId={selectedEntity.sourceObject.mediaSource?.mediaId}
                    />
                )}
            </div>
        </div>
    );
}
