import { MutableRefObject, useRef } from "react";
import { TimelineHoverText } from "./TimelineHoverText";
import { TimelineMarker } from "./TimelineMarker";
import { TimelineSelection } from "./TimelineSelection";

function useTimelineMarkers(
    timelineRef: MutableRefObject<SVGSVGElement | undefined>,
    cursor: number,
    secondaryCursor: number,
    xScale: MutableRefObject<d3.ScaleTime<number, number, never> | undefined>,
) {
    const cursorMarker = useRef<TimelineMarker>(undefined);
    const secondaryCursorMarker = useRef<TimelineMarker>(undefined);
    const hoverMarker = useRef<TimelineMarker>(undefined);
    const hoverText = useRef<TimelineHoverText>(undefined);
    const selection = useRef<TimelineSelection>(undefined);

    /** Update cursor markers */
    function updateCursors() {
        if (cursor !== undefined) {
            cursorMarker.current.show();
            const newPosition = xScale.current(cursor);
            if (cursorMarker.current.position != newPosition) {
                cursorMarker.current.setPosition(newPosition);
            }
        }

        if (secondaryCursor !== undefined) {
            const newPosition = xScale.current(secondaryCursor);
            if (secondaryCursorMarker.current.position != newPosition) {
                secondaryCursorMarker.current.setPosition(newPosition);
            }
        }

        selection.current.update();
    }

    /** Used for setting up the timeline markers, after the timeline has been drawn for the first time */
    function setMarkers() {
        selection.current ??= new TimelineSelection(timelineRef);
        secondaryCursorMarker.current ??= new TimelineMarker(timelineRef, { colour: "orange" });
        cursorMarker.current ??= new TimelineMarker(timelineRef, { colour: "blue" });
        hoverMarker.current ??= new TimelineMarker(timelineRef, {});
        if (!selection.current.firstMarker || !selection.current.secondMarker) {
            selection.current.setMarkers(secondaryCursorMarker.current, cursorMarker.current);
        }
        hoverText.current ??= new TimelineHoverText(timelineRef);
    }

    return {
        cursorMarker,
        secondaryCursorMarker,
        hoverMarker,
        hoverText,
        selection,
        updateCursors,
        setMarkers,
    };
}

export default useTimelineMarkers;
