import { useView } from "../../../hooks/useView";
import { VizList } from "./List/VizList";

export interface VizContainerProps {
    viewId: string;
}

export function VizContainer(props: VizContainerProps) {
    const { view } = useView(props.viewId);

    const renderVisualisation = () => {
        switch (view?.representation) {
            case "large-icons":
            case "detail-list":
                return <VizList viewId={props.viewId} />;
            case "map":
                return <div>Map</div>;
            default:
                return <div>No Data</div>;
        }
    };

    return <div className="viz-container">{view !== undefined && renderVisualisation()}</div>;
}
