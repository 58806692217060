import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import { Entity } from "../Entity";

export abstract class EntityFilter {
    displayName: string;
    defaultParams: unknown = undefined;

    constructor(displayName: string) {
        this.displayName = displayName;
    }

    abstract predicate(entities: Entity[], parameter: unknown): Entity[];
    abstract createComponent(filterId: string, view: AnalyticsStateView, options?: unknown): JSX.Element;

    withDefaultParams(defaultParams: unknown): this {
        this.defaultParams = defaultParams;
        return this;
    }
}
