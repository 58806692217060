export const Theme = {
    LIGHT: "light",
    DARK: "dark",
} as const;
export type Theme = (typeof Theme)[keyof typeof Theme];

export interface UserConfig {
    uiLanguage: string;
    uiTheme: Theme;
    showDebugUi: boolean;
    showCustomContextMenus: boolean;
    showMockDem: boolean;
    enableAnalysisCompleteEmail: boolean;
}
