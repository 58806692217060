import { useSelector } from "react-redux";
import { Theme, UserConfig } from "../models/UserConfig";
import { ApplicationState } from "../store";
import * as UserStore from "../store/user/User";
import { useGetCurrentUserConfigQuery, useUpdateCurrentUserConfigMutation } from "../store/api/kinesense";

/** Returns the default theme provided by the user's preferred color scheme setting */
function getDefaultTheme(): Theme {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? Theme.DARK : Theme.LIGHT;
}

/** Utility hook for easily accessing user config options and providing them default values */
function useUserConfig() {
    const user = useSelector<ApplicationState, UserStore.UserState>((state) => state?.user);

    // Skip fetching user config until user.session is defined (for auth)
    const {
        data: fetchedConfig,
        isFetching,
        isSuccess,
    } = useGetCurrentUserConfigQuery(null, {
        skip: user?.session === undefined,
    });

    const [updateUserConfig] = useUpdateCurrentUserConfigMutation();

    return {
        userConfig: {
            uiLanguage: fetchedConfig?.uiLanguage ?? "en",
            uiTheme: fetchedConfig?.uiTheme ?? getDefaultTheme(),
            showDebugUi: fetchedConfig?.showDebugUi ?? false,
            showCustomContextMenus: fetchedConfig?.showCustomContextMenus ?? true,
            showMockDem: fetchedConfig?.showMockDem ?? false,
            enableAnalysisCompleteEmail: fetchedConfig?.enableAnalysisCompleteEmail ?? true,
        } as UserConfig,
        isFetching,
        isSuccess,
        updateUserConfig,
    };
}

export default useUserConfig;
