import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { useView } from "../../../../hooks/useView";

export interface FiltersProps {
    viewId: string;
}

function Filters(props: FiltersProps) {
    const { view } = useView(props.viewId);

    const isLoading = view === undefined || view?.data.state !== "loaded";

    return (
        <ConditionalSpinner isLoading={isLoading}>
            {!isLoading && (
                <div className="viz-filter">
                    {view.currentDataDescription?.filters["bounds"]?.createComponent("bounds", view, {
                        hasInlineCropSelector: true,
                    })}
                </div>
            )}
        </ConditionalSpinner>
    );
}

export default Filters;
