import { useGetFragmentImageCookiesQuery, useGetStoredFileQuery } from "../../../../store/api/kinesense";
import { useEffect, useRef, useState } from "react";
import {
    EntityImage,
    FileImageEntityImage,
    FileVideoFrameEntityImage,
    ThumbnailEntityImage,
} from "../../../../models/viz/Entity";
import ThumbnailExtractor from "../../../../utilities/ThumbnailExtractor";
import LoadingLabel from "../../../../components/LoadingLabel";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";

export interface EntityImageDisplayProps {
    entityImage: EntityImage;
    mediaId: string | undefined;
}

export function EntityImageDisplay(props: EntityImageDisplayProps) {
    const organisationId = useSelector((state: ApplicationState) => state.user?.organisationId);
    const hasNoImage = (props.entityImage !== undefined && !props.entityImage.hasImage()) ?? true;

    const fileId = (props.entityImage as FileVideoFrameEntityImage)?.fileId;

    const {
        data: videoFile,
        isError: isErrorVideoFile,
        isSuccess: isSuccessVideoFile,
        isFetching: isFetchingVideoFile,
    } = useGetStoredFileQuery(
        { fileId },
        { skip: fileId === undefined || props.entityImage.origin !== "videoFileFrame" },
    );
    const hasLoadedVideoFile = isSuccessVideoFile && !isFetchingVideoFile;

    const {
        data: imageFile,
        isError: isErrorImageFile,
        isSuccess: isSuccessImageFile,
        isFetching: isFetchingImageFile,
    } = useGetStoredFileQuery({ fileId }, { skip: fileId === undefined || props.entityImage.origin !== "imageFile" });
    const hasLoadedImageFile = isSuccessImageFile && !isFetchingVideoFile;

    const {
        data: cookies,
        isSuccess: isSuccessCookies,
        isFetching: isFetchingCookies,
        isError: isErrorCookies,
    } = useGetFragmentImageCookiesQuery(
        { mediaId: props.mediaId },
        { skip: props.mediaId === undefined || props.entityImage?.origin !== "thumbnail" },
    );
    const hasLoadedCookies = isSuccessCookies && !isFetchingCookies;

    const [isLoading, setIsLoading] = useState(true);
    const [thumbnailSrc, setThumbnailSrc] = useState<string | undefined>(undefined);

    // Only unset loading state when data for the image has loaded
    const imgRef = useRef<HTMLImageElement>(null);
    useEffect(() => {
        if (!imgRef.current) {
            return;
        }

        imgRef.current.onload = () => setIsLoading(false);
    }, [imgRef.current]);

    // Keep track of error in loading image
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        setIsError(isErrorVideoFile || isErrorCookies || isErrorImageFile);
    }, [isErrorVideoFile, isErrorImageFile, isErrorCookies]);

    // Option 1: Fetch thumbnail for the image
    useEffect(() => {
        if (cookies === undefined) {
            return;
        }
        const imageAsThumbnail = props.entityImage as ThumbnailEntityImage;

        // HACK: pass cookies in as query parameters to avoid CORS related issues with credentials
        fetch(
            `https://images.${process.env.REACT_APP_KINESENSE_API_BASE.replace("api.", "")}/${organisationId}/${
                imageAsThumbnail.mediaId
            }/${imageAsThumbnail.runId}/${imageAsThumbnail.fragGroupId}/${imageAsThumbnail.fragmentId}.jpg?Policy=${
                cookies["CloudFront-Policy"]
            }&Key-Pair-Id=${cookies["CloudFront-Key-Pair-Id"]}&Signature=${cookies["CloudFront-Signature"]}`,
        )
            .then((d) => {
                return d.blob();
            })
            .then((b) => {
                const url = URL.createObjectURL(b);
                setThumbnailSrc(url);
            })
            .catch((e) => {
                setIsError(true);
                console.error(e);
            });
    }, [hasLoadedCookies]);

    // Option 2: Use fetched image file
    useEffect(() => {
        if (props.entityImage?.origin != "imageFile" || !hasLoadedImageFile || !imageFile?.accessUrl?.url) {
            return;
        }

        setThumbnailSrc(imageFile.accessUrl.url);
    }, [hasLoadedImageFile, props.entityImage]);

    // Option 3: Generate thumbnail for the image from the fetched video file
    useEffect(() => {
        if (props.entityImage?.origin != "videoFileFrame" || !hasLoadedVideoFile || !videoFile?.accessUrl?.url) {
            return;
        }

        const time = props.entityImage.timestamp;
        const bounds = props.entityImage.bounds;

        ThumbnailExtractor.getThumbnail(videoFile.accessUrl.url, bounds, time)
            .then((r) => {
                setThumbnailSrc(r);
            })
            .catch((e) => {
                setIsError(true);
                console.error(e);
            });
    }, [hasLoadedVideoFile, props.entityImage]);

    return (
        <>
            {isLoading ? (
                <LoadingLabel isLoading={true} />
            ) : hasNoImage || isError ? (
                <div aria-label="No image available" className="no-image">
                    No image
                </div>
            ) : undefined}
            <img
                ref={imgRef}
                src={thumbnailSrc}
                style={{ height: "100%", maxWidth: "100%", margin: "auto", display: isLoading ? "none" : "block" }}
            />
        </>
    );
}
