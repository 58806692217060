import { Container, Spinner } from "react-bootstrap";
import "./OverlaySpinner.scss";

export interface OverlaySpinnerProps {
    isLoading: boolean;
    size?: "sm";
}

function OverlaySpinner(props: OverlaySpinnerProps) {
    if (!props.isLoading) {
        return null;
    }

    return (
        <Container className="overlay-spinner d-flex justify-content-center align-items-center">
            <Spinner size={props.size} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </Container>
    );
}

export default OverlaySpinner;
