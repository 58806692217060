import { useEffect, useRef, useState } from "react";

function useInterval(callback: () => void, millisecondsDelay: number, initialIsPlaying = true) {
    const [isIntervalPlaying, setIsIntervalPlaying] = useState(initialIsPlaying);

    const callbackRef = useRef(callback);
    const intervalRef = useRef<NodeJS.Timeout>(undefined);

    function pauseInterval() {
        setIsIntervalPlaying(false);
    }

    function resumeInterval() {
        setIsIntervalPlaying(true);
    }

    useEffect(() => {
        callbackRef.current = callback;

        if (!isIntervalPlaying) {
            callback();
        }
    }, [callback]);

    useEffect(() => {
        if (!millisecondsDelay) {
            return;
        }

        function update() {
            callbackRef.current();
        }

        if (isIntervalPlaying) {
            intervalRef.current = setInterval(update, millisecondsDelay);
        }

        return () => clearInterval(intervalRef.current);
    }, [millisecondsDelay, isIntervalPlaying]);

    return { intervalRef, pauseInterval, resumeInterval };
}

export default useInterval;
