import { SetStateAction } from "react";
import { createRoot } from "react-dom/client";
import { PercentCrop } from "react-image-crop";

type SetIsSelectingFn = (_: SetStateAction<boolean>) => void;
type SetComponentCropFn = (_: SetStateAction<PercentCrop>) => void;

export class VideoImageSelector {
    public isSelecting: boolean = false;
    private setIsSelecting: SetIsSelectingFn;
    private crop: PercentCrop;
    private componentRenderer: React.FC | undefined;
    public setComponentCrop: SetComponentCropFn;

    constructor() {
        this.endSelection = this.endSelection.bind(this);
    }

    // Append component to `react-select` selection element so that it is placed relative to that
    public renderComponent() {
        if (this.componentRenderer === undefined) {
            return;
        }

        const el = document.getElementsByClassName("ReactCrop__crop-selection");
        if (el.length == 0) {
            return;
        }

        const div = document.createElement("div");
        div.className = "ReactCrop__selection-options";
        const root = createRoot(div);

        root.render(<this.componentRenderer />);
        el[0].replaceChildren(el[0].children[0]);
        el[0].appendChild(div);
    }

    public beginSelection(componentRenderer: React.FC, initialCrop?: PercentCrop) {
        if (initialCrop !== undefined) {
            this.setComponentCrop(initialCrop);
        }

        this.componentRenderer = componentRenderer;
        this.setIsSelecting(true);
    }
    public endSelection() {
        this.setIsSelecting(false);
        this.componentRenderer = undefined;
    }
    public completeSelection() {
        this.endSelection();
        return this.crop;
    }

    /** Only to be used by the crop component, use `setComponentCrop` to actually modify the crop */
    public setCrop(crop: PercentCrop) {
        this.crop = crop;
    }

    /** Only to be used by the crop component */
    public register(setIsSelecting: SetIsSelectingFn, setComponentCrop: SetComponentCropFn) {
        this.setIsSelecting = setIsSelecting;
        this.setComponentCrop = setComponentCrop;
    }
}

export const GlobalVideoImageSelector = new VideoImageSelector();
