import { useSelector } from "react-redux";
import { useView } from "../../../hooks/useView";
import { ApplicationState } from "../../../store";
import { DataViz } from "../../viz/DataViz";
import VizMediaList from "../../viz/components/VizMediaList";
import "./Grid.scss";
import { useManageViews } from "../../../hooks/useManageViews";
import ErrorPage from "../../../components/ErrorPage";

export interface GridProps {
    viewId?: string;
}

export function Grid(props: GridProps) {
    const { analytics } = useSelector((state: ApplicationState) => state);
    const { projectViews, isInvalidMediaId, setMediaId, mediaIdParam } = useManageViews();
    const { viewId } = useView(props.viewId ?? analytics?.primaryViewId);

    if (isInvalidMediaId) {
        return (
            <ErrorPage
                subTitle="Invalid media source ID"
                errorMessageElement={
                    <p>
                        A valid media source with the ID{" "}
                        <code className="py-1 px-2 mx-1 bg-body-secondary">{mediaIdParam}</code> could not be found
                    </p>
                }
            />
        );
    }

    return (
        <DataViz>
            <div className="grid-header">
                <VizMediaList
                    mediaId={mediaIdParam}
                    setMediaId={setMediaId}
                    selectedViewId={viewId}
                    projectViews={projectViews}
                />
            </div>
        </DataViz>
    );
}
