import { useEffect, useRef } from "react";

function useAsyncCallback(
    callback: () => Promise<unknown>,
    dependencies: React.DependencyList,
    options?: { skip?: boolean },
) {
    const abortController = useRef(new AbortController());

    useEffect(() => {
        if (options?.skip) {
            return;
        }

        callback();
        abortController.current.signal.addEventListener("abort", callback);

        return () => {
            abortController.current.abort();
            abortController.current.signal.removeEventListener("abort", callback);
        };
    }, dependencies);
}

export default useAsyncCallback;
