import UserConfigSwitch from "../../../../settings/components/UserConfigSwitch";
import { User as UserData } from "../../../../../models/User";
import { Organisation } from "../../../../../models/Organisation";
import { useAdminSetUserRoleMutation } from "../../../../../store/api/kinesense";
import { useEffect, useState } from "react";
import { Notifications } from "../../../../../utilities/Notifications/Notifications";
import useDebouncedCallback from "../../../../../hooks/useDebouncedCallback";
import { tryExtractErrorMessage } from "../../../../../utilities/helpers";

export interface ToggleIsManagerProps {
    userData: UserData;
    organisation: Organisation;
}

function ToggleIsManager(props: ToggleIsManagerProps) {
    const isManager = props.userData.roles.includes("manager");
    const [localIsManager, setLocalIsManager] = useState(isManager);
    const [isUpdating, setIsUpdating] = useState(false);

    const [setUserRole] = useAdminSetUserRoleMutation();

    function handleOnChange() {
        setIsUpdating(true);

        const next = !localIsManager;
        setLocalIsManager(next);

        setUserRole({
            username: props.userData.username,
            organisationId: props.organisation.organisationId,
            body: { role: next ? "manager" : "basic" },
        })
            .unwrap()
            .catch((e) => {
                Notifications.notify(
                    "Error updating user role",
                    `The following error was encountered while attempting to update a user's role: ${tryExtractErrorMessage(
                        e,
                    )}`,
                    "warning",
                );
            })
            .finally(() => setIsUpdating(false));
    }

    const debouncedHandleOnChange = useDebouncedCallback(handleOnChange, 250, {
        isImmediate: true,
        maxWait: 1000,
    });

    // Ensure local value is synced to user data
    useEffect(() => {
        if (localIsManager == isManager) {
            return;
        }
        setLocalIsManager(isManager);
    }, [isManager, localIsManager]);

    return (
        <UserConfigSwitch
            label="Is manager"
            title="Toggle whether user is an organisation manager"
            isChecked={localIsManager}
            onChange={debouncedHandleOnChange}
            isUpdating={isUpdating}
        />
    );
}

export default ToggleIsManager;
