import * as React from "react";
import "./MediaItem.scss";
import { MediaSource } from "../../../models/media/MediaSource";

type MediaItemProps = { item: MediaSource };

export default class MediaItem extends React.Component<MediaItemProps> {
    constructor(props: MediaItemProps) {
        super(props);
    }

    public render() {
        const { days, hours, minutes, seconds, milliseconds } = msToTime(this.props.item.duration);
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-1 col-sm-4 mediaItem">
                        <div className="preview">preview</div>
                    </div>
                    <div className="col-2 col-sm-6 mediaItem">
                        <div>
                            <strong>{this.props.item.name}</strong>
                        </div>
                        <div>
                            <div className="label">Start Time </div>
                            {new Date(this.props.item.startsAt).toLocaleDateString() +
                                " " +
                                new Date(this.props.item.startsAt).toTimeString().slice(0, 5)}
                        </div>
                        <div>
                            <div className="label">Duration</div> {days + ":" + hours + ":" + minutes + ":" + seconds}
                            <div className="milliseconds">{"." + milliseconds}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function msToTime(duration: number) {
    const days: number = Math.floor(duration / 86400000);
    let milliseconds: string | number = Math.floor((duration % 1000) / 100);
    let seconds: string | number = Math.floor((duration / 1000) % 60);
    let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
    let hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    milliseconds = milliseconds < 10 ? "0" + milliseconds : milliseconds;

    return { days, hours, minutes, seconds, milliseconds };
}
