import { nanoid } from "@reduxjs/toolkit";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { PropertyDescription } from "../dataDescriptions/PropertyDescription";
import { EntityProperties } from "../dataDescriptions/EntityProperties";
import { Entity, EntityImageCollection, FileVideoFrameEntityImage } from "../Entity";
import { EntityAdapter } from "./EntityAdapter";
import { TagObject } from "../../tags/TagObject";
import { EntityProperty } from "../entityProperties/EntityProperty";
import { StringEntityProperty } from "../entityProperties/StringEntityProperty";
import { StringListEntityProperty } from "../entityProperties/StringListEntityProperty";
import { NumberEntityProperty } from "../entityProperties/NumberEntityProperty";
import { DateEntityProperty } from "../entityProperties/DateEntityProperty";

export class TagObjectEntityAdapter implements EntityAdapter<TagObject> {
    dataDescription: DataDescription;

    constructor(dataDescription: DataDescription) {
        this.dataDescription = dataDescription;
    }

    setDataDescription(dataDescription: DataDescription): void {
        this.dataDescription = dataDescription;
    }

    generateFreeFormSearchString(data: TagObject): string {
        return `${data.name} ${data.comments ?? ""}`;
    }

    convertToEntity(data: TagObject): Entity {
        const props = this.dataDescription.properties;
        const ep = EntityProperties;

        const properties: [PropertyDescription, EntityProperty<unknown>][] = [
            [props[ep.Name.key], new StringEntityProperty(data.name)],
            [props[ep.ObjectCategory.key], new StringEntityProperty(data.type)],
            [props[ep.Comments.key], new StringEntityProperty(data.comments)],
            [props[ep.Meta.CreatedAt.key], new DateEntityProperty(new Date(data.createdAt))],
            [props[ep.Meta.UpdatedAt.key], new DateEntityProperty(new Date(data.updatedAt))],
            [props[ep.Meta.FreeFormSearch.key], new StringEntityProperty(this.generateFreeFormSearchString(data))],
        ];

        switch (data.type) {
            case "person":
                properties.push([props[ep.Aliases.key], new StringListEntityProperty(data.aliases)]);
                break;
            case "group":
                properties.push([props[ep.Group.ApproxSize.key], new NumberEntityProperty(data.approxSize)]);
                break;
            case "vehicle":
                properties.push(
                    [props[ep.Vehicle.RegistrationPlate.key], new StringEntityProperty(data.registrationPlate)],
                    [props[ep.Vehicle.Make.key], new StringEntityProperty(data.make)],
                    [props[ep.Vehicle.Model.key], new StringEntityProperty(data.model)],
                    [props[ep.Vehicle.Colour.key], new StringEntityProperty(data.colour)],
                    [props[ep.Vehicle.Type.key], new StringEntityProperty(data.vehicleType)],
                );
                break;
            case "location":
                properties.push(
                    [props[ep.Location.Address.key], new StringEntityProperty(data.address)],
                    [props[ep.Location.PostCode.key], new StringEntityProperty(data.postCode)],
                    [props[ep.Location.Latitude.key], new NumberEntityProperty(data.latitude)],
                    [props[ep.Location.Longitude.key], new NumberEntityProperty(data.longitude)],
                );
                break;
            case "animal":
                properties.push(
                    [props[ep.Animal.Species.key], new StringEntityProperty(data.species)],
                    [props[ep.Animal.Breed.key], new StringEntityProperty(data.breed)],
                    [props[ep.Animal.Colour.key], new StringEntityProperty(data.colour)],
                );
                break;
        }

        let images = [];
        if (Array.isArray(data.images)) {
            images = data.images
                .map((i) => {
                    switch (i.type) {
                        case "file":
                            return new FileVideoFrameEntityImage({
                                fileId: i.fileId,
                                timestamp: 0,
                                bounds: [0, 0, 1, 1],
                            });
                        case "frame":
                            return new FileVideoFrameEntityImage({
                                fileId: i.fileId,
                                bounds: i.bounds,
                                timestamp: i.timestamp,
                            });
                    }
                })
                .filter((i) => i !== undefined);
        }

        return new Entity({
            id: nanoid(16),
            sourceObject: {
                id: data.objectId,
                mediaSource: undefined,
                runId: undefined,
                mediaSourceFrameOffset: undefined,
                projectId: data.projectId,
                object: data,
            },
            dataDescription: this.dataDescription,
            images: new EntityImageCollection(images),
            properties: Object.fromEntries(properties.map(([prop, value]) => [prop.key, value])),
        });
    }
}
