import { AreaBounds } from "cloud-core/spatial/Spatial";
import { inflateSync } from "fflate";

export class FilterBitmapUtilities {
    public static decodeBitmapString(base64Str: string): Uint32Array {
        const compressedArray = atob(base64Str);
        const bytes = [];

        for (let i = 0; i < compressedArray.length; i++) {
            bytes.push(compressedArray.charCodeAt(i));
        }

        const byteArray = new Uint8Array(bytes);
        const decompressed = inflateSync(byteArray);

        return new Uint32Array(decompressed.buffer);
    }

    public static decodeLowResBitmapString(base64Str: string): Uint32Array {
        const array = atob(base64Str);
        const bitmap = new ArrayBuffer(8);
        const bitmapBytes = new Uint8Array(bitmap);

        for (let i = 0; i < array.length; i++) {
            bitmapBytes[i] = array.charCodeAt(i);
        }

        return new Uint32Array(bitmap);
    }

    public static areasAreOverlapping(first: Uint32Array, second: Uint32Array): boolean {
        const andArray = new Uint32Array(first.length);

        for (let i = 0; i < first.length; i++) {
            andArray[i] = first[i] & second[i];
        }

        return andArray.some((v) => v != 0);
    }

    public static convertBoundsToBitmap(bounds: AreaBounds, bitmapSide: number): Uint32Array {
        const bitmap = new ArrayBuffer(Math.ceil((bitmapSide * bitmapSide) / 8.0));
        const view = new Uint8Array(bitmap);

        const left = Math.floor(bounds[0] * bitmapSide);
        const top = Math.floor(bounds[1] * bitmapSide);
        const right = Math.ceil(bounds[2] * bitmapSide);
        const bottom = Math.ceil(bounds[3] * bitmapSide);

        for (let row = top; row <= bottom; row++) {
            for (let col = left; col <= right; col++) {
                const bitIndex = col + row * bitmapSide;
                const byteIndex = Math.floor(bitIndex / 8);

                view[byteIndex] |= 1 << bitIndex % 8;
            }
        }

        return new Uint32Array(view.buffer);
    }
}
