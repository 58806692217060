import { capitaliseFirst } from "../../../utilities/helpers";
import { EntityProperty } from "./EntityProperty";

export class StringListEntityProperty extends EntityProperty<string[]> {
    staticValue: string[];
    capitaliseFirstLetter: boolean = true;

    constructor(value: string[], capitalise = true, confidence?: number[]) {
        super(confidence);
        this.valueType = "string";
        this.isList = true;
        this.staticValue = value;
        this.capitaliseFirstLetter = capitalise;
    }

    display(): string {
        const strings = this.capitaliseFirstLetter ? this.staticValue.map((v) => capitaliseFirst(v)) : this.staticValue;
        return strings.join(", ");
    }

    value(): string[] {
        return this.staticValue;
    }

    withCapitaliseFirstLetter(capitaliseFirstLetter: boolean): StringListEntityProperty {
        this.capitaliseFirstLetter = capitaliseFirstLetter;
        return this;
    }
}
