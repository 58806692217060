import { faArrowDownShortWide, faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { capitaliseFirst } from "../utilities/helpers";

function useControlledList<SortBy>(defaultSortBy: SortBy, isSortOrderAscendingDefault: boolean) {
    const [sortBy, setSortBy] = useState<SortBy>(defaultSortBy);
    const [isSortOrderAscending, setIsSortOrderAscending] = useState(isSortOrderAscendingDefault);
    const [filter, setFilter] = useState<string>("");

    function handleOnChangeSortBy(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        setSortBy(e.currentTarget.value as unknown as SortBy);
    }

    function toggleSortByOrder() {
        setIsSortOrderAscending(!isSortOrderAscending);
    }

    function handleOnChangeFilter(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFilter(e.currentTarget.value);
    }

    return {
        sortBy,
        setSortBy,
        isSortOrderAscending,
        setIsSortOrderAscending,
        filter,
        setFilter,
        handleOnChangeSortBy,
        handleOnChangeFilter,
        toggleSortByOrder,
    };
}

export interface ControlledListInputsProps {
    sortBy: string;
    sortByKeys: string[];
    isSortOrderAscending: boolean;
    filter: string;
    filterInputPlaceholder: string;
    handleOnChangeSortBy: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    handleOnChangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
    toggleSortByOrder: () => void;
}

export function ControlledListInputs(props: ControlledListInputsProps) {
    return (
        <>
            <ControlledListFilterInput
                placeholder={props.filterInputPlaceholder}
                filter={props.filter}
                handleOnChangeFilter={props.handleOnChangeFilter}
            />

            <Form.Select value={props.sortBy} onChange={props.handleOnChangeSortBy}>
                <option disabled hidden value="">
                    Sort by
                </option>

                {props.sortByKeys.map((key) => (
                    <option value={key} key={`organisations-sort-by-option-${key}`}>
                        {capitaliseFirst(key)}
                    </option>
                ))}
            </Form.Select>

            <Button
                className="d-flex gap-2 align-items-center"
                onClick={props.toggleSortByOrder}
                role="checkbox"
                aria-label="toggles sort order between ascending and descending"
            >
                {props.isSortOrderAscending ? (
                    <FontAwesomeIcon icon={faArrowDownShortWide} />
                ) : (
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                )}
            </Button>
        </>
    );
}

// Specific input components to be used separately
export function ControlledListFilterInput(props: {
    placeholder: string;
    filter: string;
    handleOnChangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Form.Control
            type="text"
            placeholder={props.placeholder}
            value={props.filter}
            onChange={props.handleOnChangeFilter}
        />
    );
}

export default useControlledList;
