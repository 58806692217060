import { useRef } from "react";
import Dashboard, { DashboardCards } from "../../../../components/dashboard/Dashboard";
import OrganisationsCard from "./OrganisationsCard";
import ProcessorsCard from "./ProcessorsCard";

function AdminDashboard() {
    const cards = useRef<DashboardCards>({
        organisations: <OrganisationsCard />,
        processors: <ProcessorsCard />,
    });

    return <Dashboard dashboardId="admin" cards={cards} />;
}

export default AdminDashboard;
