import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClone } from "@fortawesome/free-solid-svg-icons";
import "./CopySnippetToClipboard.scss";

export interface CopySnippetToClipboardProps {
    snippet: string;
}

export const COPY_SNIPPET_RESET_MILLISECONDS = 5000 as const;

export default function CopySnippetToClipboard(props: CopySnippetToClipboardProps) {
    const [isCopied, setIsCopied] = useState(false);

    function copySnippet(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setIsCopied(true);

        navigator.clipboard.writeText(props.snippet);

        setTimeout(() => {
            setIsCopied(false);
        }, COPY_SNIPPET_RESET_MILLISECONDS);
    }

    return (
        <code className="copy-to-clipboard bg-body-tertiary fs-5">
            {props.snippet}

            <button className="copy-to-clipboard-button" onClick={copySnippet} disabled={isCopied}>
                {isCopied ? (
                    <>
                        <FontAwesomeIcon icon={faCheck} /> Copied!
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon icon={faClone} /> Copy
                    </>
                )}
            </button>
        </code>
    );
}
