import { MediaSource as MediaSourceBase, MediaSourceInitialDisplayFile } from "cloud-core/media/MediaSource";
import { ImportMetadataResults } from "../../views/import/components/importWizard/ImportMetadata";
import { Milliseconds } from "../../utilities/numerics";

export const MEDIASOURCE_VIDEO_DEFAULT_FRAMERATE = 25;

export function calculateFramePeriod(video: MediaSourceInitialDisplayFile | undefined): Milliseconds {
    return 1000 / (video?.frameRate ?? MEDIASOURCE_VIDEO_DEFAULT_FRAMERATE);
}

export type MediaSource = MediaSourceBase<Milliseconds>;

export type IntegrationMediaSource = Omit<
    MediaSource,
    "mediaId" | "startsAt" | "organisationId" | "tags" | "metadata"
> & {
    integrationId: string;
    integrationMediaId: string;
    preview: {
        type: "url" | "file";
        source: string;
    };
    video: {
        fileSize: number;
        mimeType: string;
        format: string;
        fileId: string;
        width: number;
        height: number;
        frameRate: number;
    };
};

export interface AddMediaSourceRequestBody
    extends Omit<
        MediaSource,
        | "mediaId"
        | "createdAt"
        | "updatedAt"
        | "organisationId"
        | "files"
        | "attachmentCount"
        | "tags"
        | "originalSource"
        | "createdBy"
        | "thumbnails"
    > {
    metadata: ImportMetadataResults;
}
