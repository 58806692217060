import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { useAdminGetOrganisationsQuery } from "../../../../store/api/kinesense";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { Organisation } from "../../../../models/Organisation";
import { motion } from "framer-motion";
import { useMemo } from "react";

export const OrganisationsSortBy = {
    name: "name",
    country: "country",
} as const;
export type OrganisationsSortBy = (typeof OrganisationsSortBy)[keyof typeof OrganisationsSortBy];

function getSortOrganisationsFunction(sortBy: OrganisationsSortBy, isAscending: boolean) {
    return (a: Organisation, b: Organisation) => {
        return a[sortBy].localeCompare(b[sortBy]) * (isAscending ? 1 : -1);
    };
}

export interface OrganisationsListProps {
    maxItems?: number;
    control?: {
        sortBy: OrganisationsSortBy;
        isSortOrderAscending: boolean;
        filter: string;
    };
}

function OrganisationsList(props: OrganisationsListProps) {
    const { data: organisations, isFetching, isSuccess } = useAdminGetOrganisationsQuery({});

    const isControlled = useMemo(() => props.control !== undefined, [props.control]);

    let displayedOrganisations: Organisation[] = [];

    if (isSuccess) {
        const limit = props.maxItems ?? organisations.length;

        displayedOrganisations = organisations.slice(0, limit);
    }

    if (isControlled) {
        // FILTER
        const filterRegex = new RegExp(props.control.filter, "i");

        displayedOrganisations = displayedOrganisations.filter(
            (org) => filterRegex.test(org.name) || filterRegex.test(org.country),
        );

        // SORT
        const isAscending = props.control.isSortOrderAscending ?? true;

        switch (props.control.sortBy) {
            case "name":
                displayedOrganisations.sort(getSortOrganisationsFunction("name", isAscending));
                break;
            case "country":
                displayedOrganisations.sort(getSortOrganisationsFunction("country", isAscending));
        }
    }

    return (
        <ListGroup>
            <ConditionalSpinner isLoading={isFetching}>
                {displayedOrganisations?.map((organisation) => (
                    <motion.div
                        layout
                        animate={{ opacity: 1, x: 0 }}
                        initial={{ opacity: 0, x: 50 }}
                        transition={{ ease: "easeOut" }}
                        key={organisation.organisationId}
                        className="p-0 list-group-item"
                    >
                        <ListGroup.Item
                            action
                            as={Link}
                            to={`/admin/organisations/${organisation.organisationId}`}
                            className="border-0 outline-0"
                        >
                            {organisation.name}
                            <span className="fst-italic fw-light">, {organisation.country}</span>
                        </ListGroup.Item>
                    </motion.div>
                ))}
            </ConditionalSpinner>
        </ListGroup>
    );
}

export default OrganisationsList;
