import { Form } from "react-bootstrap";
import RefreshButton from "../../../../components/RefreshButton";
import useControlledList, { ControlledListFilterInput } from "../../../../hooks/useControlledList";
import { useAdminGetAllProcessorsQuery } from "../../../../store/api/kinesense";
import ProcessorsList, { ProcessorsSortBy } from "./ProcessorsList";

export interface ControlledPropsListProps {
    maxItems?: number;
}

function ControlledProcessorsList(props: ControlledPropsListProps) {
    const control = useControlledList<ProcessorsSortBy>(ProcessorsSortBy.lastStartedAt, true);
    const { refetch, isFetching } = useAdminGetAllProcessorsQuery();

    return (
        <article className="controlled-organisations-list">
            <section className="d-flex align-items-center justify-content-between">
                <Form onSubmit={(e) => e.preventDefault()}>
                    <ControlledListFilterInput
                        placeholder="Search by processor ID or instance type"
                        filter={control.filter}
                        handleOnChangeFilter={control.handleOnChangeFilter}
                    />
                </Form>

                <RefreshButton callback={refetch} isDisabled={isFetching} />
            </section>

            <ProcessorsList maxItems={props.maxItems} control={control} />
        </article>
    );
}

export default ControlledProcessorsList;
