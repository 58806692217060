import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { DataOperation, VizContext } from "./DataOperation";
import NiceModal from "@ebay/nice-modal-react";
import ExportEntitiesToCsvModal from "../../../components/dataOperationModals/exportEntitiesToCsvModal/ExportEntitiesToCsvModal";

export class ExportEntitiesToCsv extends DataOperation {
    filenamePrefix?: string;

    constructor(filenamePrefix?: string) {
        super();
        this.operationId = "exportEntitiesToCsv";
        this.displayName = "Export to CSV";
        this.icon = {
            type: "fontawesome",
            icon: faFileCsv,
        };
        this.priority = "primary";
        this.context = "entity";
        this.category = "export";
        this.filenamePrefix = filenamePrefix;
        NiceModal.register(this.operationId, ExportEntitiesToCsvModal);
    }

    canExecute(context: VizContext) {
        return (context.view?.filteredEntities ?? []).length > 0 || context.view?.selectedEntity !== undefined;
    }

    execute(context: VizContext) {
        NiceModal.show(this.operationId, { context, filenamePrefix: this.filenamePrefix });
    }
}
