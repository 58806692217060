export const ROLES = ["admin", "manager", "basic"] as const;
export type Role = (typeof ROLES)[number];

export interface User {
    username: string;
    email: string;
    joinedAt: number;
    roles: Role[];
}

export interface FetchedUser extends Omit<User, "roles"> {
    roles: Role[];
}

export function convertFetchedUsers(fetchedUsers: FetchedUser[]) {
    const convertedUsersData: User[] = [];

    for (const fetchedUser of fetchedUsers) {
        const { roles: fetchedRoles, ...props } = fetchedUser;
        const convertedRoles: Role[] = [];

        for (const role of fetchedRoles) {
            if (ROLES.includes(role)) {
                convertedRoles.push(role);
            }
        }
        // Sort roles by importance
        convertedRoles.sort((a: Role, b: Role) => {
            return a === "admin" || (a === "manager" && b !== "admin") ? -1 : 1;
        });

        convertedUsersData.push({
            ...props,
            roles: convertedRoles,
        });
    }

    return convertedUsersData;
}
