import dayjs from "dayjs";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateFormats } from "../../../../utilities/dates";
import { MediaSource } from "../../../../models/media/MediaSource";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { buildMediaSourceQueryString } from "../../../../hooks/useManageViews";
import { motion } from "framer-motion";

export interface VideoListItemProps {
    data: MediaSource;
}

function VideoListItem(props: VideoListItemProps) {
    const projectId = useSelector((state: ApplicationState) => state.general?.activeProjectId);

    return (
        <motion.div layout>
            <ListGroup.Item
                action
                as={Link}
                to={`/review?${buildMediaSourceQueryString(projectId, props.data.mediaId)}`}
            >
                <img height="24" src="/images/icons/report-video_56.png" alt="video" />
                <span className="text-truncate flex-fill" title={props.data.name}>
                    {props.data.name}
                </span>
                <span className="text-secondary">
                    {dayjs(props.data.startsAt).format(DateFormats.yearMonthDayWithTime)}
                </span>
            </ListGroup.Item>
        </motion.div>
    );
}

export default VideoListItem;
