import { ListGroup } from "react-bootstrap";
import useToggleUserConfigOption from "./useToggleUserConfigOption";
import UserConfigSwitch from "./UserConfigSwitch";
import useUserConfig from "../../../hooks/useUserConfig";

function AdvancedTab() {
    const { userConfig, updateUserConfig } = useUserConfig();

    // DEBUG UI
    const [localShowDebugUi, isUpdatingShowDebugUi, toggleShowDebugUi] = useToggleUserConfigOption(
        userConfig.showDebugUi,
        (showDebugUi: boolean) => updateUserConfig({ showDebugUi }).unwrap(),
        "Error toggling debug mode",
    );

    // MOCKDEM
    const [localShowMockDem, isUpdatingShowMockDem, toggleShowMockDem] = useToggleUserConfigOption(
        userConfig.showMockDem,
        (showMockDem: boolean) => updateUserConfig({ showMockDem }).unwrap(),
        "Error toggling MockDem visibility",
    );

    return (
        <ListGroup className="advanced-tab">
            <UserConfigSwitch
                label="Debug mode"
                title="Show/hide certain debug UI elements - useful for developers"
                isChecked={localShowDebugUi}
                isUpdating={isUpdatingShowDebugUi}
                onChange={toggleShowDebugUi}
            />

            <UserConfigSwitch
                label="Show MockDEM"
                title="Enable/Disable the MockDEM page"
                isChecked={localShowMockDem}
                isUpdating={isUpdatingShowMockDem}
                onChange={toggleShowMockDem}
            />
        </ListGroup>
    );
}

export default AdvancedTab;
