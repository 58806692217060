import { faStepBackward, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "@reduxjs/toolkit";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { useView } from "../../../hooks/useView";
import * as AnalyticsStore from "../../../store/analytics/Analytics";
import { calculateFramePeriod } from "../../../models/media/MediaSource";
import { Entity } from "../../../models/viz/Entity";
import { DateEntityProperty } from "../../../models/viz/entityProperties/DateEntityProperty";
import useMediaSources from "../../../hooks/useMediaSources";

// Used for skipping fragment groups with start times too close to the cursor position
const EVENT_BUFFER_MILLISECONDS = 200;

export function useVideoControls(
    viewId: string,
    mediaId: string,
    videoRef: MutableRefObject<HTMLVideoElement>,
    sourceId: MutableRefObject<string>,
    entities: Entity[],
    entitiesInView: Entity[],
) {
    const dispatch = useDispatch();
    const { view } = useView(viewId);
    const { activeMediaSource: media } = useMediaSources(viewId, mediaId);

    const millisecondsBetweenFrames = calculateFramePeriod(media?.files?.initialDisplay);

    const areButtonControlsDisabled = !videoRef?.current || videoRef.current.src === "";
    const [isPlaying, isMuted] = useMemo(() => {
        if (!view) {
            return [false, false];
        }

        return [view.mediaStates.play == "playing", view.mediaStates.audio == "muted"];
    }, [view?.mediaStates]);

    const isPlayingEventsOnly = useRef(false);

    function mute() {
        dispatch(AnalyticsStore.actionCreators.setViewAudioState(viewId, "muted", sourceId.current));
    }

    function unmute() {
        dispatch(AnalyticsStore.actionCreators.setViewAudioState(viewId, "unmuted", sourceId.current));
    }

    function toggleMute() {
        isMuted ? unmute() : mute();
    }

    function pause() {
        dispatch(AnalyticsStore.actionCreators.setViewPlayState(viewId, "paused", sourceId.current));
        isPlayingEventsOnly.current = false;
    }

    function play() {
        dispatch(AnalyticsStore.actionCreators.setViewPlayState(viewId, "playing", sourceId.current));
        isPlayingEventsOnly.current = false;
    }

    function togglePlay() {
        isPlaying ? pause() : play();
    }

    function updateCursor(updatedCursorMilliseconds: number) {
        if (view?.cursor === undefined || view?.cursor == updatedCursorMilliseconds) {
            return;
        }

        // Update video element's time too to avoid more updates from the VideoPlayer component
        if (videoRef.current) {
            videoRef.current.currentTime = (updatedCursorMilliseconds - media.startsAt) / 1000;
        }

        dispatch(
            AnalyticsStore.actionCreators.setCursorPosition(
                viewId,
                "primary",
                updatedCursorMilliseconds,
                sourceId.current,
            ),
        );
    }

    function nextFrame() {
        const endTime = media.startsAt + media.duration;
        updateCursor(Math.min(view?.cursor + millisecondsBetweenFrames, endTime));
    }

    function prevFrame() {
        updateCursor(Math.max(view?.cursor - millisecondsBetweenFrames, media.startsAt));
    }

    function prevEvent() {
        for (const entity of entities.slice().reverse()) {
            const startsAt = (entity.properties["StartTime"] as DateEntityProperty).value().getTime();
            if (startsAt >= view.cursor - EVENT_BUFFER_MILLISECONDS) {
                continue;
            }
            return updateCursor(startsAt);
        }
    }

    function nextEvent(bufferMilliseconds: number = EVENT_BUFFER_MILLISECONDS) {
        for (const entity of entities) {
            const startsAt = (entity.properties["StartTime"] as DateEntityProperty).value().getTime();
            if (startsAt <= view.cursor + bufferMilliseconds) {
                continue;
            }
            return updateCursor(startsAt);
        }
    }

    function playEvents() {
        play();
        isPlayingEventsOnly.current = true;
    }

    function togglePlayEvents() {
        isPlaying ? pause() : playEvents();
    }

    useEffect(() => {
        if (isPlayingEventsOnly.current && entitiesInView?.length == 0) {
            nextEvent(0);
        }
    }, [entitiesInView?.length, isPlayingEventsOnly.current]);

    return {
        media,
        isPlaying,
        togglePlayEvents,
        isMuted,
        areButtonControlsDisabled,
        play,
        pause,
        mute,
        unmute,
        togglePlay,
        toggleMute,
        updateCursor,
        prevFrame,
        nextFrame,
        prevEvent,
        nextEvent,
    };
}

export interface VideoControlsProps {
    videoRef: MutableRefObject<HTMLVideoElement>;
    viewId: string;
    mediaId: string;
    entities: Entity[];
    entitiesInView: Entity[];
}

function VideoControls(props: VideoControlsProps) {
    const controlsId = useRef("cn-" + nanoid(8));

    const {
        isPlaying,
        isMuted,
        areButtonControlsDisabled,
        togglePlay,
        toggleMute,
        prevFrame,
        nextFrame,
        prevEvent,
        nextEvent,
        togglePlayEvents,
    } = useVideoControls(props.viewId, props.mediaId, props.videoRef, controlsId, props.entities, props.entitiesInView);

    const baseButtonProps = { disabled: areButtonControlsDisabled, variant: "secondary" };

    return (
        <div className="gap-5 video-controls d-flex justify-content-center align-items-center">
            <section className="gap-3 d-flex justify-content-center align-items-center">
                <Button title="Previous event" aria-label="previous event" onClick={prevEvent} {...baseButtonProps}>
                    <img src="/images/icons/jump-to-previous-event_56.png" />
                </Button>
                <Button
                    title={isPlaying ? "Pause" : "Play only events"}
                    aria-label="toggle play only events"
                    onClick={togglePlayEvents}
                    {...baseButtonProps}
                >
                    <img src={isPlaying ? "/images/icons/pause_56.png" : "/images/icons/play-events_56.png"} />
                </Button>
                <Button title="Next event" aria-label="next event" onClick={() => nextEvent()} {...baseButtonProps}>
                    <img src="/images/icons/jump-to-next-event_56.png" />
                </Button>
            </section>

            <section className="gap-3 d-flex justify-content-center align-items-stretch">
                <div>
                    <Button title="Previous frame" aria-label="previous frame" onClick={prevFrame} {...baseButtonProps}>
                        <FontAwesomeIcon icon={faStepBackward} className="fa-fw" />
                    </Button>
                </div>
                <Button
                    title={isPlaying ? "Pause" : "Play"}
                    aria-label="toggle play"
                    onClick={togglePlay}
                    {...baseButtonProps}
                >
                    <img src={isPlaying ? "/images/icons/pause_56.png" : "/images/icons/play_56.png"} />
                </Button>
                <div>
                    <Button title="Next frame" aria-label="next frame" onClick={nextFrame} {...baseButtonProps}>
                        <FontAwesomeIcon icon={faStepForward} className="fa-fw" />
                    </Button>
                </div>
            </section>

            <Button title="Toggle audio" aria-label="toggle audio" onClick={toggleMute} {...baseButtonProps}>
                <img src={isMuted ? "/images/icons/disabled-audio_56.png" : "/images/icons/enabled-audio_56.png"} />
            </Button>
        </div>
    );
}

export default VideoControls;
