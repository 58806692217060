import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import { useTheme } from "./hooks/useTheme";
import useUserConfig from "./hooks/useUserConfig";
import { Theme } from "./models/UserConfig";
import { ApplicationState } from "./store";
import * as UserStore from "./store/user/User";
import * as GeneralStore from "./store/general/General";
import { useAutoRefreshAuthToken } from "./utilities/Auth";
import { NotificationArea } from "./utilities/Notifications/NotificationArea";
import PageNotFound from "./views/404";
import AppLayout from "./views/AppLayout";
import Admin from "./views/admin/Admin";
import Login from "./views/auth/Login";
import MfaSetup from "./views/auth/MfaSetup";
import NewPasswordRequired from "./views/auth/NewPasswordRequired";
import PasswordReset from "./views/auth/PasswordReset";
import TotpInput from "./views/auth/TotpInput";
import Home from "./views/home/Home";
import MockDem from "./views/import/components/MockDem";
import ImportWizard from "./views/import/components/importWizard/ImportWizard";
import Organisation from "./views/organisation/Organisation";
import Review from "./views/review/Review";
import Settings from "./views/settings/Settings";
import { Tags } from "./views/tags/components/Tags";
import { Grid } from "./views/grid/components/Grid";

export type OverrideLogoUrls = {
    [Theme.LIGHT]: string;
    [Theme.DARK]: string;
};
export interface LoggedOutViewProps {
    overrideLogoPaths?: OverrideLogoUrls;
}

export function LoggedOutView(props: LoggedOutViewProps) {
    const user = useSelector<ApplicationState, UserStore.UserState>((state) => state.user);
    const { isDarkTheme } = useTheme();
    const logoPaths =
        props.overrideLogoPaths ??
        ({ [Theme.LIGHT]: "kine_logo.svg", [Theme.DARK]: "kine_logo_dark.svg" } as LoggedOutViewProps);

    const onSubmitCallbackRef = useRef<() => void>(() => null);

    function renderLoginErrorMessage() {
        switch (user?.loginIssue) {
            case "newPassword":
                return <NewPasswordRequired onSubmitCallbackRef={onSubmitCallbackRef} />;
            case "mfaSetup":
                return <MfaSetup onSubmitCallbackRef={onSubmitCallbackRef} />;
            case "totpRequired":
                return <TotpInput onSubmitCallbackRef={onSubmitCallbackRef} />;
            case "passwordReset":
                return <PasswordReset />;
            case "credentials":
            default:
                return <Login onSubmitCallbackRef={onSubmitCallbackRef} />;
        }
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="mb-4">
                <img width="250" src={isDarkTheme ? logoPaths[Theme.DARK] : logoPaths[Theme.LIGHT]} />
            </div>
            <form
                id="login-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitCallbackRef.current();
                }}
            >
                {user?.loginIssue === undefined ? (
                    <Spinner role="status" aria-label="loading" />
                ) : (
                    renderLoginErrorMessage()
                )}
            </form>

            <NotificationArea />
        </div>
    );
}

/** Convenience function for wrapping routes in motion elements for page transitions */
function wrapRouteElement(element: ReactNode) {
    return (
        <motion.div transition={{ duration: 0.15 }} exit={{ opacity: 0 }}>
            {element}
        </motion.div>
    );
}

function LoggedInView(props: { isAdmin: boolean; showMockDem: boolean }) {
    const location = useLocation();

    return (
        <AppLayout>
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={wrapRouteElement(<Home />)} />
                    <Route path="/review" element={wrapRouteElement(<Review />)} />
                    <Route path="/tags" element={wrapRouteElement(<Tags />)} />
                    <Route path="/grid" element={wrapRouteElement(<Grid />)} />
                    <Route path="/import-wizard" element={wrapRouteElement(<ImportWizard />)} />
                    <Route path="/settings" element={wrapRouteElement(<Settings />)} />
                    <Route path="/organisation" element={wrapRouteElement(<Organisation />)} />
                    {props.showMockDem && <Route path="/mockdem" element={wrapRouteElement(<MockDem />)} />}
                    {props.isAdmin && <Route path="/admin/*" element={wrapRouteElement(<Admin />)} />}

                    <Route path="*" element={wrapRouteElement(<PageNotFound />)} />
                </Routes>
            </AnimatePresence>
        </AppLayout>
    );
}

function App() {
    const dispatch = useDispatch();
    const user = useSelector<ApplicationState, UserStore.UserState>((state) => state.user);
    const { userConfig } = useUserConfig();

    const { startAutoRefresh, stopAutoRefresh } = useAutoRefreshAuthToken();

    useEffect(() => {
        dispatch(UserStore.actionCreators.authoriseUser());
    }, [user?.isLoggedIn]);

    useEffect(() => {
        if (user.isLoggedIn) {
            startAutoRefresh();
            dispatch(GeneralStore.actionCreators.setActiveProject(`p${user.organisationId}`));
        } else {
            stopAutoRefresh();
        }
    }, [user.isLoggedIn]);

    return user?.isLoggedIn != null && user.isLoggedIn ? (
        <LoggedInView isAdmin={user.permissions.isAdmin} showMockDem={userConfig.showMockDem} />
    ) : (
        <div className="min-vh-100 d-flex align-items-center justify-content-center">
            <LoggedOutView />
        </div>
    );
}

export default App;
