import { Entity } from "../Entity";
import { FragmentGroupEntityAdapter } from "../entityAdapters/FragmentGroupEntityAdapter";
import { FunctionEntityInformationGenerator } from "../entityInformation/FunctionEntityInformationGenerator";
import { StaticStringEntityInformationGenerator } from "../entityInformation/StaticStringEntityInformationGenerator";
import { RefreshDataOperation } from "../operations/RefreshDataOperation";
import { StringSorter } from "../sorters/StringSorter";
import { DataDescription } from "./DataDescription";
import { ObjectClassificationDataDescription } from "./ObjectClassificationDataDescription";
import { StandardMovementDataDescription } from "./StandardMovementDataDescription";

export class GenericVideoEventDataDescription extends DataDescription {
    constructor() {
        super();

        this.infoGenerator = {
            titleGenerator: new FunctionEntityInformationGenerator((e: Entity) => e.id, "ID"),
            subtitleGenerator: new StaticStringEntityInformationGenerator("-", "-"),
        };

        this.entityAdapter = new FragmentGroupEntityAdapter(this);
        this.setProperties([]);
        this.filters = {};
        this.defaultSorter = new StringSorter("FragmentGroupId", "Id", "FragmentGroupId");
        this.sorters = [this.defaultSorter];
        this.operations = [new RefreshDataOperation()];
    }
}

export const DataDescriptions = {
    generic: new GenericVideoEventDataDescription(),
    standardMovement: new StandardMovementDataDescription(),
    objectClassification: new ObjectClassificationDataDescription(),
};
