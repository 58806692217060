import { ImportWizardPagePropsBase, NameAndDatePageData } from "../ImportWizard";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { DateFormats } from "../../../../../utilities/dates";

interface NameAndDatePageProps extends ImportWizardPagePropsBase {
    value: NameAndDatePageData | undefined;
    defaultFileName: string | undefined;
}

function NameAndDatePage(props: NameAndDatePageProps) {
    const defaultTime = new Date();
    defaultTime.setSeconds(0);

    const [name, setName] = useState<string>(props.value?.name ?? props.defaultFileName ?? "");
    const [datetime, setDatetime] = useState<Date>(props.value?.startTime ?? defaultTime);
    const [isValid, setIsValid] = useState<boolean>(false);

    const [dateString, setDateString] = useState<string>(dayjs(datetime).format(DateFormats.yearMonthDay));
    const [timeString, setTimeString] = useState<string>(dayjs(datetime).format(DateFormats.timeSeconds));

    const utc = { name: "+00:00", offset: 0 };
    const timezones = [
        { name: "-08:00", offset: -480 },
        { name: "-07:00", offset: -420 },
        { name: "-06:00", offset: -360 },
        { name: "-05:00", offset: -300 },
        utc,
        { name: "+01:00", offset: 60 },
        { name: "+02:00", offset: 120 },
        { name: "+03:00", offset: 180 },
        { name: "+05:30", offset: 330 },
    ];

    const [timezoneIndex, setTimezoneIndex] = useState<number>(timezones.indexOf(utc));
    const [timezone, setTimezone] = useState<{ name: string; offset: number }>(utc);

    const handleTimezoneChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const timezoneIndex = Number(event.target.value);
        const timezone = timezones[timezoneIndex];

        setTimezoneIndex(timezoneIndex);
        setTimezone(timezone);
    };

    const handleNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setName(name);
        props.dataChanged({ name, startTime: datetime, timezoneOffset: timezone.offset });
    };

    const handleDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dateInputValue = event.target.value;

        if (dateInputValue == undefined || dateInputValue.length == 0) {
            return;
        }

        setDateString(dateInputValue);

        const dateBits = dateInputValue.split("-").map(Number);
        if (dateBits.length != 3 || dateBits.some(isNaN) || dateBits[1] < 1) {
            return;
        }

        datetime.setFullYear(dateBits[0]);
        datetime.setMonth(dateBits[1] - 1);
        datetime.setDate(dateBits[2]);

        setDatetime(datetime);
        props.dataChanged({ name, startTime: datetime, timezoneOffset: timezone.offset });
    };

    const handleTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const timeInputValue = event.target.value;

        if (timeInputValue == undefined || timeInputValue.length == 0) {
            return;
        }

        const dateBits = timeInputValue.split(":").map(Number);

        setTimeString(timeInputValue);

        datetime.setHours(dateBits[0]);
        datetime.setMinutes(dateBits[1]);
        datetime.setSeconds(dateBits[2]);

        setDatetime(datetime);
        props.dataChanged({ name, startTime: datetime, timezoneOffset: timezone.offset });
    };

    const updateValidity = () => {
        setIsValid(name != undefined && name.length > 0 && datetime != undefined);
    };

    useEffect(() => {
        props.validityChanged(isValid);
    }, [isValid]);

    useEffect(() => {
        updateValidity();
        props.dataChanged({ name, startTime: datetime, timezoneOffset: timezone.offset });
    }, [name]);

    return (
        <Form>
            <Row>
                <Col>
                    <div>Video Name</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control value={name} onChange={handleNameChanged} />
                </Col>
            </Row>
            <Row>
                <Col>Start Time</Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control type="date" value={dateString} onChange={handleDateChanged} />
                </Col>
                <Col>
                    <Form.Control type="time" value={timeString} onChange={handleTimeChanged} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>Timezone</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Select value={timezoneIndex} onChange={handleTimezoneChanged}>
                        {timezones.map((timezone, i) => (
                            <option key={i} value={i}>
                                {timezone.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
        </Form>
    );
}

export default NameAndDatePage;
