import * as Analytics from "./analytics/Analytics";
import { exchangeRatesApiSlice } from "./api/exchangeRates";
import { kinesenseApiSlice } from "./api/kinesense";
import * as MediaItems from "./media/MediaItems";
import * as User from "./user/User";
import * as General from "./general/General";

// The top-level state object
export interface ApplicationState {
    analytics: Analytics.AnalyticsState | undefined;
    mediaItems: MediaItems.MediaItemsState | undefined;
    user: User.UserState | undefined;
    general: General.GeneralState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    analytics: Analytics.reducer,
    mediaItems: MediaItems.reducer,
    user: User.reducer,
    general: General.reducer,
    [kinesenseApiSlice.reducerPath]: kinesenseApiSlice.reducer,
    [exchangeRatesApiSlice.reducerPath]: exchangeRatesApiSlice.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
