import { useEffect, useRef } from "react";

function useIsMounted(): () => boolean {
    const isMountedRef = useRef(true);
    const isMounted = () => isMountedRef.current;

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return isMounted;
}

export default useIsMounted;
