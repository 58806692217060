import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ErrorPage from "../../components/ErrorPage";
import useUserConfig from "../../hooks/useUserConfig";
import { useView } from "../../hooks/useView";
import { calculateFramePeriod } from "../../models/media/MediaSource";
import { ApplicationState } from "../../store";
import { isInRange } from "../../utilities/helpers";
import NoMediaSourcesErrorPage from "../import/components/NoMediaSourcesErrorPage";
import VideoControls, { useVideoControls } from "../playback/components/VideoControls";
import VideoPlayer from "../playback/components/VideoPlayer";
import VideoPlayerHeader from "../playback/components/VideoPlayerHeader";
import useLoadFragments from "../playback/components/useLoadFragments";
import Timeline from "../timeline/components/Timeline";
import "./Review.scss";
import VideoOptionsSidebar from "../playback/components/videoOptionsSidebar/VideoOptionsSidebar";
import VideoToolsSidebar from "../playback/components/videoToolsSidebar/VideoToolsSidebar";
import ReviewVideoList from "./components/ReviewVideoList";
import { useManageViews } from "../../hooks/useManageViews";

function Review() {
    const { userConfig } = useUserConfig();
    const {
        isInvalidMediaId,
        mediaIdParam,
        timestampParam,
        mediaId,
        setMediaId,
        hasLoadedActiveMedia,
        activeMediaSource,
        isMediaSourcesEmpty,
    } = useManageViews();

    const [isOptionsSidebarExpanded, setIsOptionsSidebarExpanded] = useState(false);
    const [isToolsSidebarExpanded, setIsToolsSidebarExpanded] = useState(true);
    const [isVideoListExpanded, setIsVideoListExpanded] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(undefined);

    const { analytics } = useSelector((state: ApplicationState) => state);
    const viewId = analytics?.primaryViewId;
    const { view } = useView(viewId);

    const entitiesInView = useMemo(() => {
        if (!view?.filteredEntities) {
            return [];
        }
        const millisecondsBetweenFrames = calculateFramePeriod(activeMediaSource?.files?.initialDisplay);
        return view.filteredEntities.filter((entity) =>
            isInRange(view.cursor, [
                entity.getStartTimeOrElse(() => new Date()).valueOf(),
                entity.getEndTimeOrElse(() => new Date()).valueOf() + millisecondsBetweenFrames,
            ]),
        );
    }, [view?.filteredEntities, view?.cursor]);

    const fragmentsMap = useLoadFragments(mediaId, viewId);
    const areFragmentGroupsLoaded = view?.filteredEntities !== undefined && view?.data?.state === "loaded";

    const videoSyncControlId = useRef(`vsc-${nanoid(8)}`);
    const { play, pause, mute, unmute, isPlaying } = useVideoControls(
        viewId,
        mediaId,
        videoRef,
        videoSyncControlId,
        view?.filteredEntities,
        entitiesInView,
    );

    // Sync video element's play and volume state with analytics store
    // Only needed because picture-in-picture mode has video controls
    useEffect(() => {
        if (videoRef?.current === null || activeMediaSource === undefined) {
            return;
        }

        // For syncing analytics media's muted state with video element's muted state
        function handleVolumeChange(e: Event) {
            (e.currentTarget as HTMLVideoElement).muted ? mute() : unmute();
        }

        videoRef.current?.addEventListener("play", play);
        videoRef.current?.addEventListener("pause", pause);
        videoRef.current?.addEventListener("volumechange", handleVolumeChange);

        return () => {
            pause();
            unmute();

            videoRef.current?.removeEventListener("play", play);
            videoRef.current?.removeEventListener("pause", pause);
            videoRef.current?.removeEventListener("volumechange", handleVolumeChange);
        };
    }, [videoRef?.current, mediaId]);

    if (isInvalidMediaId) {
        return (
            <ErrorPage
                subTitle="Invalid media source ID"
                errorMessageElement={
                    <p>
                        A valid media source with the ID{" "}
                        <code className="py-1 px-2 mx-1 bg-body-secondary">{mediaIdParam}</code> could not be found
                    </p>
                }
            />
        );
    } else if (isMediaSourcesEmpty) {
        return <NoMediaSourcesErrorPage />;
    }

    return (
        <main
            className={`border review${isOptionsSidebarExpanded ? " sidebar-left-expanded" : ""}${
                isToolsSidebarExpanded ? " sidebar-right-expanded" : ""
            }${isVideoListExpanded ? " video-list-expanded" : ""}`}
        >
            <section className="p-2 shadow-sm review-header bg-body-tertiary border-bottom">
                <VideoPlayerHeader
                    isMediaLoaded={hasLoadedActiveMedia}
                    viewId={viewId}
                    mediaId={mediaId}
                    videoRef={videoRef}
                />
            </section>

            <section className="shadow-sm review-video-list border-end border-bottom">
                <ReviewVideoList
                    viewId={viewId}
                    mediaId={mediaId}
                    setMediaId={setMediaId}
                    isExpanded={isVideoListExpanded}
                    setIsExpanded={setIsVideoListExpanded}
                />
            </section>

            <section className="review-sidebar-left border-end">
                <VideoOptionsSidebar
                    viewId={viewId}
                    mediaId={mediaId}
                    isExpanded={isOptionsSidebarExpanded}
                    setIsExpanded={setIsOptionsSidebarExpanded}
                    videoRef={videoRef}
                />
            </section>

            <section className="review-player position-relative d-flex flex-column">
                <VideoPlayer
                    key={viewId}
                    videoRef={videoRef}
                    videoType="video/mp4"
                    viewId={viewId}
                    mediaId={mediaId}
                    isLoaded={areFragmentGroupsLoaded && hasLoadedActiveMedia}
                    entitiesInView={entitiesInView}
                    fragmentsMap={fragmentsMap}
                    timestampParam={timestampParam}
                />
            </section>

            <section className="p-1 review-controls bg-body-tertiary border-top border-bottom shadow-sm-y">
                <VideoControls
                    viewId={viewId}
                    mediaId={mediaId}
                    videoRef={videoRef}
                    entities={view?.filteredEntities}
                    entitiesInView={entitiesInView}
                />
            </section>

            <section className="review-sidebar-right border-start">
                <VideoToolsSidebar
                    viewId={viewId}
                    mediaId={mediaId}
                    isExpanded={isToolsSidebarExpanded}
                    setIsExpanded={setIsToolsSidebarExpanded}
                    videoRef={videoRef}
                />
            </section>

            <section className="review-timeline d-flex flex-column align-items-center position-relative">
                <Timeline
                    key={viewId}
                    viewId={viewId}
                    media={activeMediaSource}
                    isLoaded={areFragmentGroupsLoaded && hasLoadedActiveMedia}
                    videoRef={videoRef}
                    entities={view?.filteredEntities}
                    fragmentsMap={fragmentsMap}
                    isShowingDebugUi={userConfig.showDebugUi}
                />
            </section>
        </main>
    );
}

export default Review;
