import dayjs from "dayjs";
import { DateFormats } from "../../utilities/dates";
import { Entity } from "../../models/viz/Entity";
import { csvFormatRows } from "d3";

const IGNORED_PROPERTIES: Readonly<string[]> = ["Bounds", "FreeFormSearch", "FragmentGroupId"];
const LIST_SEPARATOR = ", " as const;

export type CsvData = string[][];

function adjustWhitespace(s: string): string {
    return (
        s
            .trim()
            // While new lines are valid if quoted, it makes the CSV hard to read so we just replace
            // them (and other excess whitespace) with spaces
            .replace(/\s+/g, " ")
    );
}

function handleDate(d: Date): string {
    return dayjs(d).format(DateFormats.yearMonthDayWithTimeSeconds);
}

function handleString(s: string): string {
    return adjustWhitespace(s ?? "");
}

function handleNumber(n: number): string {
    return adjustWhitespace(n?.toString() ?? "");
}

function handleUnknown(u: unknown): string {
    return JSON.stringify(u) ?? "";
}

/** Convert a list of entities into a CSV file compatible string */
export function convertEntitiesToCsvData(entities: Entity[]): CsvData {
    if (entities.length === 0) {
        return [];
    }

    // CSV headers row based on entity properties
    const prop_keys: string[] = [];
    // Rows representing entities and columns representing values for the corresponding headers
    const rows: string[][] = [];

    for (const entity of entities) {
        // Gather any new headers for this entity
        // NOTE: necessary because entities like tag objects have different fields depending on their type
        for (const [key, value] of Object.entries(entity.properties)) {
            if (!prop_keys.includes(key) && !IGNORED_PROPERTIES.includes(key) && value.valueType !== "object") {
                prop_keys.push(key);

                // Update all existing rows with a (empty) value for the new field
                for (const row of rows) {
                    row.push("");
                }
            }
        }

        // Initialise each row with a column for the entity ID
        const newRow = [entity.id];
        // Gather property values for each header
        for (const key of prop_keys) {
            const prop = entity.properties[key];
            if (prop === undefined) {
                newRow.push("");
                continue;
            }

            // Handle different property types, converting them all to strings
            let strProp: string;
            switch (prop.valueType) {
                case "date":
                    strProp = prop.isList
                        ? (prop.value() as Date[]).map(handleDate).join(LIST_SEPARATOR)
                        : handleDate(prop.value() as Date);
                    break;
                case "string":
                    strProp = prop.isList
                        ? (prop.value() as string[]).map(handleString).join(LIST_SEPARATOR)
                        : handleString(prop.value() as string);
                    break;
                case "number":
                    strProp = prop.isList
                        ? (prop.value() as number[]).map(handleNumber).join(LIST_SEPARATOR)
                        : handleNumber(prop.value() as number);
                    break;
                default:
                    strProp = prop.isList
                        ? (prop.value() as unknown[]).map(handleUnknown).join(LIST_SEPARATOR)
                        : handleUnknown(prop.value());
            }

            newRow.push(strProp);
        }

        rows.push(newRow);
    }

    const headers = [
        "ID",
        // Convert property keys to their display name
        ...prop_keys.map(
            (k) => entities.find((e) => e.properties[k] !== undefined).dataDescription.properties[k].displayName,
        ),
    ];

    rows.unshift(headers);
    return rows;
}

/** Generate a CSV file from the given data and download it. Also accepts a file name for the generated file. */
export function downloadCsvFile(data: CsvData, fileName?: string) {
    const data_str = csvFormatRows(data);
    const blob = new Blob([data_str], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    if (fileName) {
        a.download = fileName;
    }
    a.click();
}
