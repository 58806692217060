import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "@reduxjs/toolkit";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { AnalyticsMetadata, AnalyticsMetadataEntry, AnalyticsMetadataGroup } from "../../../../models/analysis/Config";
import {
    useDeleteAnalyticsMetadataGroupMutation,
    useGetAnalyticsMetadataQuery,
    useSetAnalyticsMetadataMutation,
} from "../../../../store/api/kinesense";
import { Notifications } from "../../../../utilities/Notifications/Notifications";
import "./MetadataEditor.scss";
import MetadataEditorGroup from "./MetadataEditorGroup";

export interface AnalyticsMetadataWithIds {
    groups: AnalyticsMetadataGroupWithIds[];
}

export interface AnalyticsMetadataGroupWithIds extends AnalyticsMetadataGroup {
    entries: AnalyticsMetadataEntryWithId[];
}

export interface AnalyticsMetadataEntryWithId extends AnalyticsMetadataEntry {
    id: string;
}

export function convertAnalyticsMetadataWithIds(data: AnalyticsMetadataWithIds): AnalyticsMetadata {
    const convertedData = { groups: [] };

    for (const group of data.groups) {
        const convertedGroup = { groupId: group.groupId, name: group.name, entries: [] };

        for (const entry of group.entries) {
            convertedGroup.entries.push({ name: entry.name, isRequired: entry.isRequired } as AnalyticsMetadataEntry);
        }

        convertedData.groups.push(convertedGroup as AnalyticsMetadataGroup);
    }

    return convertedData as AnalyticsMetadata;
}

export function convertAnalyticsMetadata(data: AnalyticsMetadata): AnalyticsMetadataWithIds {
    const convertedData = { groups: [] };

    for (const group of data.groups) {
        const convertedGroup = { groupId: group.groupId, name: group.name, entries: [] };

        for (const entry of group.entries) {
            convertedGroup.entries.push({
                id: nanoid(5),
                name: entry.name,
                isRequired: entry.isRequired,
            } as AnalyticsMetadataEntryWithId);
        }

        convertedData.groups.push(convertedGroup as AnalyticsMetadataGroupWithIds);
    }

    return convertedData as AnalyticsMetadataWithIds;
}

function MetadataEditor() {
    const { data: metadata, refetch, isFetching } = useGetAnalyticsMetadataQuery({});
    const [localMetadata, setLocalMetadata] = useState<AnalyticsMetadataWithIds>(undefined);
    const [hasChanged, setHasChanged] = useState(true);
    const groups = localMetadata?.groups ?? [];

    const [setAnalyticsMetadata] = useSetAnalyticsMetadataMutation();
    const [deleteAnalyticsMetadataGroup] = useDeleteAnalyticsMetadataGroupMutation();

    const newGroupInputRef = useRef<HTMLInputElement>(undefined);

    const areButtonsDisabled = !hasChanged || isFetching;

    // Set localMetadata when config first loads
    useEffect(() => {
        if (isFetching) {
            return;
        }

        setLocalMetadata(convertAnalyticsMetadata(metadata));
        setHasChanged(false);
    }, [isFetching]);

    function addGroup(newGroup: AnalyticsMetadataGroupWithIds) {
        setLocalMetadata((oldMetadata) => ({ groups: [...oldMetadata.groups, newGroup] }));
        setHasChanged(true);
    }

    async function handleOnClickSave(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setHasChanged(false);

        try {
            const newMetadata = convertAnalyticsMetadataWithIds(localMetadata);

            if (newMetadata?.groups === undefined) {
                return;
            }

            const newMetadataGroupIds = newMetadata.groups.map((g) => g.groupId);

            for (const group of metadata.groups) {
                const groupId = group.groupId;

                if (newMetadataGroupIds.includes(groupId)) {
                    continue;
                }

                await deleteAnalyticsMetadataGroup({ groupId }).unwrap();
            }

            await setAnalyticsMetadata(convertAnalyticsMetadataWithIds(localMetadata)).unwrap();

            Notifications.notify(
                "Metadata updated successfully",
                "The organisation's metadata configuration has been updated successfully",
            );
            refetch();
        } catch (e) {
            Notifications.notify(
                "Error updating metadata",
                `An error was encountered while trying to update the organisation's metadata configuration: ${e.message}`,
            );
        }
    }

    function handleOnClickReset(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        refetch();
    }

    function handleOnSubmit(e: React.FormEvent) {
        e.preventDefault();

        const newGroup: AnalyticsMetadataGroupWithIds = {
            groupId: nanoid(12),
            name: newGroupInputRef.current.value,
            entries: [],
        };

        addGroup(newGroup);
        newGroupInputRef.current.value = "";
    }

    return (
        <article className="metadata-editor">
            <Container className="px-0 mb-2 d-flex justify-content-between">
                <h5>Evidence Information</h5>
            </Container>

            <ConditionalSpinner isLoading={isFetching}>
                <div className="gap-3 d-flex flex-column">
                    {groups.map((group, i) => (
                        <MetadataEditorGroup
                            key={group.groupId}
                            isLoaded={!isFetching}
                            localMetadata={localMetadata}
                            setLocalMetadata={setLocalMetadata}
                            onChanged={() => setHasChanged(true)}
                            metadata={metadata}
                            groupIndex={i}
                        />
                    ))}

                    <form className="p-4 rounded border group" onSubmit={handleOnSubmit}>
                        <Form.Control ref={newGroupInputRef} placeholder="New group" required />

                        <button className="button-with-icon" type="submit" title="Add new group">
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </button>
                    </form>
                </div>
            </ConditionalSpinner>

            <section className="gap-3 mt-4 d-flex justify-content-between w-100">
                <Button variant="secondary" disabled={areButtonsDisabled} onClick={handleOnClickReset}>
                    Reset
                </Button>

                <Button variant="primary" disabled={areButtonsDisabled} onClick={handleOnClickSave}>
                    Save changes
                </Button>
            </section>
        </article>
    );
}

export default MetadataEditor;
