import { MutableRefObject, useEffect, useState } from "react";

function useHover(nodeRef: MutableRefObject<HTMLElement>) {
    const [isHovering, setIsHovering] = useState(false);
    const [mouseLocation, setMouseLocation] = useState<[number, number]>([0, 0]);
    const [mouseLocationClient, setMouseLocationClient] = useState<[number, number]>([0, 0]);

    const handleMouseOver: EventListener = (_) => setIsHovering(true);
    const handleMouseOut: EventListener = (_) => setIsHovering(false);

    const handleMouseMove: EventListener = (event: MouseEventInit) => {
        if (event === undefined) {
            return;
        }

        const { offsetX, offsetY, clientX, clientY } = event as {
            offsetX: number;
            offsetY: number;
            clientX: number;
            clientY: number;
        };

        setMouseLocation([offsetX ?? 0, offsetY ?? 0]);
        setMouseLocationClient([clientX ?? 0, clientY ?? 0]);
    };

    useEffect(() => {
        nodeRef.current?.addEventListener("mouseover", handleMouseOver);
        nodeRef.current?.addEventListener("mouseout", handleMouseOut);
        nodeRef.current?.addEventListener("mousemove", handleMouseMove);

        return () => {
            nodeRef.current?.removeEventListener("mouseover", handleMouseOver);
            nodeRef.current?.removeEventListener("mouseout", handleMouseOut);
            nodeRef.current?.removeEventListener("mousemove", handleMouseMove);
        };
    }, [nodeRef.current]);

    return { isHovering, mouseLocation, mouseLocationClient };
}

export default useHover;
