import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import { VizContext } from "../../../models/viz/operations/DataOperation";
import { Button, Form, Modal, Table } from "react-bootstrap";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { Entity } from "../../../models/viz/Entity";
import { useMemo, useState } from "react";
import { capitaliseFirst, tryExtractErrorMessage } from "../../../utilities/helpers";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import { convertEntitiesToCsvData, downloadCsvFile } from "../../../utilities/entities/exportToCsv";

export interface ExportEntitiesToCsvModalProps {
    context: VizContext;
    filenamePrefix?: string;
}

const EntityTargets = ["Filtered", "Selected"] as const;
type EntityTarget = (typeof EntityTargets)[number];

const ExportEntitiesToCsvModal = NiceModal.create((props: ExportEntitiesToCsvModalProps) => {
    const { view } = props.context;
    const hasSelectedEntities = view?.selectedEntity !== undefined;
    const selectedEntities = hasSelectedEntities ? view.selectedEntities : [];
    const filteredEntities = view?.filteredEntities ?? [];

    const date = dayjs().format(DateFormats.yearMonthDay);
    const defaultFilename = !props.filenamePrefix ? date : `${props.filenamePrefix} ${date}`;

    const [entityTarget, setEntityTarget] = useState<EntityTarget>(hasSelectedEntities ? "Selected" : "Filtered");
    const [filename, setFilename] = useState<string | undefined>(defaultFilename);

    const data = useMemo(() => {
        let entities: Entity[];
        switch (entityTarget) {
            case "Selected":
                entities = selectedEntities;
                break;
            case "Filtered":
                entities = filteredEntities;
        }
        return convertEntitiesToCsvData(entities);
    }, [entityTarget]);

    const modal = useModal();

    function handleOnSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        try {
            downloadCsvFile(data, filename);

            Notifications.notify(
                "File downloaded successfully",
                `The file "${filename}.csv" was downloaded successfully`,
                "info",
            );
            modal.hide();
        } catch (e) {
            Notifications.notify(
                "Error while downloading CSV file",
                `The following error was encountered while attempting to download a CSV file: ${tryExtractErrorMessage(
                    e,
                )}`,
                "warning",
            );
        }
    }

    return (
        <Modal {...bootstrapDialog(modal)} centered backdrop="static" keyboard={false} size="xl">
            <Modal.Header closeButton>Export to CSV</Modal.Header>

            <Form onSubmit={handleOnSubmit}>
                <Modal.Body className="gap-2 d-flex flex-column">
                    <Form.Label label="Type">
                        Filename
                        <div className="gap-2 d-flex align-items-center">
                            <Form.Control
                                type="text"
                                value={filename}
                                onChange={(e) => setFilename(e.target.value)}
                                required
                            />
                            .csv
                        </div>
                    </Form.Label>

                    {hasSelectedEntities && (
                        <Form.Label>
                            Entities to export
                            <Form.Select
                                value={entityTarget}
                                onChange={(e) => setEntityTarget(e.target.value as EntityTarget)}
                                required
                            >
                                {EntityTargets.map((type) => (
                                    <option value={type} key={type}>
                                        {capitaliseFirst(type)} entities
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Label>
                    )}

                    <h5 className="mt-2">Preview</h5>
                    <div className="overflow-scroll" style={{ maxHeight: "20rem" }}>
                        {data.length > 1 && (
                            <Table striped borderless>
                                <thead className="border-bottom">
                                    <tr>
                                        {data[0].map((h, i) => (
                                            <th className="text-nowrap" key={i}>
                                                {h}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.slice(1).map((row, i) => (
                                        <tr key={i}>
                                            {row.map((d, ii) => (
                                                <td
                                                    className="overflow-hidden text-nowrap"
                                                    style={{ textOverflow: "ellipsis", maxWidth: "15rem" }}
                                                    title={d}
                                                    key={ii}
                                                >
                                                    {d}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={modal.hide}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="success">
                        Download CSV
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});
export default ExportEntitiesToCsvModal;
