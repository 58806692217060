import { EntityFilter } from "../filters/EntityFilter";
import { EntitySorter } from "../sorters/EntitySorter";
import { EntityAdapter } from "../entityAdapters/EntityAdapter";
import { EntityInformation } from "../entityInformation/EntityInformation";
import { DataOperation } from "../operations/DataOperation";
import { PropertyDescription } from "./PropertyDescription";

export abstract class DataDescription {
    type: string;
    infoGenerator: EntityInformation;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entityAdapter: EntityAdapter<any>;
    properties: { [key: string]: PropertyDescription };
    filters: { [key: string]: EntityFilter } = {};
    sorters: EntitySorter[] = [];
    defaultSorter: EntitySorter;
    operations: DataOperation[] = [];
    childDataDescriptions: DataDescription[] = [];

    setProperties(properties: PropertyDescription[]) {
        this.properties = {};
        properties.forEach((p) => {
            this.properties[p.key] = p;
        });
    }
}
