import { useCallback, useMemo, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { Theme } from "../models/UserConfig";
import { Notifications } from "../utilities/Notifications/Notifications";
import useUserConfig from "./useUserConfig";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";

export function useTheme() {
    const { organisationId, username } = useSelector((state: ApplicationState) => state.user);

    const localStorageKey = `${organisationId}-${username}-theme`;

    const { userConfig, isFetching, isSuccess, updateUserConfig } = useUserConfig();

    // Local theme is defined as a backup and for when there is no user logged in (user.session is undefined)
    const [localTheme, setLocalTheme] = useLocalStorage<Theme>(localStorageKey, userConfig.uiTheme);
    const [isUpdatingTheme, setIsUpdatingTheme] = useState(false);
    const [updatedTheme, setUpdatedTheme] = useState<Theme>(userConfig.uiTheme);

    const theme = useMemo(() => {
        if (isUpdatingTheme || isFetching) {
            return updatedTheme;
        }

        return isSuccess ? userConfig.uiTheme : localTheme;
    }, [isFetching, updatedTheme]);
    document.documentElement.dataset.bsTheme = theme;

    const isDarkTheme = theme === Theme.DARK;

    // Sync local storage
    if (theme !== localTheme) {
        setLocalTheme(theme);
    }

    const setTheme = useCallback(async (nextTheme: Theme) => {
        setIsUpdatingTheme(true);
        setUpdatedTheme(nextTheme);

        try {
            await updateUserConfig({ uiTheme: nextTheme });
            setLocalTheme(nextTheme);
        } catch (e) {
            Notifications.notify(
                "Error updating theme",
                `Error encountered while trying to update user theme: ${JSON.stringify(e)}`,
                "warning",
            );
        }

        setIsUpdatingTheme(false);
    }, []);

    function toggleTheme() {
        const nextTheme: Theme = theme === Theme.DARK ? Theme.LIGHT : Theme.DARK;
        setTheme(nextTheme);
    }

    return { theme, setTheme, toggleTheme, isDarkTheme, isUpdatingTheme };
}
