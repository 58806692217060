import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { DataOperation, VizContext } from "./DataOperation";
import { buildMediaSourceQueryString } from "../../../hooks/useManageViews";

export class JumpToReviewAtTimeOperation extends DataOperation {
    constructor() {
        super();
        this.operationId = "jumpToReviewAtTime";
        this.displayName = "Jump to Review at Time";
        this.icon = {
            type: "fontawesome",
            icon: faArrowRight,
        };
        this.priority = "primary";
        this.context = "entity";
        this.category = "jump";
    }

    canExecute(context: VizContext) {
        return context.view.selectedEntity != undefined && context.view.selectedEntity.sourceObject != undefined;
    }

    execute(context: VizContext) {
        const entity = context.view.selectedEntity;
        if (entity.sourceObject.mediaSource == undefined) {
            return;
        }

        const media = entity.sourceObject.mediaSource;
        const offset = entity.getStartTimeOr(new Date(media.startsAt)).valueOf() - media.startsAt;

        console.log("Jumping...", context.view.selectedEntity, offset);
        context.react.navigate(`/review?${buildMediaSourceQueryString(context.projectId, media.mediaId, offset)}`);
    }
}
