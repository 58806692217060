import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ListGroup } from "react-bootstrap";
import { Processor } from "../../../../models/admin/Processor";
import { DateFormats } from "../../../../utilities/dates";

export function formatProcessorDate(dateMilliseconds: number) {
    if (dateMilliseconds <= 0) {
        return "N/A";
    }
    return dayjs(dateMilliseconds).format(DateFormats.yearMonthDayWithTimeSeconds);
}

export interface ProcessorListItemProps {
    data: Processor;
}

function ProcessorListItem(props: ProcessorListItemProps) {
    return (
        <motion.div
            layout
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 50 }}
            transition={{ ease: "easeOut" }}
            className="p-0 list-group-item"
        >
            <ListGroup.Item
                action
                as={Link}
                to={`/admin/processors/${props.data.processorId}`}
                className="border-0 outline-0"
            >
                <span>{props.data.processorId}</span>
                <span>{formatProcessorDate(props.data.lastStartedAt)}</span>
                <span>{props.data.type}</span>
            </ListGroup.Item>
        </motion.div>
    );
}

export default ProcessorListItem;
