import { DataDescription } from "./DataDescription";
import { DataDescriptions } from "./GenericVideoEventDataDescription";

export function getDataDescriptionsForAnalyser(analyserId: string): DataDescription[] {
    switch (analyserId) {
        case "ks:std":
            return [DataDescriptions.standardMovement];
        case "ks:stdclass":
            return [DataDescriptions.objectClassification, DataDescriptions.standardMovement];
        default:
            return [DataDescriptions.generic];
    }
}
