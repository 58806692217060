import { useEffect, useState } from "react";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import { tryExtractErrorMessage } from "../../../utilities/helpers";

function useToggleUserConfigOption(
    option: boolean,
    mutateOption: (option: boolean) => Promise<unknown>,
    notificationTitle: string,
): [boolean, boolean, () => void] {
    const [localOption, setLocalOption] = useState(option);
    const [isUpdating, setIsUpdating] = useState(false);

    function toggleOption() {
        setIsUpdating(true);

        const nextOption = !localOption;
        setLocalOption(nextOption);

        mutateOption(nextOption)
            .catch((e) => {
                Notifications.notify(
                    notificationTitle,
                    `The following error was encountered while attempting to toggle this user setting: ${tryExtractErrorMessage(
                        e,
                    )}`,
                    "warning",
                );
            })
            .finally(() => setIsUpdating(false));
    }

    const debouncedToggleOption = useDebouncedCallback(toggleOption, 250, {
        isImmediate: true,
        maxWait: 1000,
    });

    // Ensure local value is synced to user config
    useEffect(() => {
        if (option === undefined || localOption == option) {
            return;
        }
        setLocalOption(option);
    }, [option]);

    return [localOption, isUpdating, debouncedToggleOption];
}

export default useToggleUserConfigOption;
