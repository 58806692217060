import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { actionCreators } from "../store/user/User";

export function getAuthHeaders(token: string): Headers {
    return new Headers({
        Authorization: `Bearer ${token}`,
    });
}

export function addAuthHeaders(token: string, headers: Headers) {
    headers.append("Authorization", `Bearer ${token}`);
}

export function useAutoRefreshAuthToken() {
    const dispatch = useDispatch();

    const session = useSelector<ApplicationState, CognitoUserSession>((state) => state.user?.session);
    const idToken = useMemo(() => session?.getIdToken(), [session]);

    const currentIntervalRef = useRef<NodeJS.Timeout>(null);

    const [isRunning, setIsRunning] = useState(true);

    // Cleanup on unmount
    useEffect(() => {
        return () => clearInterval(currentIntervalRef.current);
    }, []);

    function startAutoRefresh() {
        clearInterval(currentIntervalRef.current);

        if (idToken) {
            const differenceSeconds = idToken.getExpiration() - idToken.getIssuedAt();
            const timeoutMilliseconds = Math.max(
                // Get half of the milliseconds until the idToken is invalid
                differenceSeconds * 500,
                // Minimum timeout in milliseconds, in case there is an issue with above
                120000,
            );

            currentIntervalRef.current = setInterval(() => {
                dispatch(actionCreators.refreshAuthSession());
            }, timeoutMilliseconds);

            setIsRunning(true);

            return true;
        }
        return false;
    }

    function stopAutoRefresh() {
        setIsRunning(false);
        clearInterval(currentIntervalRef.current);
    }

    return { isRunning, startAutoRefresh, stopAutoRefresh };
}
