import { useEffect, useMemo, useRef } from "react";
import { debounce, Options as debounceOptions } from "ts-debounce";

function useDebouncedCallback(callback: () => void, waitMilliseconds: number, options?: debounceOptions<void>) {
    const ref = useRef<() => void>();

    useEffect(() => {
        ref.current = callback;

        return () => {
            ref.current = undefined;
        };
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        return debounce(
            () => {
                ref.current?.();
            },
            waitMilliseconds,
            options,
        );
    }, []);

    return debouncedCallback;
}

export default useDebouncedCallback;
