import { faClose, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, ListGroup } from "react-bootstrap";

export interface EditTagObjectModalAliasesFieldProps {
    aliases: string[];
    setAliases: (update: React.SetStateAction<string[]>) => void;
}

function EditTagObjectModalAliasesField(props: EditTagObjectModalAliasesFieldProps) {
    const [newAlias, setNewAlias] = useState("");

    function removeAlias(index: number) {
        props.setAliases((currAliases) => [...currAliases.slice(0, index), ...currAliases.slice(index + 1)]);
    }

    function updateAlias(index: number, update: string) {
        if (update === "") {
            removeAlias(index);
            return;
        }

        props.setAliases((currAliases) => {
            const newAliases = [...currAliases];
            newAliases[index] = update;
            return newAliases;
        });
    }

    function addAlias() {
        if (newAlias === "" || props.aliases.includes(newAlias)) {
            return;
        }

        props.setAliases((currAliases) => [...currAliases, newAlias]);
        setNewAlias("");
    }

    return (
        <Form.Group id="aliases-field" className="p-3 rounded border">
            <Form.Label>Aliases</Form.Label>

            <ListGroup>
                {props.aliases.map((alias, i) => (
                    <ListGroup.Item key={i} className="gap-2 p-0">
                        <Form.Control value={alias} onChange={(e) => updateAlias(i, e.target.value)} />
                        <button
                            className="button-with-icon"
                            title="Remove alias"
                            onClick={(e) => {
                                e.preventDefault();
                                removeAlias(i);
                            }}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </ListGroup.Item>
                ))}

                <ListGroup.Item className="gap-2 p-0">
                    <Form.Control value={newAlias} onChange={(e) => setNewAlias(e.target.value)} />

                    <button
                        className="button-with-icon"
                        title="Add new alias"
                        onClick={(e) => {
                            e.preventDefault();
                            addAlias();
                        }}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                </ListGroup.Item>
            </ListGroup>
        </Form.Group>
    );
}

export default EditTagObjectModalAliasesField;
