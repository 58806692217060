import { PropsWithChildren } from "react";
import NavMenu from "./NavMenu";
import { NotificationArea } from "../utilities/Notifications/NotificationArea";
import StatusBar from "./StatusBar";
import "./AppLayout.scss";

export default function AppLayout(props: PropsWithChildren<Record<never, never>>) {
    return (
        <>
            <NavMenu />
            <div className="pb-4 base-layout">{props.children}</div>
            <NotificationArea />
            <StatusBar />
        </>
    );
}
