import { useGetRunsQuery } from "../../../store/api/kinesense";

/** Get the number of analysis runs associated with a given media ID.
 *
 * Returns undefined while the runs are not yet loaded. */
function useRunsCount(mediaId: string): number | undefined {
    const { data: runs, isSuccess: hasLoadedRuns } = useGetRunsQuery({ mediaId }, { skip: !mediaId });

    if (!hasLoadedRuns) {
        return undefined;
    }

    return runs.length;
}

export default useRunsCount;
