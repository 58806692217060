import { produce } from "immer";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import { MediaSource } from "../../models/media/MediaSource";
import { getAuthHeaders } from "../../utilities/Auth";
import { DataDescription } from "../../models/viz/dataDescriptions/DataDescription";
import { getDataDescriptionsForAnalyser } from "../../models/viz/dataDescriptions/getDataDescriptionsForAnalyser";

type MediaSourceLoadState = "unloaded" | "loading" | "loaded";
export interface RunSummary {
    runId: number;
    analyserId: string;
    status: string;
}
export type MediaSourceWithRunSummaries = MediaSource & {
    runs: RunSummary[];
    validDataDescriptions: DataDescription[];
};
export type MediaSourceWithRunSummariesAndEndsAt = MediaSourceWithRunSummaries & { endsAt: number };
export type MediaSourceWithExtras = MediaSourceWithRunSummariesAndEndsAt & { loadState: MediaSourceLoadState };
type MediaSourcesMap = { [key: string]: MediaSourceWithExtras };

export interface MediaItemsState {
    media: MediaSourcesMap;
}

export interface RequestPrimaryMediaAction {
    type: "media/request";
    payload: {
        mediaId: string;
    };
}

export interface ReceivePrimaryMediaAction {
    type: "media/receive";
    payload: {
        mediaId: string;
        primaryMedia: MediaSourceWithRunSummariesAndEndsAt;
    };
}

export type KnownAction = RequestPrimaryMediaAction | ReceivePrimaryMediaAction;

export const actionCreators = {
    requestMediaSource:
        (mediaId: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const state = getState();

            if (state && state.mediaItems && mediaId !== undefined) {
                dispatch({
                    type: "media/request",
                    payload: {
                        mediaId: mediaId,
                    },
                });

                const headers = getAuthHeaders(state.user.authToken);
                const options: RequestInit = {
                    headers: headers,
                };

                fetch(`https://${process.env.REACT_APP_KINESENSE_API_BASE}/media/${mediaId}`, options)
                    .then((res) => res.json())
                    .then((json) => json.data)
                    .then((data: MediaSourceWithRunSummariesAndEndsAt) => {
                        data.endsAt = data.startsAt + data.duration;
                        const validDataDescriptions = data.runs.flatMap((run) =>
                            getDataDescriptionsForAnalyser(run.analyserId),
                        );

                        const uniqueDataDescriptions = [];
                        for (const dataDescription of validDataDescriptions) {
                            if (!uniqueDataDescriptions.includes(dataDescription)) {
                                uniqueDataDescriptions.push(dataDescription);
                            }
                        }

                        dispatch({
                            type: "media/receive",
                            payload: {
                                mediaId: mediaId,
                                primaryMedia: data,
                            },
                        });
                    });
            }
        },
};

const unloadedState: MediaItemsState = {
    media: {},
};

export const reducer: Reducer<MediaItemsState> = (
    state: MediaItemsState | undefined,
    incomingAction: Action,
): MediaItemsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    return produce(state, (draft) => {
        switch (action.type) {
            case "media/receive":
                draft.media[action.payload.mediaId] = { ...action.payload.primaryMedia, loadState: "loaded" };
                break;
            case "media/request":
                draft.media[action.payload.mediaId] = { ...state.media[action.payload.mediaId], loadState: "loading" };
                break;
            default:
                break;
        }
    });
};
