import { useCallback } from "react";
import { Form } from "react-bootstrap";
import { capitaliseFirst, cleanId } from "../../../../../utilities/helpers";
import { ImportWizardInputProps } from "../ImportWizardOption";

function ImportWizardSelectionInput(props: ImportWizardInputProps) {
    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChanged(event.currentTarget.value);
    }, []);
    const displayId = cleanId(props.option.displayName);
    const defaultValue = props.option.default ?? props.option.selectionOptions?.[0];
    const initialValue = props.default ?? defaultValue;

    return (
        <>
            {props.option.selectionOptions?.map((selectionOption) => {
                const radioId = `radio-${displayId}-${selectionOption}`;
                return (
                    <Form.Group key={radioId}>
                        <Form.Check
                            inline
                            id={radioId}
                            name={props.option.displayName}
                            type="radio"
                            checked={selectionOption === initialValue}
                            value={selectionOption}
                            onChange={onChange}
                        />
                        <Form.Label htmlFor={radioId} className="m-0">
                            {capitaliseFirst(selectionOption)}
                        </Form.Label>
                    </Form.Group>
                );
            })}
        </>
    );
}

export default ImportWizardSelectionInput;
