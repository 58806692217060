import dayjs from "dayjs";
import { DateFormats } from "../../../../utilities/dates";
import { Table } from "react-bootstrap";
import CopyButton from "../../../../components/copyButton/CopyButton";
import { ReviewVideoListItemTrayContentProps } from "./ReviewVideoListItemFooter";

export default function ReviewVideoListItemDetails(props: ReviewVideoListItemTrayContentProps) {
    // Details
    const startTime = dayjs(props.data.startsAt).format(DateFormats.dayMonthYearWithTimeSeconds);
    const endTime = dayjs(props.data.startsAt + props.data.duration).format(DateFormats.dayMonthYearWithTimeSeconds);
    const duration = dayjs(props.data.duration).format(DateFormats.dayMonthYearWithTimeSeconds);
    const id = props.data.mediaId;
    const createdAt = dayjs(props.data.createdAt).format(DateFormats.dayMonthYearWithTimeSeconds);
    const createdBy = props.data.createdBy?.id ?? "???";

    // Metadata
    const { format, width, height, audioBitRate, audioChannelCount, audioSampleRate, frameCount, frameRate } =
        props.data.files.initialDisplay;

    return (
        <div ref={props.contentRef} className="inner">
            <section className="overflow-hidden">
                <h4>Details</h4>
                <Table borderless className="details-table">
                    <tbody>
                        <tr>
                            <td>Start Time</td>
                            <td className="w-100">{startTime}</td>
                            <td>
                                <CopyButton toCopy={startTime} />
                            </td>
                        </tr>

                        <tr>
                            <td>End Time</td>
                            <td>{endTime}</td>
                            <td>
                                <CopyButton toCopy={endTime} />
                            </td>
                        </tr>

                        <tr>
                            <td>Duration</td>
                            <td>{duration}</td>
                            <td>
                                <CopyButton toCopy={duration} />
                            </td>
                        </tr>

                        <tr>
                            <td>ID</td>
                            <td>{id}</td>
                            <td>
                                <CopyButton toCopy={id} />
                            </td>
                        </tr>

                        <tr>
                            <td>Creation At</td>
                            <td className="w-100">{createdAt}</td>
                            <td>
                                <CopyButton toCopy={createdAt} />
                            </td>
                        </tr>

                        <tr>
                            <td>Created By</td>
                            <td>{createdBy}</td>
                            <td>
                                <CopyButton toCopy={createdBy} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </section>

            <section className="overflow-hidden">
                <h4>Metadata</h4>
                <div className="overflow-scroll" style={{ maxHeight: "10rem" }}>
                    <Table borderless className="details-table">
                        <tbody>
                            {format !== undefined && (
                                <tr>
                                    <td>Format</td>
                                    <td>{format}</td>
                                </tr>
                            )}

                            {height && width && (
                                <tr>
                                    <td>Video resolution</td>
                                    <td>{`${width}x${height}`}</td>
                                </tr>
                            )}

                            {frameRate && (
                                <tr>
                                    <td>Frame rate</td>
                                    <td>{frameRate} fps</td>
                                </tr>
                            )}

                            {frameCount && (
                                <tr>
                                    <td>Frame count</td>
                                    <td>{frameCount}</td>
                                </tr>
                            )}

                            {audioChannelCount && (
                                <tr>
                                    <td>Audio Channel Count</td>
                                    <td>{audioChannelCount}</td>
                                </tr>
                            )}

                            {audioBitRate && (
                                <tr>
                                    <td>Audio Bit Rate</td>
                                    <td>{Math.round(audioBitRate / 1000)} kb/s</td>
                                </tr>
                            )}

                            {audioSampleRate && (
                                <tr>
                                    <td>Audio Sample Rate</td>
                                    <td>{audioSampleRate} Hz</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </section>
        </div>
    );
}
