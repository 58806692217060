import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { ImportWizardInputProps } from "../ImportWizardOption";

export interface ImportWizardNumberInputProps extends ImportWizardInputProps {
    step?: number;
}

function ImportWizardNumberInput(props: ImportWizardNumberInputProps) {
    const defaultValue = typeof props.option.default == "number" ? props.option.default : 1;
    const initialValue = typeof props.default == "number" ? props.default : defaultValue;
    const [value, setValue] = useState(initialValue);
    const min = props.option.min ?? 0;
    const max = props.option.max ?? 100;
    const step = props.step ?? 1;

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let nextValue = parseInt(event.currentTarget.value);

        if (isNaN(nextValue)) {
            return;
        } else if (nextValue < min) {
            nextValue = min;
        } else if (nextValue > max) {
            nextValue = max;
        }

        setValue(nextValue);
        props.onChanged(nextValue);
    }, []);

    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && value.toString().length <= 1) {
            setValue(min);
        }
    }, []);

    return (
        <Form.Control
            value={value.toString()}
            type="number"
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            onKeyDown={onKeyDown}
        />
    );
}

export default ImportWizardNumberInput;
