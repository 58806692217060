import { nanoid } from "@reduxjs/toolkit";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { EntityAdapter } from "./EntityAdapter";
import { Entity } from "../Entity";

export class EmptyEntityAdapter implements EntityAdapter<never> {
    dataDescription: DataDescription;

    constructor(dataDescription: DataDescription) {
        this.dataDescription = dataDescription;
    }

    setDataDescription(dataDescription: DataDescription): void {
        this.dataDescription = dataDescription;
    }

    convertToEntity(_: never): Entity {
        return new Entity({
            id: nanoid(),
            dataDescription: this.dataDescription,
        });
    }
}
