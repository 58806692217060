import { AnimatePresence, motion } from "framer-motion";
import { Form, ListGroup, Spinner } from "react-bootstrap";

export interface UserConfigSwitchProps {
    label: string;
    title: string;
    isChecked: boolean;
    onChange: () => void;
    isUpdating: boolean;
}

function UserConfigSwitch(props: UserConfigSwitchProps) {
    return (
        <ListGroup.Item title={props.title}>
            <Form.Label>
                <span>{props.label}</span>
                <Form.Check
                    type="switch"
                    checked={props.isChecked}
                    onChange={props.onChange}
                    disabled={props.isUpdating}
                />

                <AnimatePresence>
                    {props.isUpdating && (
                        <Spinner
                            key="loading"
                            size="sm"
                            role="status"
                            as={motion.div}
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}
                        />
                    )}
                </AnimatePresence>
            </Form.Label>
        </ListGroup.Item>
    );
}

export default UserConfigSwitch;
