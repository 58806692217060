import NiceModal from "@ebay/nice-modal-react";
import EditTagEventModal from "../../../components/dataOperationModals/editTagEventModal/EditTagEventModal";
import { DataOperation, VizContext } from "./DataOperation";

export class CreateTagEventDataOperation extends DataOperation {
    constructor() {
        super();
        this.operationId = "createTagEvent";
        this.displayName = "Create Tag Event";
        this.icon = {
            type: "image",
            url: "/images/icons/tag-add-object_56.png",
        };
        this.priority = "primary";
        this.context = "global";
        this.category = "tags";
        NiceModal.register(this.operationId, EditTagEventModal);
    }

    canExecute(context: VizContext) {
        const entity = context.view?.selectedEntity ?? context.view?.entities[0];
        return entity?.sourceObject?.mediaSource !== undefined;
    }

    execute(context: VizContext) {
        NiceModal.show(this.operationId, { context });
    }
}
