import { Button, Modal } from "react-bootstrap";
import { AugmentUserArgs, useAdminDeleteUserMutation, useDeleteUserMutation } from "../../../../../store/api/kinesense";
import { Notifications } from "../../../../../utilities/Notifications/Notifications";
import { useBasicAlerts } from "../../../../../components/BasicAlert";

export interface BaseAugmentUserModalProps {
    isVisible: boolean;
    setIsVisible: (nextIsVisible: boolean) => void;
    renderUsername: () => JSX.Element;
    isForCurrentUsersOrganisation: boolean;
    augmentUserArgs: AugmentUserArgs;
}

export type ConfirmDeleteUserModalProps = BaseAugmentUserModalProps;

function ConfirmDeleteUserModal(props: ConfirmDeleteUserModalProps) {
    const [requestDeleteUser] = props.isForCurrentUsersOrganisation
        ? useDeleteUserMutation()
        : useAdminDeleteUserMutation();

    const { alerts, addAlert, clearAlerts } = useBasicAlerts();

    const hideConfirmDeleteModal = () => {
        props.setIsVisible(false);
        clearAlerts();
    };

    function handleOnClickConfirmDelete() {
        clearAlerts();

        requestDeleteUser(props.augmentUserArgs)
            .unwrap()
            .then(() => {
                hideConfirmDeleteModal();

                Notifications.notify(
                    "User Deleted Successfully",
                    `The user with username ${props.augmentUserArgs.username} has been deleted`,
                    "info",
                );
            })
            .catch(() => {
                addAlert({
                    message: `There was an error with the request to delete the user ${props.augmentUserArgs.username}. Please contact customer service.`,
                });
            });
    }

    return (
        <Modal show={props.isVisible} onHide={hideConfirmDeleteModal} backdrop="static" keyboard={false} centered>
            <Modal.Header className="text-danger text-decoration-underline fw-bold">Warning!</Modal.Header>
            <Modal.Body>
                {alerts}

                <p>You are about to permanently delete the following user:</p>
                {props.renderUsername()}
                <p>Are you sure you wish to continue?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleOnClickConfirmDelete} type="button" variant="danger">
                    Delete
                </Button>
                <Button onClick={hideConfirmDeleteModal} type="button" variant="secondary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDeleteUserModal;
