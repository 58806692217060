import { Link } from "react-router-dom";
import { useMemo } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Processor } from "../../../../models/admin/Processor";
import { useAdminGetAllProcessorsQuery, useAdminGetProcessorTypesQuery } from "../../../../store/api/kinesense";
import ProcessorTypeCostBreakdown from "../processors/ProcessorTypeCostBreakdown";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { ignoreDragEvent } from "../../../../components/dashboard/Dashboard";

function useProcessorsStats() {
    const { data: processors, isLoading: isLoadingProcessors } = useAdminGetAllProcessorsQuery();
    const { data: processorTypes, isLoading: isLoadingProcessorTypes } = useAdminGetProcessorTypesQuery();

    const isLoading = isLoadingProcessors || isLoadingProcessorTypes;
    const count = processors?.length;
    const costsPerHourMap: { [key: string]: number } = useMemo(() => {
        if (isLoadingProcessorTypes) {
            return;
        }

        const result = {};

        for (const key of Object.keys(processorTypes)) {
            const val = processorTypes[key];
            result[val.type] = val.costPerHour;
        }

        return result;
    }, [processorTypes]);

    function getCostPerHourOfProcessor(processor: Processor) {
        return costsPerHourMap[`${processor.type}:${processor.metadata?.ec2InstanceType}`];
    }

    const costPerHourDollars = useMemo(() => {
        let totalCost = 0;

        if (isLoading) {
            return totalCost;
        }

        for (const processor of processors) {
            const cost = getCostPerHourOfProcessor(processor);
            totalCost += cost;
        }

        return totalCost;
    }, [processors, processorTypes]);

    return { costPerHourDollars, count, isLoading };
}

function ProcessorsCard() {
    const { costPerHourDollars, count, isLoading } = useProcessorsStats();

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5" className="m-1">
                    Processors
                </Card.Title>
            </Card.Header>

            <Card.Body className="gap-3 py-3 px-4 d-flex flex-column" {...ignoreDragEvent}>
                <ConditionalSpinner isLoading={isLoading}>
                    <Table className="m-0" borderless>
                        <tbody>
                            <tr>
                                <th className="ps-0" style={{ width: 0, whiteSpace: "nowrap" }}>
                                    Total processors
                                </th>
                                <td>{count}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <ProcessorTypeCostBreakdown costPerHourDollars={costPerHourDollars} />
                </ConditionalSpinner>
            </Card.Body>

            <Card.Footer className="text-center" {...ignoreDragEvent}>
                <Link className="btn btn-primary" to="/admin/processors">
                    View All Processors
                </Link>
            </Card.Footer>
        </Card>
    );
}

export default ProcessorsCard;
