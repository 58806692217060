export abstract class EntityProperty<U> {
    confidences: number[];
    isList: boolean = false;
    valueType: "string" | "number" | "date" | "object";

    constructor(confidences?: number[]) {
        this.confidences = confidences;
    }

    abstract display(): string;
    abstract value(): U;

    getConfidence(): number {
        return this.confidences[0];
    }
}
