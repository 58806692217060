import { Card, ListGroup } from "react-bootstrap";
import { ignoreDragEvent } from "../../../../components/dashboard/Dashboard";
import { useGetProjectsQuery } from "../../../../store/api/kinesense";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import ProjectListItem from "./ProjectListItem";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddProjectModal from "./AddProjectModal";
import NiceModal from "@ebay/nice-modal-react";

function ProjectList() {
    const { data: allProjects, isSuccess: hasLoadedProjects } = useGetProjectsQuery();

    return (
        <Card as={motion.article} layout>
            <Card.Header as={motion.header} layout>
                <Card.Title as={motion.h5} layout className="m-1">
                    Projects
                </Card.Title>
            </Card.Header>

            <ListGroup className="border-0" variant="flush" as={motion.div} layout {...ignoreDragEvent}>
                <div className="d-flex flex-column h-100 justify-content-between">
                    <div>
                        <ListGroup.Item as={motion.header} layout>
                            <motion.span layout className="m-0 h6">
                                Name
                            </motion.span>
                            <motion.span layout className="m-0 h6">
                                Videos
                            </motion.span>
                            <motion.span layout className="m-0 h6">
                                Created At
                            </motion.span>
                        </ListGroup.Item>

                        <motion.div layout className="items">
                            <ConditionalSpinner size="sm" isLoading={!hasLoadedProjects}>
                                {allProjects?.map((data) => <ProjectListItem key={data.projectId} data={data} />)}
                            </ConditionalSpinner>
                        </motion.div>
                    </div>

                    <motion.button
                        layout
                        className="my-2 button-with-icon align-self-center fs-6"
                        onClick={() => NiceModal.show(AddProjectModal)}
                    >
                        <motion.span
                            layout
                            className="gap-2 d-flex align-items-center text-truncate"
                            title="Create a new project"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <motion.span layout className="text-truncate">
                                New project
                            </motion.span>
                        </motion.span>
                    </motion.button>
                </div>
            </ListGroup>
        </Card>
    );
}

export default ProjectList;
