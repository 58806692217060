import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { Table } from "react-bootstrap";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import { Organisation } from "../../../models/Organisation";
import { DateFormats } from "../../../utilities/dates";

export interface OrganisationDetailsProps {
    organisation: Organisation;
    isLoading: boolean;
}

function OrganisationDetails(props: OrganisationDetailsProps) {
    return (
        <section className="organisation-details">
            <ConditionalSpinner isLoading={props.isLoading}>
                <div className="gap-3 mb-3 d-flex align-items-end">
                    <h5 className="mb-0">{props.organisation?.name}</h5>
                    <code className="gap-1 d-flex align-items-center">
                        <FontAwesomeIcon icon={faHashtag} className="fs-6" />
                        <span className="organisation-id d-block">{props.organisation?.organisationId}</span>
                    </code>
                </div>

                <Table className="details-table ms-2">
                    <tbody className="px-3 border-none">
                        <tr>
                            <th>Country:</th>
                            <td className="border-none">
                                <span>{props.organisation?.country}</span>
                            </td>
                        </tr>
                        <tr>
                            <th>Created At:</th>
                            <td>
                                <span>{dayjs(props.organisation?.createdAt).format(DateFormats.dayMonthYear)}</span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </ConditionalSpinner>
        </section>
    );
}

export default OrganisationDetails;
