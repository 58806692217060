import { faPenToSquare, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AreaBounds } from "cloud-core/spatial/Spatial";
import { SetStateAction, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactCrop, { PercentCrop } from "react-image-crop";
import { useDispatch } from "react-redux";
import useMediaSources from "../../../../hooks/useMediaSources";
import { useView } from "../../../../hooks/useView";
import { EntityImage, FileImageEntityImage } from "../../../../models/viz/Entity";
import { AreaBoundsEntityFilterParameter } from "../../../../models/viz/filters/AreaBoundsEntityFilter";
import { actionCreators } from "../../../../store/analytics/Analytics";
import { GlobalVideoImageSelector } from "../../../../utilities/videoImageSelector/VideoImageSelector";
import { EntityImageDisplay } from "../Shared/EntityImageDisplay";

const DEFAULT_CROP: PercentCrop = {
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
};

export interface AreaBoundsFilterModalProps {
    index: number;
    isVisible: boolean[];
    hideModal: (i: number) => void;
    crop: PercentCrop[];
    setCrop: (_: SetStateAction<PercentCrop[]>) => void;
    previewBgImage: EntityImage | undefined;
    handleBoundsSelected: (i: number) => void;
}

export function AreaBoundsFilterModal(props: AreaBoundsFilterModalProps) {
    const i = props.index;
    return (
        <Modal show={props.isVisible[i]} centered backdrop="static" keyboard={false} onHide={() => props.hideModal(i)}>
            <Modal.Header closeButton>
                <Modal.Title>Set filter area</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="gap-3 d-flex flex-column justify-content-center position-relative"
                style={{ minHeight: "200px" }}
            >
                <ReactCrop
                    style={{ maxWidth: "100%", height: "auto", minHeight: "100px" }}
                    crop={props.crop[i] ?? DEFAULT_CROP}
                    onChange={(_, percentCrop) => {
                        const newCrop = [...props.crop];
                        newCrop[i] = percentCrop;
                        props.setCrop(newCrop);
                    }}
                >
                    <EntityImageDisplay entityImage={props.previewBgImage} mediaId={undefined} />
                </ReactCrop>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit" onClick={() => props.handleBoundsSelected(i)}>
                    Select Area
                </Button>
                <Button variant="secondary" onClick={() => props.hideModal(i)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export interface AreaBoundsFilterProps {
    viewId: string;
    filterId: string;
    useInlineCropSelector: boolean;
}

export function AreaBoundsFilter(props: AreaBoundsFilterProps) {
    const dispatch = useDispatch();
    const { view } = useView(props.viewId);

    const { activeMediaSource: media } = useMediaSources(props.viewId);

    const [isVisible, setIsVisible] = useState<boolean[]>([false]);

    const selectedBounds = (view?.filters?.bounds?.params as AreaBoundsEntityFilterParameter)?.areas ?? [];
    const [crop, setCrop] = useState<PercentCrop[]>(
        selectedBounds.map((b) => ({
            unit: "%",
            x: b[0] * 100,
            y: b[1] * 100,
            width: (b[2] - b[0]) * 100,
            height: (b[3] - b[1]) * 100,
        })),
    );

    function hideModal(boundsIndex: number) {
        const visible = [...isVisible];
        visible[boundsIndex] = false;
        setIsVisible(visible);
    }

    function showModal(boundsIndex: number) {
        const visible = [...isVisible];
        visible[boundsIndex] = true;
        setIsVisible(visible);
    }

    function beginSelection(boundsIndex: number) {
        if (props.useInlineCropSelector) {
            GlobalVideoImageSelector.beginSelection(
                () => (
                    <div className="gap-2 pt-2 pe-2 d-flex">
                        <Button variant="success" onClick={() => completeSelection(boundsIndex)}>
                            Create filter
                        </Button>
                        <Button variant="secondary" onClick={GlobalVideoImageSelector.endSelection}>
                            Cancel
                        </Button>
                    </div>
                ),
                crop[boundsIndex] ?? DEFAULT_CROP,
            );
        } else {
            showModal(boundsIndex);
        }
    }

    function completeSelection(boundsIndex: number) {
        const newCrop = GlobalVideoImageSelector.completeSelection();
        setCrop([...crop.slice(0, boundsIndex), newCrop, ...crop.slice(boundsIndex + 1)]);
        handleBoundsSelected(boundsIndex, newCrop);
    }

    const [previewBgImage, setPreviewBgImage] = useState<undefined | EntityImage>(undefined);

    useEffect(() => {
        if (media?.thumbnails?.[0] == undefined) {
            return;
        }

        const image = new FileImageEntityImage({ fileId: media.thumbnails[0].fileId });

        setPreviewBgImage(image);
    }, [media?.thumbnails]);

    function handleBoundsSelected(boundsIndex: number, newCrop?: PercentCrop) {
        const currentCrop = newCrop ?? crop[boundsIndex];
        const area: AreaBounds = [
            currentCrop.x / 100,
            currentCrop.y / 100,
            (currentCrop.width + currentCrop.x) / 100,
            (currentCrop.height + currentCrop.y) / 100,
        ];

        // Update bounds to match new crop
        const bounds = [...selectedBounds.slice(0, boundsIndex), area, ...selectedBounds.slice(boundsIndex + 1)];
        activateViewFilter(bounds);

        hideModal(boundsIndex);
    }

    const activateViewFilter = (bounds: AreaBounds[]) => {
        dispatch(actionCreators.activateEntityFilter(props.viewId, props.filterId, { areas: bounds }));
    };

    const handleBoundsCleared = (boundsIndex: number) => {
        // Remove bounds and crop for given index
        const bounds = [...selectedBounds.slice(0, boundsIndex), ...selectedBounds.slice(boundsIndex + 1)];
        const newCrop = [...crop.slice(0, boundsIndex), ...crop.slice(boundsIndex + 1)];
        setCrop(newCrop);

        if (bounds.length > 0) {
            activateViewFilter(bounds);
        } else {
            dispatch(actionCreators.deactivateEntityFilter(props.viewId, props.filterId));
        }
    };

    return (
        <div>
            {selectedBounds.map((b, i) => {
                return (
                    <div
                        style={{ marginBottom: "0.3rem", display: "grid", gridTemplateColumns: "1fr auto auto" }}
                        key={i}
                    >
                        {b != undefined && (
                            <svg style={{ display: "inline", marginRight: "1rem" }} height="30px" width="53px">
                                <rect x="0" y="0" width="100%" height="100%" fill="rgba(127, 127, 127, 0.5)" />
                                <rect
                                    x={b[0] * 100 + "%"}
                                    y={b[1] * 100 + "%"}
                                    width={(b[2] - b[0]) * 100 + "%"}
                                    height={(b[3] - b[1]) * 100 + "%"}
                                    fill="rgba(127, 127, 127, 0.5)"
                                />
                            </svg>
                        )}

                        <Button
                            style={{ height: "32px", width: "32px" }}
                            className=""
                            variant="secondary"
                            size="sm"
                            onClick={() => beginSelection(i)}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>

                        <Button
                            style={{ marginLeft: "0.3rem", height: "32px", width: "32px" }}
                            variant="danger"
                            size="sm"
                            onClick={() => handleBoundsCleared(i)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </Button>

                        <AreaBoundsFilterModal
                            index={i}
                            isVisible={isVisible}
                            hideModal={hideModal}
                            crop={crop}
                            setCrop={setCrop}
                            previewBgImage={previewBgImage}
                            handleBoundsSelected={handleBoundsSelected}
                        />
                    </div>
                );
            })}

            <AreaBoundsFilterModal
                index={selectedBounds.length}
                isVisible={isVisible}
                hideModal={hideModal}
                crop={crop}
                setCrop={setCrop}
                previewBgImage={previewBgImage}
                handleBoundsSelected={handleBoundsSelected}
            />
            {selectedBounds.length > 0 ? (
                <Button
                    style={{ width: "53px" }}
                    variant="secondary"
                    size="sm"
                    onClick={() => beginSelection(selectedBounds.length)}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            ) : (
                <Button
                    className=""
                    variant="secondary"
                    size="sm"
                    onClick={() => beginSelection(selectedBounds.length)}
                >
                    <span>Select Area</span>
                </Button>
            )}
        </div>
    );
}
