import { ListGroup } from "react-bootstrap";
import { useTheme } from "../../../hooks/useTheme";
import useToggleUserConfigOption from "./useToggleUserConfigOption";
import UserConfigSwitch from "./UserConfigSwitch";
import useUserConfig from "../../../hooks/useUserConfig";

function AppearanceTab() {
    const { userConfig, updateUserConfig } = useUserConfig();

    // THEME
    const { toggleTheme, isDarkTheme, isUpdatingTheme } = useTheme();

    // CUSTOM CONTEXT MENUS
    const [localShowCustomContextMenus, isUpdatingShowCustomContextMenus, toggleShowCustomContextMenus] =
        useToggleUserConfigOption(
            userConfig.showCustomContextMenus,
            (showCustomContextMenus: boolean) => updateUserConfig({ showCustomContextMenus }).unwrap(),
            "Error toggling custom context menus",
        );

    return (
        <ListGroup className="appearance-tab">
            <UserConfigSwitch
                label="Dark mode"
                title="Enable/Disable dark mode"
                isChecked={isDarkTheme}
                isUpdating={isUpdatingTheme}
                onChange={toggleTheme}
            />

            <UserConfigSwitch
                label="Custom context menus"
                title={"Enable/Disable overrding of context menus used in certain parts of the UI"}
                isChecked={localShowCustomContextMenus}
                isUpdating={isUpdatingShowCustomContextMenus}
                onChange={toggleShowCustomContextMenus}
            />
        </ListGroup>
    );
}

export default AppearanceTab;
