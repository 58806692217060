import { useSelector } from "react-redux";
import { useGetAllMediaQuery, useGetMediaSourceQuery } from "../store/api/kinesense";
import { useView } from "./useView";
import { ApplicationState } from "../store";

export default function useMediaSources(viewId: string, mediaId?: string) {
    const { activeProjectId: projectId } = useSelector((state: ApplicationState) => state.general);
    const { view } = useView(viewId);

    // If media ID isn't given, select the primary media ID of the current view
    mediaId = mediaId ?? (view?.data?.hasAssociatedMedia ? view.data.primaryMediaId : undefined);

    const {
        data: allMediaSources,
        isSuccess: isSuccessAllMediaSources,
        isFetching: isFetchingAllMediaSources,
    } = useGetAllMediaQuery({ projectId }, { skip: projectId === undefined });

    /** Represents whether media sources for the current project are finished loading.
     *
     * Note that this will not always update to false between project changes so make sure to also depend
     * on the project ID itself for state changes. */
    const hasLoadedMediaSources = isSuccessAllMediaSources && !isFetchingAllMediaSources;

    /** Represents whether the media ID has been updated to match the media sources for the current project. */
    const isMediaIdReady =
        hasLoadedMediaSources &&
        mediaId !== undefined &&
        (allMediaSources ?? []).find((m) => m.mediaId == mediaId) !== undefined;

    const {
        data: activeMediaSource,
        isSuccess: isSuccessActiveMedia,
        isFetching: isFetchingActiveMedia,
    } = useGetMediaSourceQuery({ projectId, mediaId }, { skip: !isMediaIdReady });

    /** Represents whether the media source for the provided media ID (or the primary media source for
     * the currently selected view if that wasn't provided) has been loaded. */
    const hasLoadedActiveMedia = isMediaIdReady && isSuccessActiveMedia && !isFetchingActiveMedia;

    return {
        allMediaSources,
        hasLoadedMediaSources,
        activeMediaSource,
        hasLoadedActiveMedia,
        mediaId,
    };
}
