import { Form } from "react-bootstrap";

export interface TotpFormGroupProps {
    totp: string;
    setTotp: (newTotp: React.SetStateAction<string>) => void;
}

function TotpFormGroup(props: TotpFormGroupProps) {
    function handleTotpChanged(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        // Only numbers
        for (const char of event.target.value) {
            if (isNaN(parseInt(char))) {
                return;
            }
        }

        props.setTotp(event.target.value);
    }

    return (
        <Form.Group className="mx-auto text-center" style={{ width: "150pt" }}>
            <Form.Control
                autoFocus
                type="text"
                inputMode="numeric"
                minLength={6}
                name="totp"
                value={props.totp}
                onChange={handleTotpChanged}
                className="mt-4 text-center"
                style={{ fontSize: "14pt", letterSpacing: 4 }}
                required
            />
        </Form.Group>
    );
}

export default TotpFormGroup;
