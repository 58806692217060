import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { DataOperation, VizContext } from "./DataOperation";
import * as AnalyticsStore from "../../../store/analytics/Analytics";
import { KnownAction } from "../../../store/user/User";

export class RefreshDataOperation extends DataOperation {
    constructor() {
        super();
        this.operationId = "refreshData";
        this.displayName = "Refresh Data";
        this.icon = {
            type: "fontawesome",
            icon: faRefresh,
        };
        this.priority = "primary";
        this.context = "global";
        this.category = "general";
    }

    canExecute(context: VizContext) {
        return (
            context.view.data.state == "loaded" ||
            context.view.data.state == "unloaded" ||
            context.view.data.state == "error"
        );
    }

    execute(context: VizContext) {
        context.react.dispatch(
            AnalyticsStore.actionCreators.loadViewData(context.viewId, true) as unknown as KnownAction,
        );
    }
}
