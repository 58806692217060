import { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useExportMediaToIntegrationMutation, useGetAllIntegrationProjectsQuery } from "../../../store/api/kinesense";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import { DateFormats } from "../../../utilities/dates";
import dayjs from "dayjs";
import { VizContext } from "../../../models/viz/operations/DataOperation";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { tryExtractErrorMessage } from "../../../utilities/helpers";

export interface ExportClipToOvercastModalProps {
    context: VizContext;
}

const ExportClipToOvercastModal = NiceModal.create((props: ExportClipToOvercastModalProps) => {
    const { view, projectId } = props.context;

    const baseEntity = view?.selectedEntity ?? view?.filteredEntities[0];

    const media = baseEntity?.sourceObject?.mediaSource;
    const mediaId = media?.mediaId;

    const [clipLink, setClipLink] = useState<string>(undefined);
    const hasExportedClip = clipLink !== undefined;
    const { data: projects } = useGetAllIntegrationProjectsQuery({ integrationId: "overcast" });
    const [exportClip] = useExportMediaToIntegrationMutation();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [integrationProjectId, setIntegrationProjectId] = useState<string | undefined>(undefined);
    const [isExporting, setIsExporting] = useState(false);

    const modal = useModal();
    modal.keepMounted = true;

    const duration = useMemo(() => {
        if (view?.cursor == undefined || view?.secondaryCursor == undefined) {
            return undefined;
        }

        return dayjs.duration(view?.cursor - view?.secondaryCursor);
    }, [view?.cursor, view?.secondaryCursor]);

    useEffect(() => {
        if (projects !== undefined && integrationProjectId === undefined) {
            setIntegrationProjectId(projects[0].projectId);
        }
    }, [projects]);

    async function handleExportClick() {
        setIsExporting(true);

        await exportClip({
            mediaId,
            projectId,
            integrationId: "overcast",
            integrationProjectId,
            folderId: undefined,
            startsAt: view?.secondaryCursor - media.startsAt,
            endsAt: view?.cursor - media.startsAt,
            title,
            description,
        })
            .unwrap()
            .then((res) => {
                setTitle("");
                setDescription("");
                Notifications.notify("Exported to Overcast", "The clip has been exported to Overcast", "info");

                const id = JSON.parse(res)?.integrationClipId;
                if (id !== undefined) {
                    setClipLink(`https://kinesense.overcasthq.com/assets/${id}`);
                }
            })
            .catch((e) => {
                Notifications.notify(
                    "Error exporting clip",
                    `The following error was encountered while attempting to export the clip:
                    ${tryExtractErrorMessage(e)}`,
                );
            })
            .finally(() => {
                setIsExporting(false);
            });
    }

    return hasExportedClip ? (
        <>
            <Modal.Header closeButton>Clip exported successfully</Modal.Header>
            <Modal.Body>
                <p>The clip has been exported to Overcast successfully.</p>
                <p>
                    To view the clip,{" "}
                    <a target="_blank" href={clipLink}>
                        click here
                    </a>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modal.hide}>Continue</Button>
            </Modal.Footer>
        </>
    ) : (
        <>
            <Modal.Header>
                <Modal.Title>
                    <span>Export Selection to Overcast</span>
                    <h5 className="text-secondary">{media?.name}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Project</Form.Label>
                        <Form.Select
                            value={integrationProjectId}
                            onChange={(e) => setIntegrationProjectId(e.target.value)}
                        >
                            {projects?.map((project) => (
                                <option key={project.projectId} value={project.projectId}>
                                    {project.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Folder (Optional)</Form.Label>
                        <Form.Select>
                            <option>/</option>
                        </Form.Select>
                    </Form.Group>
                    <hr className="mt-4" />
                    <div>
                        <div>Start Time</div>
                        <div className="text-secondary">
                            {view?.secondaryCursor !== undefined && new Date(view.secondaryCursor).toISOString()}
                        </div>
                    </div>
                    <div className="mt-2">
                        <div>End Time</div>
                        <div className="text-secondary">
                            {view?.cursor !== undefined && new Date(view?.cursor).toISOString()}
                        </div>
                    </div>
                    <div className="mt-2">
                        <div>Duration</div>
                        <div className="text-secondary">{duration?.format(DateFormats.timeSeconds)}</div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={title == "" || description == "" || isExporting}
                    variant="secondary"
                    onClick={handleExportClick}
                >
                    {isExporting ? "Exporting..." : "Export Clip"}
                </Button>
                <Button onClick={modal.hide}>Cancel</Button>
            </Modal.Footer>{" "}
        </>
    );
});

export default ExportClipToOvercastModal;
