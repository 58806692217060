import dayjs from "dayjs";
import Accordion from "react-bootstrap/Accordion";
import { User as UserData } from "../../../../models/User";
import { DateFormats } from "../../../../utilities/dates";
import Settings from "./settings/Settings";
import { UsersProps } from "./Users";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { capitaliseFirst } from "../../../../utilities/helpers";

export interface UserOwnProps {
    userData: UserData;
    positionInList: string;
    isCurrentUser: boolean;
}
export type UserProps = UserOwnProps & UsersProps;

export default function User(props: UserProps) {
    const user = useSelector((state: ApplicationState) => state.user);
    const hasPrivilegedPermissions = user.permissions.isAdmin || user.permissions.isManager;
    const highestRole = props.userData.roles[0] === "basic" ? "Standard" : capitaliseFirst(props.userData.roles[0]);

    return (
        <Accordion.Item eventKey={props.positionInList}>
            <Accordion.Header>
                <span>{props.positionInList}</span>
                <span>
                    {props.userData.username}
                    {props.isCurrentUser ? " (You)" : ""}
                </span>
                <span>{props.userData.email}</span>
                <span>{highestRole}</span>
            </Accordion.Header>
            <Accordion.Body className="ps-5">
                <section>
                    <p>
                        <strong>Joined at: </strong>
                        <span>{dayjs(props.userData.joinedAt).format(DateFormats.dayMonthYearWithTime)}</span>
                    </p>
                </section>

                {hasPrivilegedPermissions && !props.isCurrentUser && props.userData.roles[0] != "admin" && (
                    <>
                        <hr />
                        <Settings
                            userData={props.userData}
                            organisation={props.organisation}
                            isForCurrentUsersOrganisation={props.isForCurrentUsersOrganisation}
                        />
                    </>
                )}
            </Accordion.Body>
        </Accordion.Item>
    );
}
