import { Middleware } from "redux";

class ListenerAction {
    fn: () => void;
    removeAfterFirstCall = true;
    called = false;

    execute() {
        if (!this.removeAfterFirstCall || (this.removeAfterFirstCall && !this.called)) {
            this.fn();
        }

        this.called = true;
    }
}

export class ActionListener {
    listenerActions = new Map<string, ListenerAction[]>();

    middleware: Middleware = (api) => (next) => (action) => {
        const nextAction = next(action);

        if (this.listenerActions.has(action.type)) {
            const actions = this.listenerActions.get(action.type);
            let actionsAreToBeRemoved = false;

            actions.forEach((la) => {
                la.execute();

                if (la.removeAfterFirstCall) {
                    actionsAreToBeRemoved = true;
                }
            });

            if (actionsAreToBeRemoved) {
                this.listenerActions.set(
                    action.type,
                    actions.filter((a) => !(a.removeAfterFirstCall && a.called)),
                );
            }
        }

        return nextAction;
    };

    ListenForAction(actionType: string, action: () => void, removeAfterFirstCall = true) {
        const listenerAction = new ListenerAction();
        listenerAction.fn = action;
        listenerAction.removeAfterFirstCall = removeAfterFirstCall;

        if (this.listenerActions.has(actionType)) {
            this.listenerActions.get(actionType).push(listenerAction);
        } else {
            this.listenerActions.set(actionType, [listenerAction]);
        }
    }
}

export const Listener = new ActionListener();
