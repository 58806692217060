import { EntityProperty } from "./EntityProperty";

export class ObjectListEntityProperty<T> extends EntityProperty<T[]> {
    staticValue: T[];
    getDisplayFn: (obj: T[]) => string;

    constructor(value: T[], getDisplayFn?: (obj: T[]) => string, confidence?: number[]) {
        super(confidence);
        this.valueType = "object";
        this.isList = true;
        this.staticValue = value;

        if (getDisplayFn) {
            this.getDisplayFn = getDisplayFn;
        } else {
            this.getDisplayFn = (obj: T[]) => this.value().toString();
        }
    }

    display(): string {
        return this.getDisplayFn(this.staticValue);
    }

    value(): T[] {
        return this.staticValue;
    }
}
