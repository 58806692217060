import { Entity } from "../Entity";
import { EntityInformationGenerator } from "../entityInformation/EntityInformationGenerator";

export class FunctionEntityInformationGenerator implements EntityInformationGenerator {
    fn: (entity: Entity) => string;
    displayName: string;

    constructor(fn: (entity: Entity) => string, displayName: string) {
        this.fn = fn;
        this.displayName = displayName;
    }

    generateInformation(entity: Entity): string {
        return this.fn(entity);
    }
}
