import { Link } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import { useAdminGetOrganisationsQuery } from "../../../../store/api/kinesense";
import OrganisationsList from "../organisations/OrganisationsList";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { ignoreDragEvent } from "../../../../components/dashboard/Dashboard";

function OrganisationsCard() {
    const { data: organisations, isLoading } = useAdminGetOrganisationsQuery({});

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5" className="m-1">
                    Organisations
                </Card.Title>
            </Card.Header>

            <Card.Body className="gap-3 py-3 px-4 d-flex flex-column" {...ignoreDragEvent}>
                <ConditionalSpinner isLoading={isLoading}>
                    <Table className="m-0" borderless>
                        <tbody>
                            <tr>
                                <th className="ps-0" style={{ width: 0, whiteSpace: "nowrap" }}>
                                    Total organisations
                                </th>
                                <td>{organisations?.length ?? "-"}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <section>
                        <h6 className="fw-bold">Latest Organisations</h6>
                        <OrganisationsList maxItems={3} />
                    </section>
                </ConditionalSpinner>
            </Card.Body>

            <Card.Footer className="text-center" {...ignoreDragEvent}>
                <Link className="btn btn-primary" to="/admin/organisations">
                    View All Organisations
                </Link>
            </Card.Footer>
        </Card>
    );
}

export default OrganisationsCard;
