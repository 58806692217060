import { Table } from "react-bootstrap";
import BasicAlert from "../../../../components/BasicAlert";
import { useGetExchangeRateUsdToEurQuery } from "../../../../store/api/exchangeRates";

export interface ProcessorTypeCostBreakdownProps {
    costPerHourDollars: number;
}

function ProcessorTypeCostBreakdown(props: ProcessorTypeCostBreakdownProps) {
    const medianMonthLength = 30.437;
    const costPerHour = props.costPerHourDollars;
    const costPerDay = costPerHour * 24;
    const costPerMonth = costPerDay * medianMonthLength;

    const { data: fetchedExchangeRate, isSuccess, isError } = useGetExchangeRateUsdToEurQuery();

    const isExchangeRateLoaded = isSuccess && typeof fetchedExchangeRate === "number";
    const exchangeRateDollarsToEuros = isExchangeRateLoaded ? fetchedExchangeRate : 0;

    function convertToDisplayValue(value: number, convertToEur = false): string {
        value = convertToEur ? value * exchangeRateDollarsToEuros : value;

        return value.toFixed(2);
    }

    return (
        <section>
            <h6 className="fw-bold">Cost Breakdown</h6>

            <Table bordered>
                <thead>
                    <tr>
                        <th aria-hidden></th>
                        <th>Per Hour</th>
                        <th>Per Day</th>
                        <th>Per Month</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Dollars</th>
                        <td>${convertToDisplayValue(costPerHour)}</td>
                        <td>${convertToDisplayValue(costPerDay)}</td>
                        <td>${convertToDisplayValue(costPerMonth)}</td>
                    </tr>
                    <tr>
                        <th>Euros</th>
                        <td>€{convertToDisplayValue(costPerHour, true)}</td>
                        <td>€{convertToDisplayValue(costPerDay, true)}</td>
                        <td>€{convertToDisplayValue(costPerMonth, true)}</td>
                    </tr>
                </tbody>
            </Table>

            {isError && <BasicAlert message="There was an error with fetching the USD -> EUR exchange rate" />}
        </section>
    );
}

export default ProcessorTypeCostBreakdown;
