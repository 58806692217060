import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import useIsMounted from "../hooks/useIsMounted";

export interface BasicAlertControl {
    message: string;
    variant?: Variant;
}

export type BasicAlertProps = BasicAlertControl;

export default function BasicAlert(props: BasicAlertProps) {
    const [isVisible, setIsVisible] = useState(true);

    const variant = props.variant || "danger";

    const onClose = () => setIsVisible(false);

    return (
        <Alert variant={variant} show={isVisible} onClose={onClose} dismissible>
            <p className="mb-0">{props.message}</p>
        </Alert>
    );
}

// Custom hook for displaying basic alerts
export interface UseBasicAlertsResult {
    alerts: JSX.Element[];
    addAlert: (props: BasicAlertControl) => void;
    clearAlerts: () => void;
}

export function useBasicAlerts(): UseBasicAlertsResult {
    const [alerts, setAlerts] = useState<JSX.Element[]>([]);

    const isMounted = useIsMounted();

    function safeSetAlerts(action: React.SetStateAction<JSX.Element[]>) {
        if (isMounted()) {
            setAlerts(action);
        }
    }

    function addAlert(control: BasicAlertControl) {
        safeSetAlerts((prevAlerts) => {
            if (prevAlerts.find((alert) => alert.props.message == control.message)) {
                return [...prevAlerts];
            }

            return [...prevAlerts, <BasicAlert key={`basic-alert-${nanoid()}`} {...control} />];
        });
    }

    function clearAlerts() {
        safeSetAlerts((_) => []);
    }

    return { alerts, addAlert, clearAlerts };
}
