import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Container, ListGroup, Nav, Navbar, OverlayTrigger, Popover, Toast } from "react-bootstrap";
import { ApplicationState } from "../store";
import * as UserStore from "../store/user/User";
import { useTheme } from "../hooks/useTheme";
import "./NavMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGrip,
    faHatWizard,
    faHome,
    faList,
    faPlay,
    faTags,
    faUser,
    faWrench,
} from "@fortawesome/free-solid-svg-icons";
import SpinnerButton from "../components/SpinnerButton";
import useUserConfig from "../hooks/useUserConfig";

/** Amount of time that the environment badge will disappear for after being clicked */
const ENV_BADGE_DISAPPEAR_MS = 1800000;

function NavMenu() {
    const dispatch = useDispatch();
    const { user } = useSelector((state: ApplicationState) => state);
    const isUpdatingAuth = user?.loginIssueParams?.isUpdatingAuth ?? false;
    const { userConfig } = useUserConfig();

    const { pathname } = useLocation();

    const { isDarkTheme } = useTheme();
    const kine_logo = isDarkTheme ? "kine_logo_dark.svg" : "kine_logo.svg";

    // Control overlay state so that it can be closed whenever a link is clicked
    const [showOverlay, setShowOverlay] = useState(false);
    const hideOverlay = () => setShowOverlay(false);

    // Control environment badge so that it can be hidden momentarily
    const [showEnvBadge, setShowEnvBadge] = useState(true);
    useEffect(() => {
        if (showEnvBadge) {
            return;
        }

        const timeout = setTimeout(() => setShowEnvBadge(true), ENV_BADGE_DISAPPEAR_MS);
        return () => {
            clearTimeout(timeout);
        };
    }, [showEnvBadge]);

    function handleOnClickSignOut(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        dispatch(UserStore.actionCreators.deauthoriseUser());
    }

    function getActiveClassname(route: string) {
        return pathname.startsWith(route) ? "active" : "";
    }

    function renderEnvironmentBadge() {
        const env = process.env.REACT_APP_STAGE || "unknown";

        if (env === "production") {
            return null;
        }

        let colourClassName = "";
        switch (env) {
            case "unknown":
                colourClassName = "text-danger";
                break;
            case "staging":
                colourClassName = "text-warning";
                break;
        }

        return (
            <Toast show={showEnvBadge} className="env-badge" onClick={() => setShowEnvBadge(false)}>
                <Toast.Body className={colourClassName}>
                    <small>{env.toUpperCase()}</small>
                </Toast.Body>
            </Toast>
        );
    }

    return (
        <header className="mb-5">
            <Navbar className="shadow-sm bg-body-tertiary" expand="lg" sticky="top">
                <Container>
                    <Navbar.Brand as={Link} to="/" className="py-2">
                        <img width="200" src={kine_logo} />
                    </Navbar.Brand>

                    <Navbar.Collapse id="navbarCollapse">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" className={pathname == "/" ? "active" : ""}>
                                <FontAwesomeIcon icon={faHome} />
                                Home
                            </Nav.Link>
                            <Nav.Link
                                title="Import Wizard"
                                as={Link}
                                to="/import-wizard"
                                className={getActiveClassname("/import-wizard")}
                            >
                                <FontAwesomeIcon icon={faHatWizard} /> Import
                            </Nav.Link>
                            <Nav.Link as={Link} to="/review" className={getActiveClassname("/review")}>
                                <FontAwesomeIcon icon={faPlay} />
                                Review
                            </Nav.Link>
                            <Nav.Link as={Link} to="/tags" className={getActiveClassname("/tags")}>
                                <FontAwesomeIcon icon={faTags} />
                                Tags
                            </Nav.Link>
                            <Nav.Link as={Link} to="/grid" className={getActiveClassname("/grid")}>
                                <FontAwesomeIcon icon={faGrip} />
                                Grid
                            </Nav.Link>
                            {userConfig.showMockDem && (
                                <Nav.Link as={Link} to="/mockdem" className={getActiveClassname("/mockdem")}>
                                    <FontAwesomeIcon icon={faList} />
                                    Mock DEM
                                </Nav.Link>
                            )}
                            {user.permissions.isAdmin && (
                                <Nav.Link as={Link} to="/admin" className={getActiveClassname("/admin")}>
                                    <FontAwesomeIcon icon={faWrench} />
                                    Admin
                                </Nav.Link>
                            )}
                        </Nav>
                    </Navbar.Collapse>

                    <div className="gap-3 d-flex">
                        {renderEnvironmentBadge()}

                        <OverlayTrigger
                            show={showOverlay}
                            onToggle={setShowOverlay}
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={
                                <Popover className="text-center shadow-sm navbar-popover">
                                    <Popover.Header>{user.fullName}</Popover.Header>
                                    <Popover.Body>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item
                                                action
                                                as={Link}
                                                to="/settings"
                                                onClick={hideOverlay}
                                                className={getActiveClassname("/settings")}
                                            >
                                                Settings
                                            </ListGroup.Item>
                                            <ListGroup.Item
                                                action
                                                as={Link}
                                                to="/organisation"
                                                onClick={hideOverlay}
                                                className={getActiveClassname("/organisation")}
                                            >
                                                Organisation
                                            </ListGroup.Item>
                                        </ListGroup>

                                        <SpinnerButton
                                            className="mt-3"
                                            buttonProps={{
                                                onClick: handleOnClickSignOut,
                                                variant: "outline-secondary",
                                            }}
                                            label="Sign Out"
                                            isLoading={isUpdatingAuth}
                                        />
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Button style={{ maxWidth: "max-content" }} type="button" variant="secondary">
                                <FontAwesomeIcon icon={faUser} />
                                {user.username}
                            </Button>
                        </OverlayTrigger>
                        <Navbar.Toggle aria-controls="navbarCollapse" className="mr-2" />
                    </div>
                </Container>
            </Navbar>
        </header>
    );
}

export default NavMenu;
