import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./style/style.scss";
import { initialSetup } from "./utilities/setup";
import NiceModal from "@ebay/nice-modal-react";

const { history, store } = initialSetup();
const root = createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <ReduxRouter history={history}>
            <NiceModal.Provider>
                <App />
            </NiceModal.Provider>
        </ReduxRouter>
    </Provider>,
);

registerServiceWorker();
