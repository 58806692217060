import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { EntityProperty } from "./EntityProperty";

export class DateEntityProperty extends EntityProperty<Date> {
    staticValue: Date;

    constructor(value: Date, confidence?: number[]) {
        super(confidence);
        this.valueType = "date";
        this.isList = false;
        this.staticValue = value;
    }

    display(): string {
        return dayjs(this.staticValue).format(DateFormats.yearMonthDayWithTimeSeconds);
    }

    value(): Date {
        return this.staticValue;
    }
}
