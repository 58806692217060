import { faAngleDoubleLeft, faAngleDoubleRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import Filters from "./Filters";

export interface VideoOptionsSidebarProps {
    viewId: string;
    mediaId: string;
    isExpanded: boolean;
    setIsExpanded: (update: React.SetStateAction<boolean>) => void;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
}

type AccordionKey = "" | "filters";

function VideoOptionsSidebar(props: VideoOptionsSidebarProps) {
    const [activeKey, setActiveKey] = useState<AccordionKey>("");

    function expandSidebar() {
        props.setIsExpanded(true);
    }

    function collapseSidebar() {
        props.setIsExpanded(false);
        setActiveKey("");
    }

    function toggleSidebar() {
        props.isExpanded ? collapseSidebar() : expandSidebar();
    }

    function toggleActiveKey(key: AccordionKey) {
        activeKey == key ? setActiveKey("") : setActiveKey(key);

        if (!props.isExpanded) {
            expandSidebar();
        }
    }

    return (
        <div className="gap-4 video-sidebar video-sidebar-options d-flex flex-column justify-content-between position-relative">
            <Accordion className="video-sidebar-content" flush activeKey={activeKey}>
                <Accordion.Item eventKey="filters">
                    <Accordion.Header title="Filters" as="section" onClick={() => toggleActiveKey("filters")}>
                        <div className="video-sidebar-icon">
                            <FontAwesomeIcon icon={faFilter} />
                        </div>
                        <span className="align-self-start">Filters</span>
                    </Accordion.Header>

                    <Accordion.Body className="py-1">
                        <Filters viewId={props.viewId} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <button className="video-sidebar-expand-button" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={props.isExpanded ? faAngleDoubleLeft : faAngleDoubleRight} />
            </button>
        </div>
    );
}

export default VideoOptionsSidebar;
