import { capitaliseFirst } from "../../../utilities/helpers";
import { EntityProperty } from "./EntityProperty";

export class StringEntityProperty extends EntityProperty<string> {
    staticValue: string;
    capitaliseFirstLetter: boolean = true;

    constructor(value: string, capitalise = true, confidence?: number) {
        super([confidence]);
        this.valueType = "string";
        this.isList = false;
        this.staticValue = value;
        this.capitaliseFirstLetter = capitalise;
    }

    display(): string {
        return this.capitaliseFirstLetter ? capitaliseFirst(this.staticValue) : this.staticValue;
    }

    value(): string {
        return this.staticValue;
    }

    withCapitaliseFirstLetter(capitaliseFirstLetter: boolean): StringEntityProperty {
        this.capitaliseFirstLetter = capitaliseFirstLetter;
        return this;
    }
}
