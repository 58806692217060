import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { DateTimeInput } from "../../../components/DateTimeInput";
import { useView } from "../../../hooks/useView";
import { SortParameterType } from "../../../models/viz/sorters/EntitySorter";
import "../Viz.scss";
import { VizContainerProps } from "./VizContainer";

export type VizSortersProps = VizContainerProps;

export function VizSorters(props: VizSortersProps) {
    const { view } = useView(props.viewId);
    const [sortOptions, setSortOptions] = useState<{ value: string; label: string }[]>([]);
    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | undefined>(undefined);

    useEffect(() => {
        if (view?.currentDataDescription === undefined) {
            return;
        }

        const options = view?.currentDataDescription.sorters.map((s) => ({ value: s.id, label: s.displayName })) ?? [];
        setSortOptions(options);
        setSelectedOption(options.find((s) => s.value == view?.sorter?.sorter.id));
    }, [view?.currentDataDescription?.sorters]);

    const dispatch = useDispatch();
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [sortParam, setSortParam] = useState<undefined | SortParameterType>(undefined);

    const onOptionChange = (option: { value: string; label: string }) => {
        if (view?.sorter?.sorter.id != option.value) {
            setSelectedOption(option);
            dispatch({
                type: "analytics/setActiveSorter",
                payload: {
                    viewId: props.viewId,
                    sorter: {
                        sorter: view?.currentDataDescription.sorters.find((s) => s.id == option.value),
                        order: "asc",
                        sortParameter: sortParam,
                    },
                },
            });
        }
    };

    const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const order = event.target.value as "asc" | "desc";

        if (view?.sorter != undefined && event.target.value != view?.sorter.order) {
            setSortOrder(order);
            dispatch({
                type: "analytics/setActiveSorter",
                payload: {
                    viewId: props.viewId,
                    sorter: { sorter: view?.sorter.sorter, order },
                },
            });
        }
    };

    const onParamChange = (value: string | number | Date) => {
        setSortParam(value);
    };

    const renderParam = () => {
        switch (view?.sorter?.sorter.sortParameter.type) {
            case "date":
                return (
                    <DateTimeInput
                        defaultDate={view?.sorter?.sorter.sortParameter.defaultValue}
                        onDateChanged={onParamChange}
                    />
                );
            case "number":
                return <VizSorterNumberParam onChange={onParamChange} />;
            case "string":
                return <VizSorterStringParam onChange={onParamChange} />;
        }
    };

    return (
        <div className="viz-sorters viz-panel">
            <h1>Sort</h1>
            <div>
                {selectedOption != undefined && (
                    <Select
                        classNamePrefix="react-select"
                        options={sortOptions}
                        onChange={onOptionChange}
                        value={selectedOption}
                    />
                )}

                {view?.sorter?.sorter.sortParameter != undefined && renderParam()}

                <Form className="sortDirection">
                    <Form.Check
                        inline
                        checked={sortOrder == "asc"}
                        disabled={view?.sorter == undefined}
                        type="radio"
                        label="Ascending"
                        name="sortDir"
                        value="asc"
                        onChange={onRadioChange}
                    />
                    <Form.Check
                        inline
                        disabled={view?.sorter == undefined}
                        checked={sortOrder == "desc"}
                        type="radio"
                        label="Descending"
                        name="sortDir"
                        value="desc"
                        onChange={onRadioChange}
                    />
                </Form>
            </div>
        </div>
    );
}

function VizSorterStringParam(props: { onChange: (value: string) => void }) {
    const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value == undefined || value.length == 0) {
            return;
        }

        props.onChange(value);
    };

    return <input type="text" onChange={onChanged} />;
}

function VizSorterNumberParam(props: { onChange: (value: number) => void }) {
    const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value == undefined || value.length == 0) {
            return;
        }

        const number = Number(value);
        if (isNaN(number)) {
            return;
        }

        props.onChange(number);
    };

    return <input type="number" onChange={onChanged} />;
}
