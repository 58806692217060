import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RefreshButton.scss";

export interface RefreshButtonsProps {
    callback: () => void;
    isDisabled: boolean;
}

function RefreshButton(props: RefreshButtonsProps) {
    return (
        <button className="btn refresh-button" onClick={props.callback} disabled={props.isDisabled}>
            <FontAwesomeIcon icon={faRefresh} />
        </button>
    );
}

export default RefreshButton;
