import produce from "immer";
import { AppThunkAction } from "..";

export interface GeneralState {
    activeProjectId: string | undefined;
}

export interface SetActiveProjectAction {
    type: "general/setActiveProject";
    payload: string;
}

export type KnownAction = SetActiveProjectAction;

export const actionCreators = {
    setActiveProject:
        (project: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({ type: "general/setActiveProject", payload: project });
        },
};

const initialState: GeneralState = {
    activeProjectId: undefined,
};

export const reducer = (state: GeneralState | undefined, action: KnownAction): GeneralState => {
    if (state === undefined) {
        return initialState;
    }

    return produce(state, (draft) => {
        switch (action.type) {
            case "general/setActiveProject":
                draft.activeProjectId = action.payload;
                break;
        }
    });
};
