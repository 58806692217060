import { AreaBounds } from "cloud-core/spatial/Spatial";
import { assertBoundsValid, isBoundsElementsValid } from "./helpers";
import { Milliseconds } from "./numerics";
import Bottleneck from "bottleneck";

class ThumbnailExtractor {
    file: File;
    public videoElement: HTMLVideoElement;
    static thumbnailExtractors: Map<string, { extractor: ThumbnailExtractor; pool: Bottleneck }> = new Map<
        string,
        { extractor: ThumbnailExtractor; pool: Bottleneck }
    >();

    constructor(videoUrl: string) {
        this.videoElement = document.createElement("video");
        this.videoElement.src = videoUrl;
        this.videoElement.muted = true;
        this.videoElement.crossOrigin = "Anonymous";
    }

    private async ensureVideoLoaded() {
        await this.videoElement.play(); // Waits until video is loaded and playable
        this.videoElement.pause();
    }

    private convertBounds(bounds: AreaBounds): AreaBounds {
        assertBoundsValid(bounds);
        if (!isBoundsElementsValid(bounds)) {
            console.error(`The given AreaBounds object is not ordered correctly: ${bounds}`);
        }
        const [x1, y1, x2, y2] = bounds;

        return [
            x1 * this.videoElement.videoWidth,
            y1 * this.videoElement.videoHeight,
            x2 * this.videoElement.videoWidth,
            y2 * this.videoElement.videoHeight,
        ];
    }

    public static async getThumbnail(url: string, bounds: AreaBounds, timestamp: Milliseconds): Promise<string> {
        if (!ThumbnailExtractor.thumbnailExtractors.has(url)) {
            const pool = new Bottleneck({ maxConcurrent: 1 });
            ThumbnailExtractor.thumbnailExtractors.set(url, { extractor: new ThumbnailExtractor(url), pool: pool });
        }

        const { extractor, pool } = ThumbnailExtractor.thumbnailExtractors.get(url);

        return pool.schedule(() => extractor.getRegion(bounds, timestamp));
    }

    public async getThumbnailDataUrl(timestamp: Milliseconds) {
        await this.ensureVideoLoaded();

        this.videoElement.currentTime = timestamp / 1000;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = this.videoElement.videoWidth;
        canvas.height = this.videoElement.videoHeight;

        ctx.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL("image/png");
    }

    public async drawThumbnailOnCanvas(canvas: HTMLCanvasElement, timestamp: Milliseconds) {
        await this.ensureVideoLoaded();

        this.videoElement.currentTime = timestamp / 1000;

        canvas.height = (this.videoElement.videoHeight / this.videoElement.videoWidth) * canvas.width;

        canvas.getContext("2d").drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
    }

    public async getRegion(bounds: AreaBounds, timestamp: Milliseconds): Promise<string> {
        await this.ensureVideoLoaded();
        const seekPromise = new Promise<void>((resolve) => {
            this.videoElement.onseeked = () => {
                resolve();
            };
        });
        this.videoElement.currentTime = timestamp / 1000;
        await seekPromise;

        const fullImageCanvas = document.createElement("canvas");
        fullImageCanvas.width = this.videoElement.videoWidth;
        fullImageCanvas.height = (this.videoElement.videoHeight / this.videoElement.videoWidth) * fullImageCanvas.width;
        fullImageCanvas
            .getContext("2d")
            .drawImage(this.videoElement, 0, 0, fullImageCanvas.width, fullImageCanvas.height);

        const [x1, y1, x2, y2] = this.convertBounds(bounds);

        const croppedCanvas = document.createElement("canvas");
        croppedCanvas.width = x2 - x1;
        croppedCanvas.height = y2 - y1;

        croppedCanvas.getContext("2d").drawImage(fullImageCanvas, x1, y1, x2 - x1, y2 - y1, 0, 0, x2 - x1, y2 - y1);

        return croppedCanvas.toDataURL("image/png");
    }
}

export default ThumbnailExtractor;
