import { MutableRefObject, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OverlaySpinner from "../../components/OverlaySpinner";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user/User";
import NewPasswordInput, { PasswordChangedEventArgs } from "./components/NewPasswordInput";

export interface NewPasswordRequiredProps {
    onSubmitCallbackRef: MutableRefObject<() => void>;
}

function NewPasswordRequired(props: NewPasswordRequiredProps) {
    const dispatch = useDispatch();
    const isFetching = useSelector((state: ApplicationState) => state.user?.loginIssueParams?.isUpdatingAuth) ?? false;

    const [newPassword, setNewPassword] = useState("");
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

    function onNewPasswordChanged(data: PasswordChangedEventArgs) {
        setNewPassword(data.password);
        setIsNewPasswordValid(data.isValid);
    }

    if (props.onSubmitCallbackRef?.current) {
        props.onSubmitCallbackRef.current = () =>
            dispatch(UserStore.actionCreators.completeNewPasswordChallenge(newPassword));
    }

    return (
        <div className="gap-2 mt-2 d-flex flex-column">
            <div>You must set a new password before continuing</div>

            <NewPasswordInput passwordChanged={onNewPasswordChanged} />

            <div className="mt-1 text-center">
                <Button type="submit" disabled={!isNewPasswordValid || isFetching} className="position-relative">
                    <OverlaySpinner isLoading={isFetching} />
                    Confirm Password
                </Button>
            </div>
        </div>
    );
}

export default NewPasswordRequired;
