import { nanoid } from "@reduxjs/toolkit";
import { produce } from "immer";
import { WritableDraft } from "immer/dist/internal";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import { Entity } from "../../models/viz/Entity";
import { DataDescription } from "../../models/viz/dataDescriptions/DataDescription";
import { EmptyDataDescription } from "../../models/viz/dataDescriptions/EmptyDataDescription.1";
import { DataSource, DataSourceState } from "../../models/viz/dataSources/DataSource";
import { FragmentGroupDataSource } from "../../models/viz/dataSources/FragmentGroupDataSource";
import { FragmentGroupWithMedia } from "../../models/viz/entityAdapters/FragmentGroupEntityAdapter";
import { EntityFilter } from "../../models/viz/filters/EntityFilter";
import { EntitySorter, SortParameter } from "../../models/viz/sorters/EntitySorter";

export type AnalyticsRun = { projectId: string; mediaId: string; runId: number };

export type PlayState = "playing" | "paused";
export type AudioState = "unmuted" | "muted";

export enum AnalyticsStateViewEvent {
    PlayState,
    AudioState,
    CursorPosition,
    SecondaryCursorPosition,
    Other,
}

export type ActiveSorter = {
    sorter: EntitySorter;
    order: "asc" | "desc";
    sortParameter: undefined | SortParameter["defaultValue"];
};

export type ViewRepresentation = "large-icons" | "detail-list" | "map";

export type ViewData = { source: DataSource } & DataSourceState &
    (
        | {
              hasAssociatedMedia: true;
              primaryMediaId: string;
              mediaIds: string[];
          }
        | {
              hasAssociatedMedia: false;
          }
    );

export interface AnalyticsStateView {
    mediaStates: {
        play: PlayState;
        audio: AudioState;
    };
    meta: {
        updateSource: string;
        event: AnalyticsStateViewEvent;
    };
    activeFilterId: string | undefined;

    viewId: string;
    projectId: string;
    displayName: string;
    data: ViewData;
    currentDataDescription: DataDescription | undefined;
    dataDescriptions: DataDescription[];
    filters: { [key: string]: { filter: EntityFilter; isActive: boolean; params: unknown } };
    sorter: ActiveSorter;
    selectedEntity: Entity | undefined;
    selectedEntities: Entity[];
    cursor: number | undefined;
    secondaryCursor: number | undefined;
    entities: Entity[];
    filteredEntities: Entity[];
    representation: ViewRepresentation;
    allowVideoStretching: boolean;
}

export interface AnalyticsState {
    primaryViewId: string | undefined;
    views: { [key: string]: AnalyticsStateView };
    viewCount: number;
}

// ACTIONS
export interface AddAnalyticsViewAction {
    type: "analytics/addView";
    payload: {
        viewId: string;
        projectId: string;
        displayName: string;
        source: DataSource;
        setAsPrimary: boolean;
    };
}

export interface SetAnalyticsPrimaryViewIdAction {
    type: "analytics/setAnalyticsPrimaryViewId";
    payload: {
        viewId: string;
    };
}

export interface AddAnalyticsViewEntitiesAction {
    type: "analytics/addViewEntities";
    payload: {
        viewId: string;
        entities: Entity[];
        filteredEntities: Entity[];
    };
}

export interface ClearAnalyticsViewEntitiesAction {
    type: "analytics/clearViewEntities";
    payload: {
        viewId: string;
    };
}

export interface SetSelectedEntityAction {
    type: "analytics/setSelectedEntity";
    payload: {
        viewId: string;
        entity: Entity;
    };
}

export interface SetSelectedEntitiesAction {
    type: "analytics/setSelectedEntities";
    payload: {
        viewId: string;
        entities: Entity[];
    };
}

export interface SetViewDataStateAction {
    type: "analytics/setViewDataState";
    payload: {
        viewId: string;
        dataDescriptions?: DataDescription[];
    } & DataSourceState;
}

export interface SetViewDataDescriptionAction {
    type: "analytics/setViewDataDescription";
    payload: {
        viewId: string;
        dataDescription: DataDescription;
    };
}

export interface ActivateViewFilterAction {
    type: "analytics/activateViewFilter";
    payload: {
        viewId: string;
        filterId: string;
        parameter: unknown;
    };
}

export interface SetViewRepresentationAction {
    type: "analytics/setViewRepresentation";
    payload: {
        viewId: string;
        representation: ViewRepresentation;
    };
}

export interface DeactivateViewFilterAction {
    type: "analytics/deactivateViewFilter";
    payload: {
        viewId: string;
        filterId: string;
    };
}

export interface SetViewCursorPositionAction {
    type: "analytics/setViewCursor";
    payload: {
        viewId: string;
        cursorPosition: number;
        updateSource: string;
    };
}

export interface SetSecondaryViewCursorPositionAction {
    type: "analytics/setSecondaryViewCursor";
    payload: {
        viewId: string;
        cursorPosition: number;
        updateSource: string;
    };
}

export interface SetViewPlayStateAction {
    type: "analytics/setViewPlayState";
    payload: {
        viewId: string;
        playState: PlayState;
        updateSource: string;
    };
}

export interface SetViewAudioStateAction {
    type: "analytics/setViewAudioState";
    payload: {
        viewId: string;
        audioState: AudioState;
        updateSource: string;
    };
}

export interface SetActiveFilterIdAction {
    type: "analytics/setActiveFilterId";
    payload: {
        viewId: string;
        activeFilterId: string | undefined;
    };
}

export interface SetActiveSorterAction {
    type: "analytics/setActiveSorter";
    payload: {
        viewId: string;
        sorter: ActiveSorter | undefined;
    };
}

export interface SetAllowVideoStretchingAction {
    type: "analytics/setAllowVideoStretching";
    payload: {
        viewId: string;
        allowVideoStretching: boolean;
    };
}

type KnownActions =
    | AddAnalyticsViewAction
    | SetAnalyticsPrimaryViewIdAction
    | AddAnalyticsViewEntitiesAction
    | SetViewDataDescriptionAction
    | SetViewRepresentationAction
    | ClearAnalyticsViewEntitiesAction
    | SetViewDataStateAction
    | SetSelectedEntityAction
    | ActivateViewFilterAction
    | DeactivateViewFilterAction
    | SetSelectedEntitiesAction
    | SetViewCursorPositionAction
    | SetSecondaryViewCursorPositionAction
    | SetViewPlayStateAction
    | SetViewAudioStateAction
    | SetActiveFilterIdAction
    | SetActiveSorterAction
    | SetAllowVideoStretchingAction;

export type KnownAction = KnownActions & { meta?: unknown };

// THUNKS
function addView(
    source: DataSource,
    setAsPrimary: boolean,
    displayName: string,
    projectId: string,
    extraOpts?: {
        viewId?: string;
        forceReplace?: boolean;
    },
): AppThunkAction<KnownAction> {
    return async (dispatch, getState) => {
        const { viewId, forceReplace } = extraOpts ?? {};
        if (viewId != undefined && (getState().analytics.views[viewId] != undefined || !forceReplace)) {
            return;
        }

        dispatch({
            type: "analytics/addView",
            payload: {
                viewId: viewId ?? nanoid(6),
                projectId,
                displayName,
                source,
                setAsPrimary,
            },
        } as AddAnalyticsViewAction);
    };
}

function setPrimaryView(viewId?: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();
        const views = state?.analytics.views;
        const allViewIds = Object.keys(views);

        if (
            allViewIds.length == 0 ||
            (viewId != undefined && !allViewIds.includes(viewId)) ||
            viewId == state?.analytics?.primaryViewId
        ) {
            return;
        }

        dispatch({
            type: "analytics/setAnalyticsPrimaryViewId",
            payload: {
                viewId: viewId ?? allViewIds[0],
            },
        } as SetAnalyticsPrimaryViewIdAction);
    };
}

function loadViewData(viewId: string, reload = false): AppThunkAction<KnownAction> {
    return async (dispatch, getState) => {
        const state = getState();

        if (!state?.analytics?.views || !state.analytics.views[viewId]) {
            return;
        }

        const view = state.analytics.views[viewId];

        if (view.data.state == "loading") {
            return;
        }

        if (!reload && view.data.state == "loaded") {
            return;
        }

        dispatch({
            type: "analytics/setViewDataState",
            payload: {
                viewId: viewId,
                state: "loading",
            },
        } as SetViewDataStateAction);

        const result = await view.data.source.load(reload);

        if (result.state == "error") {
            dispatch({
                type: "analytics/setViewDataState",
                payload: {
                    viewId: viewId,
                    state: result.state,
                    errorMessage: result.errorMessage,
                },
            } as SetViewDataStateAction);
        } else if (result.state == "loaded") {
            dispatch({
                type: "analytics/setViewDataState",
                payload: {
                    viewId: viewId,
                    state: result.state,
                    dataDescriptions: view.data.source.getDataDescriptions(),
                },
            } as SetViewDataStateAction);
        }
    };
}

function setViewDataDescription(viewId: string, dataDescription: DataDescription): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state?.analytics?.views || !state.analytics.views[viewId]) {
            return;
        }

        if (state.analytics.views[viewId].dataDescriptions.includes(dataDescription) == false) {
            return;
        }

        if (state.analytics.views[viewId].currentDataDescription == dataDescription) {
            return;
        }

        dispatch({
            type: "analytics/setViewDataDescription",
            payload: {
                viewId,
                dataDescription,
            },
        });
    };
}

function filterEntities(
    entities: Entity[],
    filters: { filter: EntityFilter; isActive: boolean; params: unknown }[],
    sorter: ActiveSorter | undefined,
): Entity[] {
    let filteredEntities = entities;

    for (const { filter, params } of filters.filter((f) => f.isActive)) {
        filteredEntities = filter.predicate(filteredEntities, params);
    }

    if (sorter !== undefined) {
        filteredEntities.sort(sorter.sorter.getCompareFn(sorter.sortParameter));

        if (sorter.order == "desc") {
            filteredEntities.reverse();
        }
    }

    return filteredEntities;
}

function createEntities(
    viewId: string,
    data: FragmentGroupWithMedia[],
): AppThunkAction<AddAnalyticsViewEntitiesAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics || !state.analytics.views) {
            return;
        }

        const view = state.analytics.views[viewId];

        if (view == undefined || view.entities.length > 0) {
            return;
        }

        const dataDescription = state.analytics.views[viewId].currentDataDescription;

        const entities = data
            .map((row) => dataDescription.entityAdapter.convertToEntity(row))
            .filter((e) => e != undefined) as Entity[];

        const filteredEntities = filterEntities(entities, Object.values(view.filters), view.sorter);

        dispatch({
            type: "analytics/addViewEntities",
            payload: {
                viewId,
                entities,
                filteredEntities,
            },
        });
    };
}

function clearEntities(viewId: string): AppThunkAction<ClearAnalyticsViewEntitiesAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics || !state.analytics.views) {
            return;
        }

        dispatch({
            type: "analytics/clearViewEntities",
            payload: {
                viewId,
            },
        });
    };
}

function setSelectedEntity(viewId: string, entity: Entity): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics || !state.analytics.views) {
            return;
        }

        dispatch({
            type: "analytics/setSelectedEntity",
            payload: {
                viewId: viewId,
                entity: entity,
            },
        });
    };
}

function setSelectedEntities(viewId: string, entities: Entity[]): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics || !state.analytics.views) {
            return;
        }

        dispatch({
            type: "analytics/setSelectedEntities",
            payload: {
                viewId: viewId,
                entities: entities,
            },
        });
    };
}

function setActiveFilterId(viewId: string, activeFilterId: string | undefined): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state?.analytics?.views || !state.analytics.views[viewId]) {
            return;
        }

        const dispatchAction = {
            type: "analytics/setActiveFilterId",
            payload: {
                viewId,
                activeFilterId,
            },
        };

        dispatch(dispatchAction as SetActiveFilterIdAction);
    };
}

function activateEntityFilter(
    viewId: string,
    filterId: string,
    parameter: unknown,
): AppThunkAction<ActivateViewFilterAction> {
    return (dispatch, getState) => {
        const state = getState();
        if (!state?.analytics?.views || !state.analytics.views[viewId]) {
            return;
        }

        dispatch({
            type: "analytics/activateViewFilter",
            payload: {
                viewId,
                filterId,
                parameter,
            },
        });
    };
}

function deactivateEntityFilter(viewId: string, filterId: string): AppThunkAction<DeactivateViewFilterAction> {
    return (dispatch, getState) => {
        const state = getState();
        if (!state?.analytics?.views || !state.analytics.views[viewId]) {
            return;
        }

        dispatch({
            type: "analytics/deactivateViewFilter",
            payload: {
                viewId,
                filterId,
            },
        });
    };
}

function setViewPlayState(viewId: string, playState: PlayState, updateSource: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics || !state.analytics.views || !state.analytics.views[viewId]) {
            return;
        }

        dispatch({
            type: "analytics/setViewPlayState",
            payload: {
                viewId: viewId,
                playState: playState,
                updateSource: updateSource,
            },
        } as SetViewPlayStateAction);
    };
}

function setViewAudioState(viewId: string, audioState: AudioState, updateSource: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics || !state.analytics.views || !state.analytics.views[viewId]) {
            return;
        }

        dispatch({
            type: "analytics/setViewAudioState",
            payload: {
                viewId: viewId,
                audioState: audioState,
                updateSource: updateSource,
            },
        } as SetViewAudioStateAction);
    };
}

function setCursorPosition(
    viewId: string,
    cursor: "primary" | "secondary",
    position: number | undefined,
    updateSource: string,
): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state || !state.analytics) {
            return;
        }

        const action = cursor == "primary" ? "analytics/setViewCursor" : "analytics/setSecondaryViewCursor";

        dispatch({
            type: action,
            payload: {
                viewId: viewId,
                cursorPosition: position,
                updateSource: updateSource,
            },
        });
    };
}

function enableVideoStretching(viewId: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        dispatch({
            type: "analytics/setAllowVideoStretching",
            payload: {
                viewId,
                allowVideoStretching: true,
            },
        });
    };
}
function disableVideoStretching(viewId: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        dispatch({
            type: "analytics/setAllowVideoStretching",
            payload: {
                viewId,
                allowVideoStretching: false,
            },
        });
    };
}

export const actionCreators = {
    addView,
    loadViewData,
    setPrimaryView,
    createEntities,
    clearEntities,
    setViewDataDescription,
    setSelectedEntity,
    setSelectedEntities,
    setViewPlayState,
    setViewAudioState,
    setCursorPosition,
    setActiveFilterId,
    activateEntityFilter,
    deactivateEntityFilter,
    enableVideoStretching,
    disableVideoStretching,
};

const unloadedState: AnalyticsState = {
    primaryViewId: undefined,
    views: {},
    viewCount: 0,
} as const;

export const reducer: Reducer<AnalyticsState> = (
    state: AnalyticsState | undefined,
    incomingAction: Action,
): AnalyticsState => {
    if (state === undefined) {
        return { ...unloadedState };
    }

    const action = incomingAction as KnownAction;

    return produce(state, (draft) => {
        switch (action.type) {
            case "analytics/addView":
                addViewToDraft(draft, action);
                if (action.payload.setAsPrimary) {
                    draft.primaryViewId = action.payload.viewId;
                }
                break;
            case "analytics/setAnalyticsPrimaryViewId":
                draft.primaryViewId = action.payload.viewId;
                if (draft.views[action.payload.viewId].entities.length == 0) {
                    updateEntitiesInDraft(draft, action);
                }
                break;
            case "analytics/addViewEntities":
                draft.views[action.payload.viewId].entities = action.payload.entities;
                draft.views[action.payload.viewId].filteredEntities = action.payload.filteredEntities;
                draft.views[action.payload.viewId].selectedEntity = action.payload.filteredEntities[0];
                draft.views[action.payload.viewId].selectedEntities = [action.payload.filteredEntities[0]];
                break;
            case "analytics/clearViewEntities":
                draft.views[action.payload.viewId].entities = [];
                draft.views[action.payload.viewId].filteredEntities = [];
                draft.views[action.payload.viewId].selectedEntities = [];
                draft.views[action.payload.viewId].selectedEntity = undefined;
                break;
            case "analytics/activateViewFilter":
                draft.views[action.payload.viewId].filters[action.payload.filterId].isActive = true;
                draft.views[action.payload.viewId].filters[action.payload.filterId].params = action.payload.parameter;
                addFilteredEntitiesToDraft(draft, action);
                break;
            case "analytics/deactivateViewFilter":
                draft.views[action.payload.viewId].filters[action.payload.filterId].isActive = false;
                draft.views[action.payload.viewId].filters[action.payload.filterId].params = undefined;
                addFilteredEntitiesToDraft(draft, action);
                break;
            case "analytics/setViewRepresentation":
                draft.views[action.payload.viewId].representation = action.payload.representation;
                break;
            case "analytics/setActiveSorter":
                draft.views[action.payload.viewId].sorter = action.payload.sorter;
                addFilteredEntitiesToDraft(draft, action);
                break;
            case "analytics/setSelectedEntity":
                draft.views[action.payload.viewId].selectedEntity = action.payload.entity;
                if (draft.views[action.payload.viewId].selectedEntities.length == 0) {
                    draft.views[action.payload.viewId].selectedEntities = [action.payload.entity];
                }
                break;
            case "analytics/setSelectedEntities":
                draft.views[action.payload.viewId].selectedEntities = action.payload.entities;
                if (state.views[action.payload.viewId].selectedEntity == undefined) {
                    draft.views[action.payload.viewId].selectedEntity = action.payload.entities[0];
                }
                break;
            case "analytics/setViewDataState":
                draft.views[action.payload.viewId].data.state = action.payload.state;
                if (action.payload.state == "error") {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (draft.views[action.payload.viewId].data as any).errorMessage = action.payload.errorMessage;
                }
                if (action.payload.dataDescriptions != undefined) {
                    draft.views[action.payload.viewId].dataDescriptions = action.payload.dataDescriptions;
                    applyDataDescriptionsToDraft(draft, action.payload.viewId, action.payload.dataDescriptions);
                    updateEntitiesInDraft(draft, action);
                }
                break;
            case "analytics/setViewDataDescription":
                draft.views[action.payload.viewId].currentDataDescription = action.payload.dataDescription;
                applyDataDescriptionToDraft(draft, action.payload.viewId, action.payload.dataDescription);
                updateEntitiesInDraft(draft, action);
                break;
            case "analytics/setViewCursor":
                draft.views[action.payload.viewId].cursor = action.payload.cursorPosition;
                draft.views[action.payload.viewId].meta.updateSource = action.payload.updateSource;
                draft.views[action.payload.viewId].meta.event = AnalyticsStateViewEvent.CursorPosition;
                break;
            case "analytics/setSecondaryViewCursor":
                draft.views[action.payload.viewId].secondaryCursor = action.payload.cursorPosition;
                draft.views[action.payload.viewId].meta.updateSource = action.payload.updateSource;
                draft.views[action.payload.viewId].meta.event = AnalyticsStateViewEvent.SecondaryCursorPosition;
                break;
            case "analytics/setViewPlayState":
                draft.views[action.payload.viewId].mediaStates.play = action.payload.playState;
                draft.views[action.payload.viewId].meta.updateSource = action.payload.updateSource;
                draft.views[action.payload.viewId].meta.event = AnalyticsStateViewEvent.PlayState;
                break;
            case "analytics/setViewAudioState":
                draft.views[action.payload.viewId].mediaStates.audio = action.payload.audioState;
                draft.views[action.payload.viewId].meta.updateSource = action.payload.updateSource;
                draft.views[action.payload.viewId].meta.event = AnalyticsStateViewEvent.AudioState;
                break;
            case "analytics/setActiveFilterId":
                draft.views[action.payload.viewId].activeFilterId = action.payload.activeFilterId;
                break;
            case "analytics/setAllowVideoStretching":
                draft.views[action.payload.viewId].allowVideoStretching = action.payload.allowVideoStretching;
        }
    });
};

function createViewData(source: DataSource): ViewData {
    if (source instanceof FragmentGroupDataSource) {
        return {
            source: source,
            state: "unloaded",
            hasAssociatedMedia: true,
            primaryMediaId: source.primaryMediaId,
            mediaIds: source.runs.map((run) => run.mediaId),
        };
    }

    return {
        source: source,
        state: "unloaded",
        hasAssociatedMedia: false,
    };
}

function addViewToDraft(
    draft: WritableDraft<AnalyticsState>,
    action: {
        payload: {
            viewId: string;
            projectId: string;
            displayName: string;
            runs?: AnalyticsRun[];
            source?: DataSource;
        };
    },
) {
    const emptyDataDescription = new EmptyDataDescription();

    const view: AnalyticsStateView = {
        viewId: action.payload.viewId,
        projectId: action.payload.projectId,
        displayName: action.payload.displayName,
        currentDataDescription: emptyDataDescription,
        dataDescriptions: [emptyDataDescription],
        data: createViewData(action.payload.source),
        filters: {},
        sorter: { sorter: emptyDataDescription.defaultSorter, order: "asc", sortParameter: undefined },
        selectedEntity: undefined,
        selectedEntities: [],
        cursor: undefined,
        secondaryCursor: undefined,
        entities: [],
        filteredEntities: [],
        representation: "large-icons",
        allowVideoStretching: true,

        // todo: move mediaStates and meta to a separate field in the state
        mediaStates: {
            audio: "unmuted",
            play: "paused",
        },
        meta: {
            updateSource: "init",
            event: AnalyticsStateViewEvent.Other,
        },

        // todo: remove and use filters instead
        activeFilterId: undefined,
    };

    draft.views[action.payload.viewId] = view;
    draft.viewCount = Object.keys(draft.views).length;
}

function applyDataDescriptionsToDraft(
    draft: WritableDraft<AnalyticsState>,
    viewId: string,
    dataDescriptions: DataDescription[],
) {
    const currentDataDescription = dataDescriptions[0];
    draft.views[viewId].currentDataDescription = currentDataDescription;
    draft.views[viewId].dataDescriptions = dataDescriptions;
    applyDataDescriptionToDraft(draft, viewId, currentDataDescription);
}

function applyDataDescriptionToDraft(
    draft: WritableDraft<AnalyticsState>,
    viewId: string,
    dataDescription: DataDescription,
) {
    if (dataDescription === undefined) {
        return;
    }
    draft.views[viewId].filters = Object.fromEntries(
        Object.entries(dataDescription.filters).map(([key, value]) => [
            key,
            { filter: value, isActive: value.defaultParams != undefined, params: value.defaultParams },
        ]),
    );
    draft.views[viewId].sorter = {
        sorter: dataDescription.defaultSorter,
        order: "asc",
        sortParameter: undefined,
    };
}

function updateEntitiesInDraft(draft: WritableDraft<AnalyticsState>, action: { payload: { viewId: string } }) {
    draft.views[action.payload.viewId].entities = draft.views[action.payload.viewId].data.source.getEntities(
        draft.views[action.payload.viewId].currentDataDescription,
    );
    addFilteredEntitiesToDraft(draft, action);
}

function addFilteredEntitiesToDraft(draft: WritableDraft<AnalyticsState>, action: { payload: { viewId: string } }) {
    draft.views[action.payload.viewId].filteredEntities = filterEntities(
        draft.views[action.payload.viewId].entities,
        Object.values(draft.views[action.payload.viewId].filters),
        draft.views[action.payload.viewId].sorter,
    );
}
