import { Entity } from "../Entity";
import { DateEntityProperty } from "../entityProperties/DateEntityProperty";
import { EntitySorter, SortParameterType } from "./EntitySorter";

export class DateTimeSorter extends EntitySorter {
    propertyKey: string;

    constructor(id: string, displayName: string, propertyKey: string) {
        super(id, displayName);
        this.propertyKey = propertyKey;
    }

    getCompareFn(param: undefined | SortParameterType): (a: Entity, b: Entity) => number {
        return (a: Entity, b: Entity) => {
            const startTimeA = (a.properties[this.propertyKey] as DateEntityProperty).value();
            const startTimeB = (b.properties[this.propertyKey] as DateEntityProperty).value();

            return startTimeA.valueOf() - startTimeB.valueOf();
        };
    }
}
