import { nanoid } from "@reduxjs/toolkit";
import { MutableRefObject, useEffect, useRef } from "react";
import LoadingLabel from "../../../components/LoadingLabel";
import { useView } from "../../../hooks/useView";
import { Entity } from "../../../models/viz/Entity";
import { FragmentsMap } from "../../playback/components/useLoadFragments";
import "./Timeline.scss";
import useTimeline from "./useTimeline";
import useTimelineEvents from "./useTimelineEvents";
import useTimelineWidth from "./useTimelineWidth";
import { MediaSourceWithRunSummariesAndEndsAt } from "../../../store/media/MediaItems";

export interface TimelineProps {
    viewId: string;
    media: MediaSourceWithRunSummariesAndEndsAt;
    isLoaded: boolean;
    videoRef: MutableRefObject<HTMLVideoElement>;
    entities: Entity[];
    fragmentsMap: MutableRefObject<FragmentsMap>;
    isShowingDebugUi: boolean;
}

function Timeline(props: TimelineProps) {
    const { view } = useView(props.viewId);

    const timelineId = useRef(`tl-${nanoid(6)}`);
    const timelineRef = useRef<SVGSVGElement | undefined>(undefined);

    const { totalWidth, calculateTimelineWidth, updateTimelineWidth } = useTimelineWidth(timelineRef);

    const {
        domainOffset,
        secondsPerPixel,
        xScale,
        entities,
        drawTimeline,
        setTimelineAttributes,
        calculateDomainStart,
        calculateDomainEnd,
        calculateCentreTime,
        updateEntities,
    } = useTimeline(props.fragmentsMap, props.isShowingDebugUi, props.media, timelineRef, totalWidth);

    const { setListeners, onTimelineMouseEnter, onTimelineMouseLeave, updateCursors, setMarkers, cursorMarker } =
        useTimelineEvents(
            view,
            props.viewId,
            props.media,
            timelineId,
            timelineRef,
            props.videoRef,
            xScale,
            domainOffset,
            secondsPerPixel,
            totalWidth,
            drawTimeline,
        );

    function resetZoom() {
        const paddingMultiplier = 1.05;
        secondsPerPixel.current = (props.media.duration / 1000 / totalWidth.current) * paddingMultiplier;
    }

    function resetPosition() {
        domainOffset.current = 0;

        const centrePoint = 0.5;
        const centreTime = calculateCentreTime(
            props.media.startsAt,
            props.media.startsAt + props.media.duration,
            centrePoint,
        );

        xScale.current.domain([
            calculateDomainStart(centreTime, centrePoint),
            calculateDomainEnd(centreTime, centrePoint),
        ]);
    }

    /** Shifts the timeline's view either forwards or backwards - used for keeping the cursor in view */
    function shiftPosition(direction: "forward" | "backward") {
        const currentDomain = xScale.current.domain();
        const startTime = currentDomain[0].getTime();
        const endTime = currentDomain[1].getTime();
        const frameWidthMilliseconds = endTime - startTime;
        const paddingMilliseconds = frameWidthMilliseconds * 0.1;
        const cursorMilliseconds = view.cursor;

        const centrePoint = 0.5;

        const centreTime =
            direction == "forward"
                ? calculateCentreTime(
                      cursorMilliseconds - paddingMilliseconds,
                      cursorMilliseconds + frameWidthMilliseconds - paddingMilliseconds,
                      centrePoint,
                  )
                : calculateCentreTime(
                      cursorMilliseconds - frameWidthMilliseconds / 2,
                      cursorMilliseconds + frameWidthMilliseconds / 2,
                      centrePoint,
                  );

        domainOffset.current = 0;
        xScale.current.domain([
            calculateDomainStart(centreTime, centrePoint),
            calculateDomainEnd(centreTime, centrePoint),
        ]);

        drawTimeline();
        updateCursors();
    }

    /** Update the timeline's view based on the cursor's position */
    function updateViewOnCursor() {
        if (view.cursor > xScale.current?.domain?.()[1].getTime()) {
            shiftPosition("forward");
        } else if (view.cursor < xScale.current?.domain?.()[0].getTime()) {
            shiftPosition("backward");
        }
    }

    // Resize and update timeline on resize events
    useEffect(() => {
        function updateWidthHandler() {
            // Return early if no update to the timeline width occurred
            if (!props.media || !updateTimelineWidth()) {
                return;
            }

            // Reset zoom level
            resetZoom();
            resetPosition();

            setTimelineAttributes();
            drawTimeline();
            updateCursors();
        }

        window.addEventListener("resize", updateWidthHandler);

        return () => {
            window.removeEventListener("resize", updateWidthHandler);
        };
    }, [props.media?.name]);

    // Setup and update timeline based on load state, cursor positions and entities
    useEffect(() => {
        if (!props.media?.startsAt || !props.isLoaded) {
            return;
        }

        if (view?.cursor !== undefined && cursorMarker.current !== undefined) {
            updateCursors();
            updateViewOnCursor();
        }

        // Listeners need to be reset
        setListeners();

        if (entities.current !== undefined) {
            // Exit early if entities is empty and timeline has already been rendered
            if (!props.entities?.length && entities.current.length == 0) {
                return;
            }

            // Exit early if there has been no change to entities
            if (props.entities.length == entities.current.length) {
                const ids = new Set();
                for (const entity of props.entities) {
                    ids.add(entity.id);
                }
                for (const entity of entities.current) {
                    ids.add(entity.id);
                }
                if (ids.size == entities.current.length) {
                    // Only re-draw timeline if showing debug UI, as otherwise no changes are necessary
                    if (props.isShowingDebugUi) {
                        drawTimeline();
                    }
                    return;
                }
            }
        }

        totalWidth.current = calculateTimelineWidth();
        resetZoom();

        updateEntities(props.entities);

        setTimelineAttributes();
        drawTimeline();
        setMarkers();
    }, [props, view?.cursor, view?.secondaryCursor]);

    return (
        <>
            <svg
                className="timeline"
                ref={timelineRef}
                onMouseEnter={onTimelineMouseEnter}
                onMouseLeave={onTimelineMouseLeave}
                role="application"
                aria-label="video timeline"
            />

            <LoadingLabel isLoading={!props.isLoaded} />
        </>
    );
}

export default Timeline;
