import { Entity } from "../Entity";
import { EntityFilter } from "./EntityFilter";
import { StringFilter } from "../../../views/viz/components/Filtering/StringFilter";
import { AnalyticsStateView } from "../../../store/analytics/Analytics";

export class StringEntityFilter extends EntityFilter {
    propertyGetter: (e: Entity) => string;

    constructor(displayName: string, propertyGetter: (e: Entity) => string) {
        super(displayName);
        this.propertyGetter = propertyGetter;
    }

    predicate(entities: Entity[], parameter: string): Entity[] {
        return entities.filter((e) => this.propertyGetter(e).includes(parameter));
    }

    createComponent(filterId: string, view: AnalyticsStateView, options?: unknown): JSX.Element {
        return <StringFilter viewId={view.viewId} filterId={filterId} placeholder={this.displayName} />;
    }
}

export function StringPropertyGetter(key: string) {
    return (e: Entity) => {
        const property = e.properties[key];
        return property.display().toLowerCase();
    };
}
