import { SetStateAction, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { TagObjectReactSelectOption } from "../EditTagEventModal";
import { TAG_EVENT_STANDARD_ACTIONS } from "../../../../models/tags/TagEvent";
import { StandardTagEventAction } from "cloud-core/tags/TagEvent";

export interface TagEventModalCommentsProps {
    comments: string;
    setComments: (update: SetStateAction<string>) => void;
    selectedSubjects: TagObjectReactSelectOption[];
    selectedObjects: TagObjectReactSelectOption[];
    selectedLocations: TagObjectReactSelectOption[];
    selectedAction: StandardTagEventAction;
    showObject: boolean;
    showLocation: boolean;
    showLocationCheck: boolean;
}

export function getIncludeComment(props: TagEventModalCommentsProps) {
    if (props.selectedSubjects.length === 0) {
        return;
    }

    let action = "";
    switch (props.selectedAction.action) {
        case "transaction":
            action = "has a transaction with";
            break;
        case "other":
            break;
        default:
            action = TAG_EVENT_STANDARD_ACTIONS[props.selectedAction.action].label.toLowerCase();
    }

    if (action === "") {
        return;
    }

    let includeComment = `${props.selectedSubjects.map((s) => s.label).join(", ")} ${action}`;

    if (props.showObject && props.selectedObjects.length > 0) {
        includeComment = `${includeComment} ${props.selectedObjects.map((s) => s.label).join(", ")}`;

        if (props.showLocationCheck && props.selectedLocations.length > 0) {
            includeComment = `${includeComment} at ${props.selectedLocations[0].label}`;
        }
    } else if (props.showLocation && props.selectedLocations.length > 0) {
        includeComment = `${includeComment} ${props.selectedLocations[0].label}`;
    }

    includeComment += "\n";

    return includeComment;
}

function TagEventModalComments(props: TagEventModalCommentsProps) {
    const includeComment = useMemo(
        () => getIncludeComment(props),
        [
            props.selectedSubjects,
            props.selectedAction,
            props.selectedObjects,
            props.selectedLocations,
            props.showObject,
            props.showLocation,
            props.showLocationCheck,
        ],
    );

    return (
        <>
            <div className="gap-3 d-flex align-items-center">
                <Button
                    disabled={!includeComment}
                    title="Include this phrase in the comments"
                    onClick={() => props.setComments((curr) => `${includeComment}${curr}`)}
                >
                    Include
                </Button>
                {includeComment && <p className="m-0">{includeComment}</p>}
            </div>
            <Form.Control
                as="textarea"
                placeholder="Write any comments here"
                value={props.comments}
                onChange={(e) => props.setComments(e.target.value)}
                style={{ height: "10rem" }}
            />
        </>
    );
}

export default TagEventModalComments;
