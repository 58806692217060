import { Entity } from "../Entity";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { DataSource, DataSourceLoadResult, DispatchType } from "./DataSource";
import { kinesenseApiSlice } from "../../../store/api/kinesense";
import { TagObjectDataDescription } from "../dataDescriptions/TagObjectDataDescription";
import { TagObject } from "../../tags/TagObject";

export class TagObjectsDataSource implements DataSource {
    type = "tag-objects";
    private isLoaded = false;
    private projectId: string;
    private dispatch: DispatchType;
    private tagObjects: TagObject[];
    private dataDescriptions: DataDescription[];

    constructor(projectId: string, dispatch: DispatchType) {
        this.projectId = projectId;
        this.dispatch = dispatch;
        this.dataDescriptions = [new TagObjectDataDescription()];
    }

    async load(forceRefetch: boolean = false): Promise<DataSourceLoadResult> {
        this.tagObjects = await this.dispatch(
            kinesenseApiSlice.endpoints.getAllTagObjects.initiate(
                { projectId: this.projectId },
                { forceRefetch },
            ) as DispatchType,
        ).unwrap();

        this.isLoaded = true;
        const result: DataSourceLoadResult = { state: "loaded" };
        return result;
    }

    getEntities(dataDescription: DataDescription): Entity[] {
        if (!this.isLoaded) {
            return [];
        }

        const entities = this.tagObjects
            .map((row) => dataDescription.entityAdapter.convertToEntity(row))
            .filter((e) => e != undefined) as Entity[];

        return entities;
    }

    getDataDescriptions(): DataDescription[] {
        return this.dataDescriptions;
    }

    isSourceFor(signature: unknown): boolean {
        if (typeof signature !== "string") {
            return false;
        }

        return this.projectId === signature;
    }
}
