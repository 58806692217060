import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Button, ListGroup } from "react-bootstrap";
import { useState } from "react";
import { AugmentUserArgs } from "../../../../../store/api/kinesense";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import ConfirmDeleteUserModal from "../modals/ConfirmDeleteUserModal";
import ResetPasswordErrorModal from "../modals/ResetPasswordErrorModal";
import { Organisation } from "../../../../../models/Organisation";
import { User as UserData } from "../../../../../models/User";
import ToggleIsManager from "./ToggleIsManager";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";

export interface SettingsProps {
    userData: UserData;
    organisation: Organisation;
    isForCurrentUsersOrganisation: boolean;
}

export default function Settings(props: SettingsProps) {
    const isAdmin = useSelector((state: ApplicationState) => state.user.permissions.isAdmin);

    const augmentUserArgs: AugmentUserArgs = { username: props.userData.username };
    if (!props.isForCurrentUsersOrganisation) {
        augmentUserArgs.organisationId = props.organisation.organisationId;
    }

    const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
    const [isPasswordResetModalVisible, setIsPasswordResetModalVisible] = useState(false);
    const [isPasswordResetErrorModalVisible, setIsPasswordResetErrorModalVisible] = useState(false);

    const baseAugmentUserModalProps = {
        augmentUserArgs,
        renderUsername,
        username: props.userData.username,
    };

    function renderUsername() {
        return <p className="py-2 shadow-sm fw-bold ps-4 bg-body-tertiary">{props.userData.username}</p>;
    }

    return (
        <section className="gap-3 configure-user flex-column d-flex">
            <h3 className="mb-0 fs-5">
                <FontAwesomeIcon icon={faGear} /> Settings
            </h3>

            {isAdmin && (
                <ListGroup>
                    <ToggleIsManager userData={props.userData} organisation={props.organisation} />
                </ListGroup>
            )}

            <div className="gap-2 d-flex">
                <Button onClick={() => setIsConfirmDeleteModalVisible(true)} type="button" variant="outline-danger">
                    Delete User
                </Button>

                <Button type="button" variant="outline-danger" onClick={() => setIsPasswordResetModalVisible(true)}>
                    Reset Password
                </Button>
            </div>

            <ConfirmDeleteUserModal
                isVisible={isConfirmDeleteModalVisible}
                setIsVisible={setIsConfirmDeleteModalVisible}
                isForCurrentUsersOrganisation={props.isForCurrentUsersOrganisation}
                {...baseAugmentUserModalProps}
            />
            <ResetPasswordModal
                isVisible={isPasswordResetModalVisible}
                setIsVisible={setIsPasswordResetModalVisible}
                setIsErrorModalVisible={setIsPasswordResetErrorModalVisible}
                isForCurrentUsersOrganisation={props.isForCurrentUsersOrganisation}
                {...baseAugmentUserModalProps}
            />
            <ResetPasswordErrorModal
                isVisible={isPasswordResetErrorModalVisible}
                setIsVisible={setIsPasswordResetErrorModalVisible}
            />
        </section>
    );
}
