import { Table } from "react-bootstrap";
import { ProcessorType } from "../../../../models/admin/Processor";
import ProcessorTypeCostBreakdown from "./ProcessorTypeCostBreakdown";

export interface ProcessorTypeDetailsProps {
    data: ProcessorType;
}

function ProcessorTypeDetails(props: ProcessorTypeDetailsProps) {
    return (
        <section>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.data.name}</td>
                        <td>{props.data.type}</td>
                    </tr>
                </tbody>
            </Table>

            <ProcessorTypeCostBreakdown costPerHourDollars={props.data.costPerHour} />
        </section>
    );
}

export default ProcessorTypeDetails;
