import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ActivateViewFilterAction, DeactivateViewFilterAction } from "../../../../store/analytics/Analytics";

export interface StringFilterProps {
    placeholder: string;
    viewId: string;
    filterId: string;
}

export function StringFilter(props: StringFilterProps) {
    const dispatch = useDispatch();

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value.toLowerCase();

        if (value === undefined || value === "") {
            dispatch({
                type: "analytics/deactivateViewFilter",
                payload: { viewId: props.viewId, filterId: props.filterId },
            } as DeactivateViewFilterAction);
        } else {
            dispatch({
                type: "analytics/activateViewFilter",
                payload: { viewId: props.viewId, filterId: props.filterId, parameter: value },
            } as ActivateViewFilterAction);
        }
    }

    return <Form.Control placeholder={props.placeholder} onChange={handleOnChange} />;
}
