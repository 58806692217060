import { useView } from "../../../hooks/useView";
import { VizContainerProps } from "./VizContainer";
import "../Viz.scss";

export type VizCountSummaryProps = VizContainerProps;

export function VizCountSummary(props: VizCountSummaryProps) {
    const { view } = useView(props.viewId);

    return (
        <div className="viz-count-summary viz-panel">
            Showing {view?.filteredEntities.length} / {view?.entities.length} results
        </div>
    );
}
