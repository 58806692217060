import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useAdminGetAllProcessorsQuery } from "../../../../store/api/kinesense";
import { formatProcessorDate } from "./ProcessorListItem";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import RefreshButton from "../../../../components/RefreshButton";

export const ProcessorDataKeyMap: { [key: string]: string } = {
    createdAt: "Created At",
    lastStartedAt: "Last Started",
    processorId: "Processor ID",
    type: "Type",
    instanceId: "Instance ID",
    ec2InstanceType: "EC2 Instance Type",
    launcherVersion: "Launcher Version",
    isEnabled: "Enabled",
    isInitialised: "Initialised",
    supportedAnalysers: "Supported Analysers",
};

export function formatKey(key: string): string {
    return ProcessorDataKeyMap[key] ?? key;
}

export function formatValue(key: string, value: unknown): string {
    if (Array.isArray(value)) {
        return value.join(", ");
    }

    switch (key) {
        case "lastStartedAt":
            return formatProcessorDate(value as number);
        case "isEnabled":
        case "isInitialised":
            return formatBoolean(value as boolean);
        default:
            return value as string;
    }
}

export function formatBoolean(value: boolean) {
    return value ? "Yes" : "No";
}

function ProcessorDetails() {
    const { processorId } = useParams();
    const { data: processors, isFetching, refetch } = useAdminGetAllProcessorsQuery();

    const data = processors?.find((processor) => processor.processorId == processorId);

    let flatData = data;
    if (data !== undefined) {
        flatData = { ...data, ...data.metadata };
        delete flatData.metadata;
    }

    return (
        <main>
            <section className="d-flex align-items-center justify-content-between">
                <h4 className="mb-3 fs-4">Processor Details</h4>
                <RefreshButton callback={refetch} isDisabled={isFetching} />
            </section>

            <ConditionalSpinner isLoading={isFetching}>
                <Table hover>
                    <tbody>
                        {Object.entries(flatData ?? {}).map(([key, value]) => {
                            return (
                                <tr key={key} className="text-secondary">
                                    <td style={{ width: "0px", whiteSpace: "nowrap" }} className="fw-bold pe-5">
                                        {formatKey(key)}
                                    </td>
                                    <td>{formatValue(key, value)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </ConditionalSpinner>
        </main>
    );
}

export default ProcessorDetails;
