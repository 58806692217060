import { Container } from "react-bootstrap";
import AddProcessor from "./AddProcessor";
import ControlledProcessorsList from "./ControlledProcessorsList";

function Processors() {
    return (
        <main id="processors">
            <Container className="d-flex justify-content-between align-items-start mb-3 p-0">
                <h4 className="fs-4 m-0">Processors</h4>
                <AddProcessor />
            </Container>

            <ControlledProcessorsList />
        </main>
    );
}

export default Processors;
