import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { useView } from "../../../../hooks/useView";
import { VizContainerProps } from "../VizContainer";
import { LargeIconList } from "./LargeIconList";
import { DetailList } from "./DetailList";
import "./VizList.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as AnalyticsStore from "../../../../store/analytics/Analytics";
import { DataDescriptions } from "../../../../models/viz/dataDescriptions/GenericVideoEventDataDescription";

export type VizListProps = VizContainerProps;

export function VizList(props: VizListProps) {
    const dispatch = useDispatch();
    const { view } = useView(props.viewId);

    const isLoading = view?.data.state != "loaded";

    const renderList = () => {
        switch (view?.representation) {
            case "large-icons":
                return <LargeIconList viewId={props.viewId} />;
            case "detail-list":
                return <DetailList viewId={props.viewId} />;
        }
    };

    // Switch to non-movement data description
    useEffect(() => {
        if (view?.currentDataDescription?.type === DataDescriptions.standardMovement.type) {
            const desc = view.dataDescriptions.find((d) => d.type !== DataDescriptions.standardMovement.type);

            if (desc !== undefined) {
                dispatch(AnalyticsStore.actionCreators.setViewDataDescription(view.viewId, desc));
            }
        }
    }, [view?.currentDataDescription?.type]);

    return (
        <div className={`gap-3 py-2 viz-list ${isLoading ? "d-flex justify-content-center" : ""}`}>
            <ConditionalSpinner isLoading={isLoading}>
                {view?.currentDataDescription.type !== DataDescriptions.standardMovement.type ? (
                    renderList()
                ) : (
                    <div className="py-4 d-flex w-100 justify-content-center align-items-center">
                        <p>No data available for this media source</p>
                    </div>
                )}
            </ConditionalSpinner>
        </div>
    );
}
