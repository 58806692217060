import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { produce } from "immer";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import { getAuthHeaders } from "../../utilities/Auth";
import { Notifications } from "../../utilities/Notifications/Notifications";

export type AuthorisationIssueType = "credentials" | "newPassword" | "mfaSetup" | "totpRequired" | "passwordReset";
type AuthorisationIssueParams = undefined | BaseAuthIssueParams | MfaSetupParams;

export interface BaseAuthIssueParams {
    isUpdatingAuth: boolean;
    error: Error | undefined;
}

export interface MfaSetupParams extends BaseAuthIssueParams {
    otpKey: string;
}

export interface UserPermissions {
    isAdmin: boolean;
    isManager: boolean;
}

export interface UserState {
    isLoggedIn: boolean;
    username: string;
    user: CognitoUser;
    session: CognitoUserSession;
    permissions: UserPermissions;
    organisationId: string;
    authToken: string;
    fullName: string;
    loginIssue: AuthorisationIssueType | undefined;
    loginIssueParams: AuthorisationIssueParams;
    cognitoPool: CognitoUserPool | undefined;
}

export interface AuthorisationFailedAction {
    type: "user/authorisationIssue";
    payload: {
        user: CognitoUser;
        issueType: AuthorisationIssueType;
        params: AuthorisationIssueParams;
    };
}

export interface AuthorisationIssueUpdateParamsAction {
    type: "user/authorisationIssueUpdateParams";
    payload: {
        params: AuthorisationIssueParams;
    };
}

export interface UserAuthorisedAction {
    type: "user/authorised";
    payload: {
        user: CognitoUser;
        session: CognitoUserSession;
        organisationId: string;
        authToken: string;
        fullName: string;
        permissions: UserPermissions;
    };
}

export interface RefreshAuthSessionAction {
    type: "user/refreshToken";
    payload: {
        session: CognitoUserSession;
        authToken: string;
    };
}

export interface UserDeauthorisedAction {
    type: "user/deauthorised";
}

export interface FetchedCognitoPoolDetailsAction {
    type: "user/cognitoPoolDetails";
    payload: {
        pool: CognitoUserPool;
    };
}

export type KnownAction =
    | AuthorisationIssueUpdateParamsAction
    | AuthorisationFailedAction
    | UserAuthorisedAction
    | UserDeauthorisedAction
    | FetchedCognitoPoolDetailsAction
    | RefreshAuthSessionAction;

function completeNewPasswordChallenge(password: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state) {
            return;
        }

        dispatchAuthIssueUpdateParams(dispatch, { error: undefined, isUpdatingAuth: true });

        state.user.user.completeNewPasswordChallenge(
            password,
            {},
            createAuthenticationHandler(state.user.user, dispatch),
        );
    };
}

/** Helper function to reduce boilerplate for dispatch calls to update loginIssueParams */
function dispatchAuthIssueUpdateParams(
    dispatch: (action: AuthorisationIssueUpdateParamsAction) => void,
    params: {
        error: undefined | Error;
        isUpdatingAuth: boolean;
    },
) {
    dispatch({
        type: "user/authorisationIssueUpdateParams",
        payload: {
            params,
        },
    });
}

function completeMfaSetup(totpCode: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state) {
            return;
        }

        const otpKey = (state.user.loginIssueParams as MfaSetupParams)?.otpKey;

        dispatchAuthIssueUpdateParams(dispatch, { otpKey, error: undefined, isUpdatingAuth: true } as MfaSetupParams);

        state.user.user.verifySoftwareToken(totpCode, "Friendly name", {
            onSuccess: (session) => {
                handleUserAuthorised(state.user.user, dispatch);
            },
            onFailure: (error) => {
                dispatchAuthIssueUpdateParams(dispatch, { otpKey, error, isUpdatingAuth: false } as MfaSetupParams);
                Notifications.notify("Error with verification code", `${error.message}`, "warning");
            },
        });
    };
}

function completeTotpChallenge(totpCode: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state) {
            return;
        }

        dispatchAuthIssueUpdateParams(dispatch, { error: undefined, isUpdatingAuth: true });

        state.user.user.sendMFACode(
            totpCode,
            {
                onSuccess: (session) => {
                    handleUserAuthorised(state.user.user, dispatch);
                },
                onFailure: (error) => {
                    dispatchAuthIssueUpdateParams(dispatch, { error, isUpdatingAuth: false });
                    Notifications.notify("Error with verification code", `${error.message}`, "warning");
                },
            },
            "SOFTWARE_TOKEN_MFA",
        );
    };
}

function completePasswordReset(verificationCode: string, newPassword: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state) {
            return;
        }

        dispatchAuthIssueUpdateParams(dispatch, { error: undefined, isUpdatingAuth: true });

        state.user.user.confirmPassword(verificationCode, newPassword, {
            onSuccess: (data) => {
                console.log("reset init success", data);

                const authDetails = new AuthenticationDetails({
                    Username: state.user.username,
                    Password: newPassword,
                });

                state.user.user.authenticateUser(authDetails, createAuthenticationHandler(state.user.user, dispatch));
            },
            onFailure: (error) => {
                console.log("reset init error", error);
                dispatchAuthIssueUpdateParams(dispatch, { error, isUpdatingAuth: false });
            },
        });
    };
}

function logInUser(username: string, password: string): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        dispatchAuthIssueUpdateParams(dispatch, { error: undefined, isUpdatingAuth: true });

        if (state.user.cognitoPool == undefined) {
            fetchPoolDetails(dispatch, (userPool) => cognitoAuthenticate(username, password, userPool, dispatch));
        } else {
            cognitoAuthenticate(username, password, state.user.cognitoPool, dispatch);
        }
    };
}

function authoriseUser(): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state) {
            return;
        }

        const continueAuth = (userPool: CognitoUserPool) => {
            const user = userPool.getCurrentUser();
            if (user != null) {
                handleUserAuthorised(user, dispatch);
            } else {
                dispatch({
                    type: "user/authorisationIssue",
                    payload: {
                        user: user,
                        // Not undefined as that is for unloaded state
                        issueType: null,
                        params: undefined,
                    },
                } as AuthorisationFailedAction);
            }
        };

        if (state.user.cognitoPool == undefined) {
            fetchPoolDetails(dispatch, (userPool) => {
                continueAuth(userPool);
            });
        } else {
            continueAuth(state.user.cognitoPool);
        }
    };
}

function deauthoriseUser(): AppThunkAction<KnownAction> {
    return (dispatch, getState) => {
        const state = getState();

        if (!state) {
            return;
        }

        if (state.user.isLoggedIn) {
            dispatchAuthIssueUpdateParams(dispatch, {
                error: state.user?.loginIssueParams?.error,
                isUpdatingAuth: true,
            });

            state.user.user.signOut(() => {
                dispatch({
                    type: "user/deauthorised",
                });
            });
        }
    };
}

function refreshAuthSession(): AppThunkAction<RefreshAuthSessionAction | UserDeauthorisedAction> {
    return (dispatch, getState) => {
        const state = getState();
        const refreshToken = state.user.session.getRefreshToken();

        state.user.user.refreshSession(refreshToken, (sessionError, session: CognitoUserSession) => {
            if (sessionError) {
                state.user.user.signOut(() => {
                    dispatch({
                        type: "user/deauthorised",
                    });
                });
            }

            const authToken = session.getIdToken().getJwtToken();

            dispatch({
                type: "user/refreshToken",
                payload: {
                    session: session,
                    authToken,
                },
            });
        });
    };
}

function handleUserAuthorised(user: CognitoUser, dispatch: (action: KnownAction) => void) {
    user.getSession((sessionError, session) => {
        if (sessionError !== null) {
            console.error("Session Error: ", sessionError);
            user.signOut(() => {
                dispatch({ type: "user/deauthorised" } as UserDeauthorisedAction);
            });
            return;
        }

        user.getUserAttributes((attError, attributes) => {
            if (attError !== null) {
                console.error("AttributeError: ", attError);
                user.signOut(() => {
                    dispatch({ type: "user/deauthorised" } as UserDeauthorisedAction);
                });
                return;
            }

            const organisationId = attributes.filter((a) => a.Name == "custom:orgId").map((a) => a.Value)[0];
            const fullName = attributes.filter((a) => a.Name == "custom:fullName").map((a) => a.Value)[0];

            const headers = getAuthHeaders(session.idToken.jwtToken);
            const options: RequestInit = {
                headers: headers,
            };

            fetch(`https://${process.env.REACT_APP_KINESENSE_API_BASE}/user`, options)
                .then((res) => res.text())
                .then((json) => JSON.parse(json))
                .then(({ data }) => {
                    const isAdmin = data.roles.includes("admin");
                    const isManager = data.roles.includes("manager");

                    dispatch({
                        type: "user/authorised",
                        payload: {
                            user: user,
                            session: session,
                            organisationId: organisationId,
                            authToken: session.idToken.jwtToken,
                            fullName: fullName,
                            permissions: {
                                isAdmin: isAdmin,
                                isManager: isManager,
                            },
                        },
                    } as UserAuthorisedAction);
                })
                .catch(() => {
                    user.signOut(() => {
                        dispatch({ type: "user/deauthorised" } as UserDeauthorisedAction);
                    });
                });
        });
    });
}

function fetchPoolDetails(dispatch: (action: KnownAction) => void, callback: (userPool: CognitoUserPool) => void) {
    fetch(`https://${process.env.REACT_APP_KINESENSE_API_BASE}/auth/details`)
        .then((res) => res.json())
        .then(({ data: cognitoDetails }) => {
            const userPool = new CognitoUserPool({
                ClientId: cognitoDetails.clientId,
                UserPoolId: cognitoDetails.userPoolId,
            });

            dispatch({
                type: "user/cognitoPoolDetails",
                payload: {
                    pool: userPool,
                },
            });

            callback(userPool);
        })
        .catch((error) => {
            dispatchAuthIssueUpdateParams(dispatch, { error, isUpdatingAuth: false });
            Notifications.notify(
                "Error fetching user pool",
                `The following error was encountered while attempting to fetch the cognito user pool: ${error.message}`,
            );
        });
}

function createAuthenticationHandler(user: CognitoUser, dispatch: (action: KnownAction) => void) {
    return {
        onSuccess: (result) => {
            handleUserAuthorised(user, dispatch);
        },
        onFailure: (error) => {
            switch (error.code) {
                case "PasswordResetRequiredException":
                    dispatch({
                        type: "user/authorisationIssue",
                        payload: {
                            user: user,
                            issueType: "passwordReset",
                            params: { error, isUpdatingAuth: false },
                        },
                    });
                    break;
                case "NotAuthorizedException":
                case "UserNotFoundException":
                default:
                    dispatch({
                        type: "user/authorisationIssue",
                        payload: {
                            user: user,
                            issueType: "credentials",
                            params: { error, isUpdatingAuth: false },
                        },
                    });
                    break;
            }
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
            dispatch({
                type: "user/authorisationIssue",
                payload: {
                    user: user,
                    issueType: "newPassword",
                    params: undefined,
                },
            });
        },
        mfaSetup: (challengeName, challengeParameters) => {
            user.associateSoftwareToken({
                associateSecretCode: (secretCode: string) => {
                    dispatch({
                        type: "user/authorisationIssue",
                        payload: {
                            user: user,
                            issueType: "mfaSetup",
                            params: { otpKey: secretCode, isUpdatingAuth: false, error: undefined },
                        },
                    });
                },
                onFailure: (error) => {
                    dispatchAuthIssueUpdateParams(dispatch, { error, isUpdatingAuth: false });
                },
            });
        },
        mfaRequired: (challengeName, challengeParameters) => {
            dispatchAuthIssueUpdateParams(dispatch, undefined);
        },
        totpRequired: (challengeName, challengeParameters) => {
            dispatch({
                type: "user/authorisationIssue",
                payload: {
                    user: user,
                    issueType: "totpRequired",
                    params: undefined,
                },
            });
        },
    };
}

function cognitoAuthenticate(
    username: string,
    password: string,
    userPool: CognitoUserPool,
    dispatch: (action: KnownAction) => void,
) {
    const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
    });

    const user = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    user.authenticateUser(authDetails, createAuthenticationHandler(user, dispatch));
}

export const actionCreators = {
    authoriseUser,
    deauthoriseUser,
    logInUser,
    completeNewPasswordChallenge,
    completeMfaSetup,
    completeTotpChallenge,
    completePasswordReset,
    refreshAuthSession,
};

export const unloadedState: UserState = {
    isLoggedIn: false,
    username: "",
    user: undefined,
    session: undefined,
    permissions: {
        isAdmin: false,
        isManager: false,
    },
    authToken: "",
    organisationId: "",
    fullName: "",
    loginIssue: undefined,
    loginIssueParams: {
        otpKey: "Z4OANKGEZG5TC534PQE2PBKONABEFM2EOOWMKPECL6PSCG3MPY4Q",
        isUpdatingAuth: false,
        error: undefined,
    },
    cognitoPool: undefined,
};

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    if (action.type == "user/deauthorised") {
        return { ...unloadedState, loginIssue: null };
    }

    return produce(state, (draft) => {
        switch (action.type) {
            case "user/authorisationIssue":
                draft.user = action.payload.user;
                draft.loginIssue = action.payload.issueType;
                draft.loginIssueParams = action.payload.params;
                break;
            case "user/authorisationIssueUpdateParams":
                draft.loginIssueParams = action.payload.params;
                break;
            case "user/authorised":
                draft.isLoggedIn = true;
                draft.user = action.payload.user;
                draft.session = action.payload.session;
                draft.username = action.payload.user.getUsername();
                draft.authToken = action.payload.authToken;
                draft.organisationId = action.payload.organisationId;
                draft.fullName = action.payload.fullName;
                draft.loginIssue = undefined;
                draft.loginIssueParams = undefined;
                draft.permissions = action.payload.permissions;
                break;
            case "user/cognitoPoolDetails":
                draft.cognitoPool = action.payload.pool;
                break;
            case "user/refreshToken":
                draft.session = action.payload.session;
                draft.authToken = action.payload.authToken;
                break;
        }
    });
};
