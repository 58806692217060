import { TagObject } from "../../../../models/tags/TagObject";
import OverlaySpinner from "../../../OverlaySpinner";
import { TagObjectReactSelectOption } from "../EditTagEventModal";
import { SetStateAction, useMemo } from "react";
import Select, { MultiValue } from "react-select";
import { TagEventModalAddTagObjectButton } from "./TagEventModalAddTagObjectButton";
import { VizContext } from "../../../../models/viz/operations/DataOperation";

export interface TagEventModalSubjectProps {
    context: VizContext;
    tagObjects: TagObject[];
    selectedSubjects: TagObjectReactSelectOption[];
    setSelectedSubjects: (_: SetStateAction<TagObjectReactSelectOption[]>) => void;
    setSelectedObjects: (_: SetStateAction<TagObjectReactSelectOption[]>) => void;
    isFetchingTagObjects: boolean;
}

function TagEventModalSubject(props: TagEventModalSubjectProps) {
    function handleOnChangeSubject(e: MultiValue<TagObjectReactSelectOption>) {
        props.setSelectedSubjects([...e]);
        // Remove selected objects if they are included in newly selected subjects
        props.setSelectedObjects((curr) => curr.filter((o) => !e.find((s) => s.value === o.value)));
    }

    const options: TagObjectReactSelectOption[] = useMemo(() => {
        return (props.tagObjects ?? [])
            .filter((o) => o.type !== "location")
            .map((tagObject: TagObject) => ({
                value: tagObject.objectId,
                label: tagObject.name,
            }));
    }, [props.isFetchingTagObjects]);

    function onCreatedCallback(obj: TagObject) {
        if (obj?.type === "location") {
            return;
        }

        props.setSelectedSubjects((prev) => {
            return [...prev, { value: obj.objectId, label: obj.name }];
        });
    }

    return (
        <div className="gap-1 d-flex">
            <div className="position-relative flex-grow-1">
                <OverlaySpinner isLoading={props.isFetchingTagObjects} />
                <Select
                    placeholder="Select a subject"
                    classNamePrefix="react-select"
                    className="flex-grow-1"
                    value={props.selectedSubjects}
                    options={options}
                    onChange={handleOnChangeSubject}
                    isMulti
                    required
                />
            </div>
            <TagEventModalAddTagObjectButton context={props.context} onCreatedCallback={onCreatedCallback} />
        </div>
    );
}

export default TagEventModalSubject;
