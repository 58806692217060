import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClone, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./CopyButton.scss";

export interface CopyButtonProps {
    toCopy: string;
    copyIcon?: IconProp;
    copiedIcon?: IconProp;
}

export const COPY_RESET_MILLISECONDS = 2000 as const;

export default function CopyButton(props: CopyButtonProps) {
    const [isCopied, setIsCopied] = useState(false);

    const copyIcon = props.copyIcon ?? faClone;
    const copiedIcon = props.copiedIcon ?? faCheck;
    const icon = isCopied ? copiedIcon : copyIcon;

    function handleOnClick(e: React.MouseEvent) {
        e.preventDefault();
        setIsCopied(true);

        navigator.clipboard.writeText(props.toCopy);

        setTimeout(() => {
            setIsCopied(false);
        }, COPY_RESET_MILLISECONDS);
    }

    return (
        <button
            className="copy-button button-with-icon"
            onClick={handleOnClick}
            disabled={isCopied}
            title={isCopied ? "Copied!" : `Copy to clipboard - ${props.toCopy}`}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}
