import { useParams } from "react-router-dom";
import { useAdminGetOrganisationsQuery } from "../../../../store/api/kinesense";
import OrganisationOverview from "../../../organisation/Organisation";

export default function Organisation() {
    const { organisationId } = useParams();
    const { data: organisations, isSuccess } = useAdminGetOrganisationsQuery({});

    if (isSuccess) {
        const data = organisations.find((org) => org.organisationId === organisationId);

        return <OrganisationOverview orgData={data} />;
    }
    return null;
}
