import { useEffect, useState } from "react";

export const useContextMenu = () => {
    const [isShowingMenu, setIsShowingMenu] = useState(false);
    const [menuCoords, setMenuCoords] = useState({
        x: 0,
        y: 0,
    });

    function hideMenu() {
        if (isShowingMenu) {
            setIsShowingMenu(false);
        }
    }

    function showMenu(coords: typeof menuCoords) {
        setIsShowingMenu(true);
        setMenuCoords(coords);
    }

    useEffect(() => {
        document.addEventListener("click", hideMenu);

        return () => {
            document.removeEventListener("click", hideMenu);
        };
    }, []);

    return {
        isShowingMenu,
        showMenu,
        hideMenu,
        menuCoords,
    };
};
