import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createRouterReducer, createRouterMiddleware } from "@lagunovsky/redux-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "./";
import throttle from "redux-throttle";
import { Listener } from "./ActionListener";
import { kinesenseApiSlice } from "./api/kinesense";
import { exchangeRatesApiSlice } from "./api/exchangeRates";

export default function configureStore(history: History, initialState?: ApplicationState) {
    const throttleMiddleware = throttle(300, { leading: true, trailing: true });

    const middleware = [
        Listener.middleware,
        throttleMiddleware,
        thunk,
        createRouterMiddleware(history),
        kinesenseApiSlice.middleware,
        exchangeRatesApiSlice.middleware,
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: createRouterReducer(history),
    });

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any); // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
