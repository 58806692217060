import { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import dayjs from "dayjs";
import { Notification } from "./Notifications";
import { DateFormats } from "../dates";

const TIME_AGO_DEFAULT = "just now" as const;

interface NotificationItemProps {
    notification: Notification;
    onNotificationClosed: (notification: Notification) => void;
}
export function NotificationItem(props: NotificationItemProps) {
    const [autoHide, setAutoHide] = useState(props.notification.severity == "info");
    const [timeAgo, setTimeAgo] = useState<string>(TIME_AGO_DEFAULT);
    const addedAt = dayjs(props.notification.addedAt);

    useEffect(() => {
        // Reset timeAgo if the notification's addedAt has changed
        if (timeAgo != TIME_AGO_DEFAULT) {
            setTimeAgo(TIME_AGO_DEFAULT);
        }

        // Interval to update timeAgo
        const interval = setInterval(() => {
            setTimeAgo(addedAt.fromNow());
        }, 10000);
        return () => clearInterval(interval);
    }, [props.notification.addedAt.getMilliseconds()]);

    return (
        <Toast
            className={`${props.notification.severity}-toast${props.notification.isOld ? " old-notification" : ""}`}
            onClose={() => props.onNotificationClosed(props.notification)}
            autohide={autoHide}
            delay={10000}
            onMouseDown={() => setAutoHide(false)}
        >
            <Toast.Header>
                <strong className="me-auto">{props.notification.title}</strong>
                <small title={addedAt.format(DateFormats.dayMonthYearWithTimeSeconds)}>{timeAgo}</small>
            </Toast.Header>
            <Toast.Body>{props.notification.message}</Toast.Body>
        </Toast>
    );
}
