import { AnimatePresence, motion } from "framer-motion";
import { Form, ListGroup } from "react-bootstrap";
import { faClose, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetadataEditorGroupProps } from "./MetadataEditorGroup";
import {
    AnalyticsMetadataEntryWithId,
    AnalyticsMetadataGroupWithIds,
    AnalyticsMetadataWithIds,
} from "./MetadataEditor";
import { useRef } from "react";
import { nanoid } from "@reduxjs/toolkit";

export const animationProps = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
} as const;

function MetadataEditorFields(props: MetadataEditorGroupProps) {
    const group = props.localMetadata.groups[props.groupIndex];

    const newFieldNameInputRef = useRef<HTMLInputElement>(undefined);
    const newFieldRequiredInputRef = useRef<HTMLInputElement>(undefined);

    function addEntry(entry: AnalyticsMetadataEntryWithId) {
        props.setLocalMetadata((oldMetadata) => {
            const newEntries: AnalyticsMetadataEntryWithId[] = [...oldMetadata.groups[props.groupIndex].entries, entry];

            const newGroup: AnalyticsMetadataGroupWithIds = {
                ...oldMetadata.groups[props.groupIndex],
                entries: newEntries,
            };

            const newMetadata: AnalyticsMetadataWithIds = { groups: [...oldMetadata.groups] };
            newMetadata.groups[props.groupIndex] = newGroup;

            return newMetadata;
        });
        props.onChanged();
    }

    function deleteEntryByIndex(index: number) {
        props.setLocalMetadata((oldMetadata) => {
            const newEntries: AnalyticsMetadataEntryWithId[] = [
                ...oldMetadata.groups[props.groupIndex].entries.slice(0, index),
                ...oldMetadata.groups[props.groupIndex].entries.slice(index + 1),
            ];

            const newGroup: AnalyticsMetadataGroupWithIds = {
                ...oldMetadata.groups[props.groupIndex],
                entries: newEntries,
            };

            const newMetadata: AnalyticsMetadataWithIds = { groups: [...oldMetadata.groups] };
            newMetadata.groups[props.groupIndex] = newGroup;

            return newMetadata;
        });
        props.onChanged();
    }

    function updateEntryByIndex(update: AnalyticsMetadataEntryWithId, index: number) {
        props.setLocalMetadata((oldMetadata) => {
            const newEntries: AnalyticsMetadataEntryWithId[] = [...oldMetadata.groups[props.groupIndex].entries];
            newEntries[index] = update;

            const newGroup: AnalyticsMetadataGroupWithIds = {
                ...oldMetadata.groups[props.groupIndex],
                entries: newEntries,
            };

            const newMetadata: AnalyticsMetadataWithIds = { groups: [...oldMetadata.groups] };
            newMetadata.groups[props.groupIndex] = newGroup;

            return newMetadata;
        });
        props.onChanged();
    }

    function handleOnChangeInput(
        e: React.ChangeEvent<HTMLInputElement>,
        id: string,
        isRequired: boolean,
        index: number,
    ) {
        updateEntryByIndex({ name: e.target.value, id, isRequired }, index);
    }

    function handleOnChangeCheck(e: React.ChangeEvent<HTMLInputElement>, id: string, name: string, index: number) {
        updateEntryByIndex({ name, id, isRequired: e.target.checked }, index);
    }

    function handleOnFocusLostInput(name: string, index: number) {
        if (name === "") {
            deleteEntryByIndex(index);
        }
    }

    function isFieldNameInvalid(fieldName: string): boolean {
        return fieldName === "" || group.entries.filter((e) => e.name === fieldName).length > 1;
    }

    function handleOnSubmit(e: React.FormEvent) {
        e.preventDefault();

        const newEntry: AnalyticsMetadataEntryWithId = {
            id: nanoid(5),
            name: newFieldNameInputRef.current.value,
            isRequired: newFieldRequiredInputRef.current.checked,
        };

        addEntry(newEntry);
        newFieldNameInputRef.current.value = "";
        newFieldRequiredInputRef.current.checked = false;
    }

    return (
        <ListGroup aria-label="Group's field names">
            <AnimatePresence>
                <ListGroup.Item as="header">
                    <h6 className="mb-0">Field name</h6>
                    <h6 className="mb-0">Required</h6>
                    <span aria-hidden></span>
                </ListGroup.Item>

                {group.entries.map(({ id, name, isRequired }, index) => (
                    <ListGroup.Item key={id} as={motion.div} {...animationProps}>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleOnChangeInput(e, id, isRequired, index)
                            }
                            isInvalid={isFieldNameInvalid(name)}
                            onBlur={() => handleOnFocusLostInput(name, index)}
                            title="Rename field"
                        />

                        <Form.Check
                            checked={isRequired}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleOnChangeCheck(e, id, name, index)
                            }
                        />

                        <button
                            className="button-with-icon"
                            onClick={() => deleteEntryByIndex(index)}
                            title="Delete field"
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </ListGroup.Item>
                ))}
            </AnimatePresence>

            <ListGroup.Item as="form" onSubmit={handleOnSubmit}>
                <Form.Control ref={newFieldNameInputRef} type="text" placeholder="New field" required />
                <Form.Check ref={newFieldRequiredInputRef} />

                <button type="submit" className="button-with-icon" title="Add new field">
                    <FontAwesomeIcon icon={faPlusCircle} />
                </button>
            </ListGroup.Item>
        </ListGroup>
    );
}

export default MetadataEditorFields;
