import { AreaBounds } from "cloud-core/spatial/Spatial";
import { AnalyticsStateView } from "../../../store/analytics/Analytics";
import { FilterBitmapUtilities } from "../../../utilities/FilterBitmapUtilities";
import { AreaBoundsFilter } from "../../../views/viz/components/Filtering/AreaBoundsFilter";
import { Entity } from "../Entity";
import { StringEntityProperty } from "../entityProperties/StringEntityProperty";
import { EntityFilter } from "./EntityFilter";

export type AreaBoundsEntityFilterParameter = { areas: AreaBounds[] };

export class AreaBoundsEntityFilter extends EntityFilter {
    boundsKey: string;
    lowResBoundsKey: string;

    constructor(displayName: string, boundsKey: string, lowResBoundsKey: string) {
        super(displayName);
        this.boundsKey = boundsKey;
        this.lowResBoundsKey = lowResBoundsKey;
    }

    predicate(entities: Entity[], parameter: AreaBoundsEntityFilterParameter): Entity[] {
        if (parameter.areas.length == 0) {
            return entities;
        }

        const parameterAreasMap = new Map<AreaBounds, Uint32Array>();
        const convertLowResBounds = (bounds: AreaBounds): Uint32Array => {
            if (!parameterAreasMap.has(bounds)) {
                const bitmap = FilterBitmapUtilities.convertBoundsToBitmap(bounds, 8);
                parameterAreasMap.set(bounds, FilterBitmapUtilities.convertBoundsToBitmap(bounds, 8));
                return bitmap;
            } else {
                return parameterAreasMap.get(bounds);
            }
        };

        return entities.filter((e) => {
            const entityBounds = (e.properties[this.boundsKey] as StringEntityProperty)?.value();
            const lowResEntityBounds = (e.properties[this.lowResBoundsKey] as StringEntityProperty)?.value();
            const lowResEntityBitmap = FilterBitmapUtilities.decodeLowResBitmapString(lowResEntityBounds);

            if (entityBounds == undefined || lowResEntityBounds == undefined) {
                return false;
            }

            let entityBitmap: Uint32Array | undefined = undefined;

            for (const bound of parameter.areas) {
                const filterLowResBitmap = convertLowResBounds(bound);

                if (FilterBitmapUtilities.areasAreOverlapping(filterLowResBitmap, lowResEntityBitmap)) {
                    const filterBitmap = FilterBitmapUtilities.convertBoundsToBitmap(bound, 128);

                    if (entityBitmap == undefined) {
                        entityBitmap = FilterBitmapUtilities.decodeBitmapString(entityBounds);
                    }

                    if (FilterBitmapUtilities.areasAreOverlapping(filterBitmap, entityBitmap)) {
                        return true;
                    }
                }
            }

            return false;
        });
    }

    createComponent(
        filterId: string,
        view: AnalyticsStateView,
        options?: { hasInlineCropSelector: boolean },
    ): JSX.Element {
        return (
            <AreaBoundsFilter
                viewId={view.viewId}
                filterId={filterId}
                useInlineCropSelector={options?.hasInlineCropSelector ?? false}
            />
        );
    }
}
