import { useDispatch, useSelector } from "react-redux";
import Select, { OnChangeValue, ActionMeta, StylesConfig } from "react-select";
import { useState } from "react";
import {
    AvailableOption,
    OptionEntityFilterParameter,
    DEFAULT_OPTION,
} from "../../../../models/viz/filters/OptionEntityFilter";
import { ApplicationState } from "../../../../store";
import { ActivateViewFilterAction, DeactivateViewFilterAction } from "../../../../store/analytics/Analytics";

const STYLES: StylesConfig<AvailableOption, true> = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
    // Extra padding to account for hidden remove button
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
    },
};

export interface OptionFilterProps {
    viewId: string;
    filterId: string;
    availableOptions: AvailableOption[];
}

function OptionFilter({ viewId, filterId, availableOptions }: OptionFilterProps) {
    const dispatch = useDispatch();
    const defaultParams = useSelector(
        (state: ApplicationState) => state.analytics.views[viewId].filters[filterId]?.params,
    ) as OptionEntityFilterParameter;

    const [options, setOptions] = useState<readonly AvailableOption[]>(
        availableOptions.filter(
            (o: AvailableOption) =>
                o.value == DEFAULT_OPTION.value || (defaultParams?.options?.includes(o.value) ?? false),
        ),
    );

    const onOptionChange = (
        selectedOptions: OnChangeValue<AvailableOption, true>,
        actionMeta: ActionMeta<AvailableOption>,
    ) => {
        switch (actionMeta.action) {
            case "remove-value":
            case "pop-value":
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case "clear":
                if (options.length == 1 && options[0].value == DEFAULT_OPTION.value) {
                    return;
                }
                break;
            case "select-option":
                if (actionMeta.option.value == DEFAULT_OPTION.value) {
                    selectedOptions = [DEFAULT_OPTION];
                } else if (selectedOptions[0].value == DEFAULT_OPTION.value) {
                    selectedOptions = [actionMeta.option];
                }
        }

        if (selectedOptions.length == 0) {
            selectedOptions = [DEFAULT_OPTION];
        }

        setOptions(selectedOptions);

        // Update analytics store based on new selected options
        const selectedValues = selectedOptions.map((o) => o.value);
        if (selectedValues.length == 1 && selectedValues[0] == DEFAULT_OPTION.value) {
            dispatch({
                type: "analytics/deactivateViewFilter",
                payload: { viewId, filterId },
            } as DeactivateViewFilterAction);
        } else {
            dispatch({
                type: "analytics/activateViewFilter",
                payload: { viewId, filterId, parameter: { options: selectedValues } },
            } as ActivateViewFilterAction);
        }
    };

    return (
        <div>
            <Select
                isMulti
                classNamePrefix="react-select"
                value={options}
                options={availableOptions}
                isClearable={options.some((o) => !o.isFixed)}
                onChange={onOptionChange}
                styles={STYLES}
            />
        </div>
    );
}

export default OptionFilter;
