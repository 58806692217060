import { AnalyticsMetadata } from "../../../../../models/analysis/Config";
import ImportMetadata, { ImportMetadataResults } from "../ImportMetadata";
import { ImportWizardPagePropsBase } from "../ImportWizard";

interface MetadataEntryPageProps extends ImportWizardPagePropsBase {
    value: ImportMetadataResults | undefined;
    analyserMetadata: AnalyticsMetadata;
}

function MetadataEntryPage(props: MetadataEntryPageProps) {
    const onChanged = (data: unknown) => {
        props.dataChanged(data);
    };

    return (
        <div>
            <ImportMetadata
                onChanged={onChanged}
                value={props.value}
                analyserMetadata={props.analyserMetadata}
                onFormValidityChanged={props.validityChanged}
                showValidationWarnings={true}
            />
        </div>
    );
}

export default MetadataEntryPage;
