import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import { MediaSource } from "../../../models/media/MediaSource";
import { useGetRunsQuery } from "../../../store/api/kinesense";
import { RUN_IDS_QUERY_KEY, buildMediaSourceQueryString } from "../../../hooks/useManageViews";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

type SourceInfoProps = {
    source: MediaSource;
    isDisabled: boolean;
};

export default function SourceInfo(props: SourceInfoProps) {
    const projectId = useSelector((state: ApplicationState) => state.general?.activeProjectId);
    const { data: runs, isFetching: isFetchingRuns } = useGetRunsQuery({
        mediaId: props.source.mediaId,
    });
    const reviewLink = `/review?${buildMediaSourceQueryString(projectId, props.source.mediaId)}`;
    const allRuns = runs?.map((r) => r.runId).join("-");
    const endsAt = props.source?.startsAt ? props.source.startsAt + props.source.duration : undefined;

    return (
        <>
            <h4 className="Source-Name">{props.source.name}</h4>

            <p>
                MediaId: <code>{props.source.mediaId}</code>
            </p>

            <p>Start Time: {new Date(props.source.startsAt).toString()}</p>
            <p className="mb-4">End Time: {new Date(endsAt).toString()}</p>

            <ConditionalSpinner isLoading={isFetchingRuns}>
                <section className="gap-2 d-flex flex-column">
                    <h5>Analysis Runs</h5>

                    {runs?.length > 0 ? (
                        <>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Run</th>
                                        <th>Status</th>
                                        <th>Queued At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {runs?.map((run) => (
                                        <tr key={run.runId}>
                                            <td>
                                                <Link to={`${reviewLink}&${RUN_IDS_QUERY_KEY}=${run.runId}`}>
                                                    {run.runId}
                                                </Link>
                                            </td>
                                            <td>{run.status.currentStage.toUpperCase()}</td>
                                            <td>{new Date(run.status.stages.queued.startedAt).toString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-center">
                                <Link className="btn btn-primary" to={`${reviewLink}&${RUN_IDS_QUERY_KEY}=${allRuns}`}>
                                    View all runs
                                </Link>
                            </div>
                        </>
                    ) : (
                        <p>No runs found for this media source.</p>
                    )}
                </section>
            </ConditionalSpinner>
        </>
    );
}
