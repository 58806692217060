import * as d3 from "d3";
import { d3LineSelection } from "./TimelineTypes";
import { MutableRefObject } from "react";
import { spriteFieldHeight, topLabelHeight } from "./useTimeline";

interface MarkerOptions {
    colour?: string;
}

export class TimelineMarker {
    position: number;
    marker: d3LineSelection;
    timelineRef: MutableRefObject<SVGSVGElement>;
    options: MarkerOptions;

    constructor(timelineRef: MutableRefObject<SVGSVGElement>, options: MarkerOptions) {
        this.timelineRef = timelineRef;
        this.options = options;
        this.createMarkerLine();
    }

    setPosition(position: number) {
        this.position = position;
        this.marker.attr("x1", position);
        this.marker.attr("x2", position);
    }

    hide() {
        this.marker.style("opacity", 0);
    }

    show() {
        this.marker.style("opacity", 1);
    }

    private createMarkerLine() {
        this.marker = d3
            .select(this.timelineRef.current)
            .append("g")
            .append("line")
            .attr("stroke", this.options.colour ?? "var(--bs-body-color)")
            .style("stroke-width", "1px")
            .attr("class", "mouse-line")
            .style("opacity", 0)
            .attr("x1", 20)
            .attr("y1", topLabelHeight)
            .attr("x2", 20)
            .attr("y2", spriteFieldHeight + topLabelHeight);
    }
}
