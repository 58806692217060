import { MutableRefObject, ReactNode, useEffect } from "react";
import { LayoutGroup, motion } from "framer-motion";
import "./Dashboard.scss";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";

export interface DashboardCards {
    [key: string]: ReactNode;
}
// TS/JS nonsense to avoid key being <string|number>
type DashboardCardsKey = keyof DashboardCards & string;

// To allow card components to ignore the drag event on their body and footer
export const ignoreDragEvent = {
    draggable: true,
    onDragStart: (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    },
} as const;

export interface DashboardProps {
    dashboardId: string;
    cards: MutableRefObject<DashboardCards>;
}

function Dashboard(props: DashboardProps) {
    const { organisationId, username } = useSelector((state: ApplicationState) => state.user);

    const localStorageKey = `${organisationId}-${username}-dashboardCardsOrder-${props.dashboardId}`;
    const cardsKeys = Object.keys(props.cards.current);

    const [cardsOrder, setCardsOrder] = useLocalStorage<DashboardCardsKey[]>(localStorageKey, cardsKeys);

    useEffect(() => {
        syncLocalStorage();
    }, []);

    // Ensure local storage and provided cards' keys are in sync
    function syncLocalStorage() {
        const nextCardsOrder = [];

        // Keep stored cards' order, but remove cards not present in cardsKeys
        for (const item of cardsOrder) {
            if (cardsKeys.includes(item)) {
                nextCardsOrder.push(item);
            }
        }

        // Add any new cards not present in local storage but given in cardsKeys
        for (const item of cardsKeys) {
            if (!cardsOrder.includes(item)) {
                nextCardsOrder.push(item);
            }
        }

        setCardsOrder(nextCardsOrder);
    }

    function handleOnDragStart(e: DragEvent, cardType: DashboardCardsKey) {
        e.dataTransfer.setData("cardType", cardType);
    }

    function handleOnDragOver(e: React.DragEvent) {
        e.preventDefault();
    }

    function handleOnDrop(e: React.DragEvent, cardType: DashboardCardsKey) {
        const dragged = e.dataTransfer.getData("cardType") as DashboardCardsKey;
        const dropped = cardType;

        swapCards(dragged, dropped);
    }

    function swapCards(dragged: DashboardCardsKey, dropped: DashboardCardsKey) {
        setCardsOrder((cardsOrder: DashboardCardsKey[]) => {
            const nextCardsOrder = [...cardsOrder];

            const indexDragged = cardsOrder.indexOf(dragged);
            const indexDropped = cardsOrder.indexOf(dropped);

            nextCardsOrder[indexDragged] = cardsOrder[indexDropped];
            nextCardsOrder[indexDropped] = cardsOrder[indexDragged];

            return nextCardsOrder;
        });
    }

    return (
        <div className="gap-4 dashboard">
            <LayoutGroup>
                {cardsOrder.map((key) => (
                    <motion.div
                        key={key}
                        className={key}
                        layout
                        draggable
                        onDragStart={(e: DragEvent) => handleOnDragStart(e, key)}
                        onDragOver={handleOnDragOver}
                        onDrop={(e) => handleOnDrop(e, key)}
                    >
                        {props.cards.current[key]}
                    </motion.div>
                ))}
            </LayoutGroup>
        </div>
    );
}

export default Dashboard;
