import * as React from "react";
import MediaItem from "../components/MediaItem";
import "./MediaList.scss";
import { MediaSource } from "../../../models/media/MediaSource";

type MediaListProps = {
    mediaList: MediaSource[];
    clickHandler: React.MouseEventHandler<HTMLLIElement>;
    activeSourceId: string;
};

export default function MediaList(props: MediaListProps) {
    return (
        <ul className="list-group media-list">
            {props.mediaList.map((item, index) => (
                <li
                    key={index}
                    className={`list-group-item item list-group-item-action${
                        item.mediaId === props.activeSourceId ? " active-item" : ""
                    }`}
                    id={item.mediaId}
                    onClick={props.clickHandler}
                >
                    <MediaItem item={item} />
                </li>
            ))}
        </ul>
    );
}
