import { useGetAllTagEventsQuery } from "../../../store/api/kinesense";

/** Get the number of tag events associated with a given media ID.
 *
 * Returns undefined while the tag events are not yet loaded. */
function useTagEventsCount(projectId: string, mediaId: string): number | undefined {
    const { data: tagEvents, isSuccess: hasLoadedTagEvents } = useGetAllTagEventsQuery(
        { projectId },
        { skip: !projectId || !mediaId },
    );

    if (!hasLoadedTagEvents) {
        return undefined;
    }

    let count = 0;
    for (const event of tagEvents) {
        for (const occurrence of event.occurrences) {
            if (occurrence.mediaId === mediaId) {
                count += 1;
            }
        }
    }
    return count;
}

export default useTagEventsCount;
