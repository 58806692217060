import { Entity } from "../Entity";
import { EntityInformationGenerator } from "../entityInformation/EntityInformationGenerator";
import dayjs from "dayjs";
import { DateEntityProperty } from "../entityProperties/DateEntityProperty";

export class DateTimeEntityInformationGenerator implements EntityInformationGenerator {
    propertyKey: string;
    format: string;
    displayName: string;

    constructor(propertyKey: string, format: string, displayName: string) {
        this.propertyKey = propertyKey;
        this.format = format;
        this.displayName = displayName;
    }

    generateInformation(entity: Entity): string {
        const date = entity.properties[this.propertyKey] as DateEntityProperty;

        if (date === undefined) {
            return "";
        }

        return dayjs(date.value()).format(this.format);
    }
}
