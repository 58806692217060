import { Fragment } from "cloud-core/analytics/Fragment";
import { useMemo, useRef } from "react";
import useAsyncCallback from "../../../hooks/useAsyncCallback";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { useView } from "../../../hooks/useView";
import { calculateFramePeriod } from "../../../models/media/MediaSource";
import { useLazyGetFragmentsFromGroupsQuery } from "../../../store/api/kinesense";
import { isInRange } from "../../../utilities/helpers";
import useMediaSources from "../../../hooks/useMediaSources";

export type FragmentsMap = { [key: string]: Fragment[] };

// Max and min values used for fetching fragments for groups ahead of the cursor
const MAX_LOAD_AHEAD_MS = 10000;
const MIN_LOAD_AHEAD_MS = 1500;

// Values for the debounced callback which updates the fragmentsMap
const DEBOUNCE_MILLISECONDS = 90;
const DEBOUNCE_MAX_WAIT_MILLISECONDS = MIN_LOAD_AHEAD_MS * 0.5;

/** Calculate the density of a video's fragment groups. Returns a value from 0 to 1.0. */
function calculateEntityDensity(numEntities: number, mediaDuration: number) {
    return Math.min(mediaDuration / (numEntities * 2000), 1.0);
}

/** Calculate how far ahead to load fragments for groups based on the video's entity/fragment group density. */
function calculateLoadAhead(numEntities: number, mediaDuration: number) {
    return Math.max(MIN_LOAD_AHEAD_MS, calculateEntityDensity(numEntities, mediaDuration) * MAX_LOAD_AHEAD_MS);
}

function useLoadFragments(mediaId: string, viewId: string) {
    const { view } = useView(viewId);

    const { activeMediaSource: media } = useMediaSources(viewId, mediaId);
    const millisecondsBetweenFrames = calculateFramePeriod(media?.files?.initialDisplay);

    // Used for accurate purging of old fragmentsMap data when the filter is updated
    const prevActiveFilterID = useRef(view?.activeFilterId);
    const prevEntitiesLength = useRef(view?.filteredEntities.length);

    const [fetchFragments] = useLazyGetFragmentsFromGroupsQuery();

    const fragmentsMap = useRef<FragmentsMap>({});

    const load_ahead_ms = useMemo(() => {
        if (!view?.filteredEntities || view.filteredEntities.length == 0 || !media?.duration) {
            return MIN_LOAD_AHEAD_MS;
        }
        return calculateLoadAhead((view?.filteredEntities ?? []).length, media?.duration ?? 0);
    }, [view?.filteredEntities.length, media?.duration]);

    // Purge fragmentsMap of fragments from groups which have been filtered out
    function purgeFilteredFragments() {
        if (
            prevActiveFilterID.current !== view?.activeFilterId ||
            prevEntitiesLength.current != view?.filteredEntities.length
        ) {
            const filteredFragmentsMap = { ...fragmentsMap.current };
            prevActiveFilterID.current = view?.activeFilterId;
            prevEntitiesLength.current = view?.filteredEntities.length;

            const fragmentGroupIds = new Set(view?.filteredEntities.map((e) => e.sourceObject.id));
            for (const key in fragmentsMap.current) {
                if (!fragmentGroupIds.has(key)) {
                    delete filteredFragmentsMap[key];
                }
            }

            fragmentsMap.current = filteredFragmentsMap;
        }
    }

    // Update multiple groups in fragmentsMap based on the cursor time, only fetching groups
    // not already stored in the fragmentsMap (unless the activeFilterId has changed)
    async function updateFragmentsMap() {
        purgeFilteredFragments();

        // Group ids are gathered based on runId so fragments can be fetched in batches
        const runIdsMap: Record<string, [string, ...string[]]> = {};
        for (const entity of view?.filteredEntities ?? []) {
            if (fragmentsMap[entity.sourceObject.id] !== undefined) {
                continue;
            }

            const isInLoadRange = isInRange(view?.cursor, [
                entity.getStartTimeOrElse(() => new Date()).valueOf() - load_ahead_ms,
                entity.getEndTimeOrElse(() => new Date()).valueOf() + millisecondsBetweenFrames,
            ]);

            if (
                isInLoadRange &&
                entity.sourceObject.mediaSource !== undefined &&
                entity.sourceObject.runId !== undefined
            ) {
                const runIdString = entity.sourceObject.runId.toString();
                if (runIdsMap[runIdString]) {
                    runIdsMap[runIdString].push(entity.sourceObject.id);
                } else {
                    runIdsMap[runIdString] = [entity.sourceObject.id];
                }
            }
        }

        // Update fragmentsMap with the newly fetched fragments
        for (const runIdString in runIdsMap) {
            const fetchedFragmentsMap = await fetchFragments({
                mediaId,
                runId: parseInt(runIdString),
                groupIds: runIdsMap[runIdString],
            }).unwrap();

            if (fetchedFragmentsMap) {
                fragmentsMap.current = Object.assign(fragmentsMap.current, fetchedFragmentsMap);
            }
        }
    }

    const debouncedUpdateFragmentsMap = useDebouncedCallback(updateFragmentsMap, DEBOUNCE_MILLISECONDS, {
        maxWait: DEBOUNCE_MAX_WAIT_MILLISECONDS,
        isImmediate: true,
    });

    useAsyncCallback(
        debouncedUpdateFragmentsMap,
        [view?.cursor, view?.filteredEntities, view?.filteredEntities.length],
        {
            skip: !mediaId,
        },
    );

    return fragmentsMap;
}

export default useLoadFragments;
