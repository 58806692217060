import { Button, Modal } from "react-bootstrap";

export interface ResetPasswordErrorModalProps {
    isVisible: boolean;
    setIsVisible: (nextIsVisible: React.SetStateAction<boolean>) => void;
}

function ResetPasswordErrorModal(props: ResetPasswordErrorModalProps) {
    const hideResetPasswordErrorModal = () => props.setIsVisible(false);

    return (
        <Modal show={props.isVisible} backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <Modal.Title>Error Resetting Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>There was an error reseting the user's password.</p>
                <p>The user's password has not been reset.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={hideResetPasswordErrorModal} type="button" variant="secondary">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ResetPasswordErrorModal;
