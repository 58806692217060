import * as d3 from "d3";
import { d3TextSelection } from "./TimelineTypes";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { MutableRefObject } from "react";

export class TimelineHoverText {
    cachedPosition: number;
    hoverText: d3TextSelection;
    hoverSubText: d3TextSelection;
    textXMargin = 5;
    textYMargin = 20;

    constructor(timelineRef: MutableRefObject<SVGSVGElement>) {
        this.createHoverText(timelineRef);
    }

    setPosition(position: number, totalWidth: number) {
        const hoverTextWidth = this.hoverText.node().getComputedTextLength();
        const combinedHoverTextWidth = Math.floor(hoverTextWidth + this.hoverSubText.node().getComputedTextLength());

        // Don't update position if it will overlflow the timeline
        if (position + combinedHoverTextWidth + this.textXMargin > totalWidth) {
            return;
        }

        this.cachedPosition = position;
        this.hoverText.attr("transform", `translate(${position}, ${this.textYMargin})`);
        this.hoverSubText.attr("transform", `translate(${position + hoverTextWidth}, ${this.textYMargin})`);
    }

    setDate(date: Date) {
        const dateTime = dayjs(date);
        this.hoverText.text(dateTime.format(DateFormats.dayMonthYearWithTimeSeconds) + ".");
        this.hoverSubText.text(dateTime.format(DateFormats.milliseconds));
    }

    hide() {
        this.hoverText.style("opacity", 0);
        this.hoverSubText.style("opacity", 0);
    }

    show() {
        this.hoverText.style("opacity", 1);
        this.hoverSubText.style("opacity", 1);
    }

    private createHoverText(timelineRef: MutableRefObject<SVGSVGElement>) {
        const timelineSelection = d3.select(timelineRef.current);

        // Hack to get CSS for monospace font-family i.e. var(--bs-font-monospace)
        const dummyDiv = timelineSelection.append("div").attr("class", "font-monospace");
        const fontFamily = dummyDiv.style("font-family");
        dummyDiv.remove();

        this.hoverText = timelineSelection.append("text").style("font", `15px ${fontFamily}`);
        this.hoverSubText = timelineSelection.append("text").style("font", `10px ${fontFamily}`);
    }
}
