import * as d3 from "d3";
import { d3RectSelection } from "./TimelineTypes";
import { TimelineMarker } from "./TimelineMarker";
import { MutableRefObject } from "react";
import { spriteFieldHeight, topLabelHeight } from "./useTimeline";

export class TimelineSelection {
    firstMarker: TimelineMarker;
    secondMarker: TimelineMarker;
    area: d3RectSelection;
    timelineRef: MutableRefObject<SVGSVGElement>;

    constructor(timelineRef: MutableRefObject<SVGSVGElement>) {
        this.timelineRef = timelineRef;
    }

    setMarkers(firstMarker: TimelineMarker, secondMarker: TimelineMarker) {
        this.firstMarker = firstMarker;
        this.secondMarker = secondMarker;
        this.createSelectionArea("cyan");
    }

    update() {
        const firstIsStart = this.firstMarker.position < this.secondMarker.position;
        const start = firstIsStart ? this.firstMarker.position : this.secondMarker.position;
        const end = firstIsStart ? this.secondMarker.position : this.firstMarker.position;

        if (!start || !end) {
            return;
        }

        this.area.attr("x", start);
        this.area.attr("width", end - start);
    }

    hide() {
        this.area.style("opacity", 0);
    }

    show() {
        this.area.style("opacity", 0.3);
    }

    private createSelectionArea(colour: string) {
        this.area = d3
            .select(this.timelineRef.current)
            .append("g")
            .append("rect")
            .style("fill", colour)
            .style("opacity", 0)
            .attr("x", 20)
            .attr("y", topLabelHeight)
            .attr("height", spriteFieldHeight)
            .attr("width", 10);
    }
}
