import { Button, Modal } from "react-bootstrap";
import { useAdminResetUserPasswordMutation, useResetUserPasswordMutation } from "../../../../../store/api/kinesense";
import { Notifications } from "../../../../../utilities/Notifications/Notifications";
import { BaseAugmentUserModalProps } from "./ConfirmDeleteUserModal";

export type ResetPasswordModalProps = BaseAugmentUserModalProps & {
    setIsErrorModalVisible: (nextIsVisible: boolean) => void;
};

function ResetPasswordModal(props: ResetPasswordModalProps) {
    const [requestResetPassword] = props.isForCurrentUsersOrganisation
        ? useResetUserPasswordMutation()
        : useAdminResetUserPasswordMutation();

    const hidePasswordResetModal = () => props.setIsVisible(false);

    function handleOnClickResetPassword() {
        requestResetPassword(props.augmentUserArgs)
            .unwrap()
            .then(() => {
                hidePasswordResetModal();

                Notifications.notify(
                    "Password Reset Successfully",
                    `Password reset for user ${props.augmentUserArgs.username}`,
                    "info",
                );
            })
            .catch(() => {
                hidePasswordResetModal();
                props.setIsErrorModalVisible(true);
            });
    }

    return (
        <Modal show={props.isVisible} onHide={hidePasswordResetModal} backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <Modal.Title>Reset User Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>The following user's password will be reset</p>
                {props.renderUsername()}
                <p>The user will be emailed a reset code which they can use to reset their password on log in.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="ml-2" type="button" variant="danger" onClick={handleOnClickResetPassword}>
                    Reset Password
                </Button>
                <Button onClick={hidePasswordResetModal} type="button" variant="secondary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ResetPasswordModal;
