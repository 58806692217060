import HorizontalTabs from "../../components/HorizontalTabs";
import AdvancedTab from "./components/AdvancedTab";
import AppearanceTab from "./components/AppearanceTab";
import NotificationsTab from "./components/NotificationsTab";
import SecurityTab from "./components/SecurityTab";
import "./Settings.scss";

function Settings() {
    const tabsMap = {
        Appearance: <AppearanceTab />,
        Security: <SecurityTab />,
        Advanced: <AdvancedTab />,
        Notifications: <NotificationsTab />,
    };

    return (
        <main className="container user-settings">
            <h4>Settings</h4>

            <HorizontalTabs tabsMap={tabsMap} />
        </main>
    );
}

export default Settings;
