import { MutableRefObject } from "react";

// UNCONTROLLED FORMS
type InputRefs = { [key: string]: MutableRefObject<HTMLInputElement> };

function getUncontrolledFormData<FormData>(inputRefs: InputRefs): FormData {
    const data = {};
    for (const [key, ref] of Object.entries(inputRefs)) {
        data[key] = ref.current.value;
    }
    return data as FormData;
}

function resetUncontrolledFormData<FormData>(inputRefs: InputRefs, emptyFormValues: FormData) {
    for (const [key, ref] of Object.entries(inputRefs)) {
        ref.current.value = emptyFormValues[key];
    }
}

function useUncontrolledFormUtils<FormData>(
    inputRefs: InputRefs,
    emptyFormValues: FormData,
): {
    getFormData: () => FormData;
    resetFormData: () => void;
} {
    return {
        getFormData: () => getUncontrolledFormData<FormData>(inputRefs),
        resetFormData: () => resetUncontrolledFormData<FormData>(inputRefs, emptyFormValues),
    };
}

export default useUncontrolledFormUtils;
