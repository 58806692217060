import { Card, ListGroup, Spinner } from "react-bootstrap";
import { ignoreDragEvent } from "../../../../components/dashboard/Dashboard";
import { useGetAllMediaQuery, useGetProjectsQuery } from "../../../../store/api/kinesense";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import VideoListItem from "./VideoListItem";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function VideoList() {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);
    const { data: projects } = useGetProjectsQuery();
    const project = (projects ?? []).find((p) => p.projectId == projectId);

    const {
        data: allMediaSources,
        isSuccess,
        isFetching,
    } = useGetAllMediaQuery({ projectId }, { skip: projectId === undefined });

    const hasLoadedMediaSources = isSuccess && !isFetching;

    return (
        <Card as={motion.article} layout>
            <Card.Header as={motion.header} layout>
                <Card.Title as={motion.h5} layout className="m-1">
                    <motion.span layout>Videos - </motion.span>
                    {project !== undefined ? (
                        <motion.span layout>{project?.name ?? "Unknown"}</motion.span>
                    ) : (
                        <Spinner size="sm" as={motion.div} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                </Card.Title>
            </Card.Header>

            <ListGroup variant="flush" as={motion.div} layout {...ignoreDragEvent}>
                <div className="d-flex flex-column h-100 justify-content-between">
                    <div>
                        <ListGroup.Item as={motion.header} layout>
                            <motion.span layout className="m-0 h6">
                                Name
                            </motion.span>
                            <motion.span layout className="m-0 h6">
                                Videos starts at
                            </motion.span>
                        </ListGroup.Item>

                        <motion.div layout className="items">
                            <ConditionalSpinner size="sm" isLoading={!hasLoadedMediaSources}>
                                {allMediaSources?.map((data) => <VideoListItem key={data.mediaId} data={data} />)}
                            </ConditionalSpinner>
                        </motion.div>
                    </div>

                    <Link
                        className="my-2 button-with-icon align-self-center fs-6 text-decoration-none"
                        to="/import-wizard"
                    >
                        <motion.span
                            layout
                            className="gap-2 d-flex align-items-center text-truncate"
                            title="Import a new media source"
                        >
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <motion.span layout className="text-truncate">
                                New import
                            </motion.span>
                        </motion.span>
                    </Link>
                </div>
            </ListGroup>
        </Card>
    );
}

export default VideoList;
