import { Entity } from "../Entity";
import { EntityInformationGenerator } from "../entityInformation/EntityInformationGenerator";

export class DisplayStringEntityInformationGenerator implements EntityInformationGenerator {
    propertyKey: string;
    displayName: string;

    constructor(propertyKey: string, displayName: string) {
        this.propertyKey = propertyKey;
        this.displayName = displayName;
    }

    generateInformation(entity: Entity): string {
        return entity.properties[this.propertyKey].display();
    }
}
