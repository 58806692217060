import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";
import { ApplicationState } from "../../../../store";
import { useAdminGetUsersQuery, useGetUsersQuery } from "../../../../store/api/kinesense";
import { Organisation } from "../../../../models/Organisation";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import User from "./User";
import AddUser from "./AddUser";
import "./Users.scss";

export interface UsersProps {
    organisation: Organisation;
    isForCurrentUsersOrganisation: boolean;
}

export default function Users(props: UsersProps) {
    const user = useSelector((state: ApplicationState) => state.user);
    const username = user?.username;
    const hasPrivilegedPermissions = user.permissions.isAdmin || user.permissions.isManager;

    const { data: usersData, isLoading } = props.isForCurrentUsersOrganisation
        ? useGetUsersQuery({})
        : useAdminGetUsersQuery({ organisationId: props.organisation.organisationId });

    return (
        <Accordion flush className="organisation-users">
            <Container className="px-0 mb-2 d-flex justify-content-between">
                <h5 className="mb-0">Users</h5>
                {hasPrivilegedPermissions && <AddUser {...props} />}
            </Container>

            <header>
                <h6>#</h6>
                <h6>Username</h6>
                <h6>Email</h6>
                <h6>Role</h6>
                <h6 aria-hidden></h6>
            </header>

            <ConditionalSpinner isLoading={isLoading}>
                {usersData?.map((userData, index) => (
                    <User
                        {...props}
                        key={index}
                        positionInList={(index + 1).toString()}
                        userData={userData}
                        isCurrentUser={userData.username === username}
                    />
                ))}
            </ConditionalSpinner>
        </Accordion>
    );
}
