import { useEffect, useState } from "react";
import ImportMetadataGroup from "./ImportMetadataGroup";
import { AnalyticsMetadata } from "../../../../models/analysis/Config";

export interface ImportMetadataProps {
    value?: ImportMetadataResults;
    analyserMetadata: AnalyticsMetadata;
    onChanged: (data: ImportMetadataResults) => void;
    onFormValidityChanged: (isFormValid: boolean) => void;
    showValidationWarnings: boolean;
}

export interface ImportMetadataResults {
    [groupKey: string]: { [entryKey: string]: string };
}

function ImportMetadata(props: ImportMetadataProps) {
    const groups = props.analyserMetadata?.groups ?? [];
    const defaults: ImportMetadataResults = {} as ImportMetadataResults;

    for (const group of groups) {
        const groupEntries = {} as { [entryKey: string]: string };

        for (const entry of group.entries) {
            groupEntries[entry.name] = "";
        }
        defaults[group.name] = groupEntries;
    }

    const initialResults = props.value ?? defaults;
    const [results, setResults] = useState<ImportMetadataResults>(initialResults);

    const defaultGroupValidities = groups.map((_) => false);
    const [groupValidities, setGroupValidities] = useState<boolean[]>(defaultGroupValidities);
    const [isValid, setIsValid] = useState<boolean>(false);

    const handleGroupValidityChanged = (index: number, isValid: boolean) => {
        setGroupValidities((oldValidities) => {
            const newValidities = [...oldValidities];
            newValidities[index] = isValid;

            return newValidities;
        });
    };

    useEffect(() => {
        const newIsValid = groupValidities.reduce((acc, curr) => acc && curr, true);
        setIsValid(newIsValid);
    }, [groupValidities]);

    useEffect(() => {
        props.onFormValidityChanged(isValid);
    }, [isValid]);

    useEffect(() => {
        if (results === undefined || Object.keys(results).length === 0) {
            return;
        }

        props.onChanged(results);
    }, [results]);

    return (
        <>
            {groups.map((group, i) => (
                <ImportMetadataGroup
                    key={group.name}
                    group={group}
                    results={results}
                    setResults={setResults}
                    showValidationWarnings={props.showValidationWarnings}
                    onValidityChanged={(isValid) => handleGroupValidityChanged(i, isValid)}
                />
            ))}
        </>
    );
}

export default ImportMetadata;
