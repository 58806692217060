import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import NewPasswordInput, { PasswordChangedEventArgs } from "./components/NewPasswordInput";
import * as UserStore from "../../store/user/User";

function PasswordReset() {
    const dispatch = useDispatch();

    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);

    function handleVerificationCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setVerificationCode(event.target.value);
    }

    function onNewPasswordChanged(data: PasswordChangedEventArgs) {
        setNewPassword(data.password);
        setIsNewPasswordValid(data.isValid);
    }

    function confirmResetPassword(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        dispatch(UserStore.actionCreators.completePasswordReset(verificationCode, newPassword));
    }

    return (
        <div>
            <p>Your password has been reset.</p>
            <p>
                Please enter the verification code that was
                <br /> emailed to you, and create a new password.
            </p>
            <Form.Group className="gap-2 d-flex flex-column">
                <Form.Control type="text" placeholder="Verification Code" onChange={handleVerificationCodeChange} />

                <NewPasswordInput passwordChanged={onNewPasswordChanged} />

                <div className="mt-1 text-center">
                    <Button onClick={confirmResetPassword} disabled={!isNewPasswordValid}>
                        Reset Password
                    </Button>
                </div>
            </Form.Group>
        </div>
    );
}

export default PasswordReset;
