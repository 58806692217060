import { useGetAnalysersConfigQuery, useGetRunsQuery } from "../../../../store/api/kinesense";
import { Accordion, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { ReviewVideoListItemTrayContentProps } from "./ReviewVideoListItemFooter";
import useInterval from "../../../../hooks/useInterval";
import { capitaliseFirst } from "../../../../utilities/helpers";
import { DateFormats } from "../../../../utilities/dates";
import dayjs from "dayjs";

export type ReviewVideoListItemRunsProps = ReviewVideoListItemTrayContentProps & { forceUpdate: () => void };

export default function ReviewVideoListItemRuns(props: ReviewVideoListItemRunsProps) {
    const mediaId = props.data.mediaId;
    const { data, isSuccess, isFetching } = useGetRunsQuery({ mediaId }, { skip: !mediaId });
    const isLoadingRuns = isFetching || !isSuccess;
    const runs = data ?? [];

    const { data: analyserConfig, isLoading: isLoadingAnalysers } = useGetAnalysersConfigQuery();
    function getMatchingAnalyser(analyserId: string) {
        return analyserConfig?.analysers.find((a) => analyserId === a.analyserId);
    }

    // Additional functionality needed since height of the tray needs to be dynamically updated
    const { resumeInterval, pauseInterval } = useInterval(props.forceUpdate, 20, false);

    if (isLoadingRuns || isLoadingAnalysers) {
        return;
    }

    return (
        <div ref={props.contentRef} className="inner">
            {runs.length === 0 ? (
                <p className="text-center text-muted">No algorithms have been applied to this media source</p>
            ) : (
                <section className="overflow-hidden">
                    <h4>Algorithms</h4>
                    <Accordion flush>
                        {runs.map((r) => {
                            const analyser = getMatchingAnalyser(r.analyserId);

                            if (!analyser) {
                                return undefined;
                            }

                            // NOTE: when we support more algorithms, different icons need to be
                            // added here based on the `analyserId`
                            const icon = faRunning;

                            const lastUpdated = dayjs(r.lastUpdatedAt).format(DateFormats.dayMonthYearWithTimeSeconds);

                            return (
                                <Accordion.Item eventKey={r.analyserId} key={r.analyserId}>
                                    <Accordion.Header>
                                        <div className="gap-2 d-flex">
                                            <FontAwesomeIcon icon={icon} />
                                            <span title={analyser.description}>{analyser.displayName}</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body
                                        onExiting={resumeInterval}
                                        onExited={pauseInterval}
                                        onEntering={resumeInterval}
                                        onEntered={pauseInterval}
                                    >
                                        <Table borderless className="details-table">
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    <td className="w-100">{capitaliseFirst(r.status.currentStage)}</td>
                                                </tr>

                                                <tr>
                                                    <td>Last Updated</td>
                                                    <td>{lastUpdated}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </section>
            )}
        </div>
    );
}
