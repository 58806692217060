import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FragmentGroupDataSource } from "../models/viz/dataSources/FragmentGroupDataSource";
import * as AnalyticsStore from "../store/analytics/Analytics";
import * as GeneralStore from "../store/general/General";
import { AnalyticsRun } from "../store/analytics/Analytics";
import { ApplicationState } from "../store";
import { NavigateOptions, useLocation, useSearchParams } from "react-router-dom";
import { Notifications } from "../utilities/Notifications/Notifications";
import { useView } from "./useView";
import useMediaSources from "./useMediaSources";

export const TIMESTAMP_QUERY_KEY = "t" as const;
export const PROJECT_ID_QUERY_KEY = "projectId" as const;
export const MEDIA_ID_QUERY_KEY = "mediaId" as const;
export const RUN_IDS_QUERY_KEY = "runIds" as const;

const PROJECT_ID_STATE_UPDATE_KEY = "isProjectIdUpdate" as const;

/** Helper function for building the URL query parameter string pointing to a specific media source.
 *
 *  Returned string does not start with the `?` symbol. */
export function buildMediaSourceQueryString(projectId: string, mediaId: string, timestamp?: number) {
    let queryString = `${PROJECT_ID_QUERY_KEY}=${projectId}&${MEDIA_ID_QUERY_KEY}=${mediaId}`;
    if (timestamp !== undefined) {
        queryString += `&${TIMESTAMP_QUERY_KEY}=${timestamp}`;
    }
    return queryString;
}

function useViewParams() {
    const location = useLocation();

    // All values are handled as search parameters so that we can change the URL without causing a reload AND keep history
    const [searchParams, setSearchParams] = useSearchParams();
    const projectIdParam = searchParams.get(PROJECT_ID_QUERY_KEY);
    const mediaIdParam = searchParams.get(MEDIA_ID_QUERY_KEY);
    const runIdsParam = searchParams.get(RUN_IDS_QUERY_KEY);
    const timestampParam = searchParams.get(TIMESTAMP_QUERY_KEY);

    /** Set the project ID query parameter, which will in turn update the active project ID */
    function setProjectIdParam(id: string) {
        if (!id) {
            return;
        }

        // If changing project ID, remove/reset all other parameters
        // NOTE: The additional state is used to tell the next media ID call to replace the URL in the history, allowing backwards navigation.
        setSearchParams({ [PROJECT_ID_QUERY_KEY]: id }, { state: { [PROJECT_ID_STATE_UPDATE_KEY]: true } });
    }

    /** Set the media ID query parameter, which will in turn update the active media source ID */
    function setMediaIdParam(id: string) {
        if (!projectIdParam || id === mediaIdParam) {
            return;
        }

        // Conditionally navigate using `replace = true` if the previous navigation was just to a new project ID.
        const opts: NavigateOptions = {};
        if (location.state?.[PROJECT_ID_STATE_UPDATE_KEY]) {
            opts.replace = true;
        }

        // If changing media ID, remove/reset all other parameters except project ID
        setSearchParams({ [PROJECT_ID_QUERY_KEY]: projectIdParam, [MEDIA_ID_QUERY_KEY]: id }, opts);
    }

    return { projectIdParam, mediaIdParam, runIdsParam, timestampParam, setProjectIdParam, setMediaIdParam };
}

function useViewMedia(projectId: string, viewId: string, mediaIdParam: string, setMediaIdParam: (_: string) => void) {
    const { view } = useView(viewId);

    // Keep track of active media source, as new views are to be created for each media source
    const [mediaId, setMediaId] = useState<string | undefined>(undefined);
    // Is only set to true when the media ID parameter is found to be invalid
    const [isInvalidMediaId, setIsInvalidMediaId] = useState(false);

    const { allMediaSources, hasLoadedMediaSources, activeMediaSource, hasLoadedActiveMedia } = useMediaSources(
        viewId,
        mediaId,
    );

    const areMediaSourcesForCurrentProject = projectId === allMediaSources?.[0]?.projectId;
    const isMediaSourcesEmpty = hasLoadedMediaSources && allMediaSources.length == 0;
    const firstMediaId =
        hasLoadedMediaSources && areMediaSourcesForCurrentProject ? allMediaSources?.[0]?.mediaId : undefined;

    function setFallbackMediaIdParam() {
        // Fallback to selected view's media source
        if (view?.data?.hasAssociatedMedia && view?.projectId === projectId) {
            // check if the current view has a source and it has valid runs
            const fragmentGroupDataSource = view?.data?.source as FragmentGroupDataSource;
            if (!fragmentGroupDataSource?.runs?.length) {
                return;
            }

            const selectedMediaId = fragmentGroupDataSource?.runs[0].mediaId;
            if (selectedMediaId) {
                setMediaIdParam(selectedMediaId);
            }
        }
        // Fallback to first available media source
        else if (firstMediaId !== undefined) {
            setMediaIdParam(firstMediaId);
        }
    }

    useEffect(() => {
        if (!projectId || !hasLoadedMediaSources || !areMediaSourcesForCurrentProject) {
            return;
        }

        if (!mediaIdParam) {
            setFallbackMediaIdParam();
            return;
        }

        const isValidMediaId = allMediaSources?.find((m) => m.mediaId == mediaIdParam) !== undefined;
        if (isValidMediaId) {
            setMediaId(mediaIdParam);
        } else {
            setIsInvalidMediaId(true);
        }
    }, [mediaIdParam, projectId, hasLoadedMediaSources, areMediaSourcesForCurrentProject]);

    return {
        mediaId,
        setMediaId: setMediaIdParam,
        isInvalidMediaId,
        allMediaSources,
        hasLoadedMediaSources,
        isMediaSourcesEmpty,
        activeMediaSource,
        hasLoadedActiveMedia,
    };
}

/** Manage initialisation and updating of views and associated media sources based on the currently active project.
 *
 * # IMPORTANT
 * Use only once per view to avoid conflicts. */
export function useManageViews() {
    const dispatch = useDispatch();

    const { analytics, general } = useSelector((state: ApplicationState) => state);
    const projectId = general?.activeProjectId;
    const { views, viewCount, primaryViewId: viewId } = analytics;
    const { view } = useView(viewId);
    const isInitialLoad = view === undefined;

    const { projectIdParam, mediaIdParam, runIdsParam, timestampParam, setProjectIdParam, setMediaIdParam } =
        useViewParams();

    // Sync project ID with query parameter when the project ID is changed
    useEffect(() => {
        if (projectId && projectIdParam !== projectId) {
            // This means that a link with a specified project is being loaded, so don't force
            // it to sync with the default / initial project ID
            if (isInitialLoad && projectIdParam) {
                return;
            }
            setProjectIdParam(projectId);
        }
    }, [projectId]);
    // Sync project ID with query parameter when the query parameter is changed
    useEffect(() => {
        // Rely on project ID so that the parameter is guaranteed to be set after the initial project ID
        // is defined.
        if (projectIdParam && projectId && projectIdParam !== projectId) {
            dispatch(GeneralStore.actionCreators.setActiveProject(projectIdParam));
        }
    }, [projectIdParam, projectId]);

    /** All the views for the active project. */
    const projectViews = useMemo(() => {
        return Object.values(views ?? {}).filter((v) => v.projectId == projectId);
    }, [projectId, viewCount]);

    const {
        mediaId,
        setMediaId,
        isInvalidMediaId,
        allMediaSources,
        hasLoadedMediaSources,
        isMediaSourcesEmpty,
        activeMediaSource,
        hasLoadedActiveMedia,
    } = useViewMedia(projectIdParam, viewId, mediaIdParam, setMediaIdParam);

    /** Represents whether the active project is ready for display, i.e. its media sources have been loaded and at least
     * one view for it exists. */
    const projectIsReady = hasLoadedMediaSources && projectViews.length > 0;

    function getRuns(): AnalyticsRun[] {
        let runs: AnalyticsRun[] = [];

        // Only base runs off the `runIdsParam` if it is for the originally queried media source
        if (runIdsParam && mediaId == mediaIdParam) {
            const existingRunIds = activeMediaSource.runs.map((r) => r.runId);
            const split = runIdsParam.split("-");

            const runIds = split.map(parseInt).filter((runId) => !isNaN(runId) && existingRunIds.includes(runId));
            const uniqueRunIds = [...new Set(runIds)];

            runs = uniqueRunIds.map((runId) => ({
                projectId: activeMediaSource.projectId,
                mediaId: activeMediaSource.mediaId,
                runId: runId,
            }));

            if (split.length > runs.length) {
                Notifications.notify(
                    "Invalid run IDs",
                    "One or more of the run IDs provided in the URL are invalid.",
                    "warning",
                );
            }
        }

        if (runs.length === 0) {
            if (activeMediaSource.runs.length) {
                runs = activeMediaSource.runs.map((run) => ({
                    projectId: activeMediaSource.projectId,
                    mediaId: activeMediaSource.mediaId,
                    runId: run.runId,
                }));
            } else {
                Notifications.notify(
                    "No valid runs",
                    `No valid analysis runs were found for the media source "${activeMediaSource.name}"`,
                    "important",
                    `no-runs-${mediaId}`,
                );
            }
        }

        return runs;
    }

    // Handle initial view creation and switching views when the media ID has changed
    useEffect(() => {
        if (!hasLoadedActiveMedia) {
            return;
        }

        const runs: AnalyticsRun[] = getRuns();
        // If view already exists, just switch to it
        for (const v of projectViews) {
            if (v.data.source.type === "frag-group" && v.data.source.isSourceFor(runs)) {
                dispatch(AnalyticsStore.actionCreators.setPrimaryView(v.viewId));
                return;
            }
        }

        const source = new FragmentGroupDataSource(mediaId, runs, dispatch);
        dispatch(AnalyticsStore.actionCreators.addView(source, true, activeMediaSource.name, projectId));
    }, [mediaId, hasLoadedActiveMedia]);

    // Handle loading data for new views
    useEffect(() => {
        if (view?.data.state == "unloaded") {
            dispatch(AnalyticsStore.actionCreators.loadViewData(viewId));
        }
    }, [viewId, view?.data.state]);

    return {
        projectIsReady,
        isInvalidMediaId,
        mediaId,
        setMediaId,
        mediaIdParam,
        timestampParam,
        hasLoadedActiveMedia,
        activeMediaSource,
        allMediaSources,
        hasLoadedMediaSources,
        isMediaSourcesEmpty,
        projectViews,
    };
}
