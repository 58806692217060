import { VizContainerProps } from "../VizContainer";
import "../../Viz.scss";
import { useView } from "../../../../hooks/useView";

export type VizFiltersProps = VizContainerProps;

export function VizFilters(props: VizFiltersProps) {
    const { view } = useView(props.viewId);

    if (view?.currentDataDescription === undefined) {
        return;
    }

    return (
        <div className="viz-filters viz-panel">
            <h1>Filter</h1>
            <div>
                {view !== undefined &&
                    view?.data.state == "loaded" &&
                    Object.entries(view.currentDataDescription.filters).map(([id, filter]) => {
                        return (
                            <div className="viz-filter" key={id}>
                                <div>{filter.displayName}</div>
                                {filter.createComponent(id, view)}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
