import { Entity } from "../../../models/viz/Entity";
import { DateEntityProperty } from "../../../models/viz/entityProperties/DateEntityProperty";

export class TimelineEntityVm extends Entity {
    startsAt: number;
    endsAt: number;
    dataRow: number;

    constructor(entity: Entity) {
        super(entity);

        this.dataRow = 0;
        this.startsAt = (this.properties["StartTime"] as DateEntityProperty).value().getTime();
        this.endsAt = (this.properties["EndTime"] as DateEntityProperty).value().getTime();
    }
}
