import { motion, AnimatePresence } from "framer-motion";
import "./LoadingLabel.scss";

function LoadingLabel(props: { isLoading: boolean }) {
    return (
        <AnimatePresence>
            {props.isLoading && (
                <motion.div
                    className="py-2 px-3 text-white rounded shadow-lg loading-label"
                    role="status"
                    key="loading-label"
                    initial={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    Loading
                    <span className="dot-1">.</span>
                    <span className="dot-2">.</span>
                    <span className="dot-3">.</span>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default LoadingLabel;
