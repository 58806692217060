import { StandardAction, StandardTagEventAction, TagEventPrototype } from "cloud-core/tags/TagEvent";
import { SetStateAction } from "react";
import { TAG_EVENT_STANDARD_ACTIONS } from "../../../../models/tags/TagEvent";
import { Form } from "react-bootstrap";

// Split up actions into groups based on prototype
const TAG_EVENT_STANDARD_ACTIONS_PROTOTYPE_GROUPING: Record<
    TagEventPrototype,
    { action: StandardAction; label: string }[]
> = Object.entries(TAG_EVENT_STANDARD_ACTIONS).reduce((acc, [key, val]) => {
    if (acc[val.prototype] === undefined) {
        acc[val.prototype] = [];
    }
    acc[val.prototype].push({ action: key, label: val.label });

    return acc;
}, Object.create(null));

export interface TagEventModalActionsProps {
    selectedAction: StandardTagEventAction;
    setSelectedAction: (_: SetStateAction<StandardTagEventAction>) => void;
    showLocationPrototype: boolean;
    showLocationCheck: boolean;
    setShowLocationCheck: (_: SetStateAction<boolean>) => void;
}

function TagEventModalActions(props: TagEventModalActionsProps) {
    return (
        <>
            {Object.entries(TAG_EVENT_STANDARD_ACTIONS_PROTOTYPE_GROUPING).map(([prototype, items]) => (
                <section key={prototype}>
                    {items.map(({ action, label }, i) => (
                        <Form.Label key={i} className="gap-2 d-flex">
                            <Form.Check
                                type="radio"
                                checked={action == props.selectedAction?.action}
                                onChange={() =>
                                    props.setSelectedAction({
                                        action,
                                        type: "standard",
                                    })
                                }
                            />
                            {label}
                        </Form.Label>
                    ))}
                    <hr />
                </section>
            ))}
            <Form.Label className="gap-2 d-flex">
                Include location?
                <Form.Check
                    disabled={props.showLocationPrototype}
                    checked={props.showLocationCheck || props.showLocationPrototype}
                    onChange={() => props.setShowLocationCheck(!props.showLocationCheck)}
                ></Form.Check>
            </Form.Label>
        </>
    );
}

export default TagEventModalActions;
