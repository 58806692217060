import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagObject } from "../../../../models/tags/TagObject";
import EditTagObjectModal from "../../editTagObjectModal/EditTagObjectModal";
import { VizContext } from "../../../../models/viz/operations/DataOperation";
import NiceModal from "@ebay/nice-modal-react";

export interface TagEventModalAddTagObjectButtonProps {
    context: VizContext;
    onCreatedCallback: (obj: TagObject) => void;
}
export function TagEventModalAddTagObjectButton(props: TagEventModalAddTagObjectButtonProps) {
    function handleOnClick(e: React.MouseEvent) {
        e.preventDefault();

        NiceModal.show(EditTagObjectModal, {
            context: props.context,
        }).then((newTagObject: TagObject) => {
            props.onCreatedCallback(newTagObject);
        });
    }

    return (
        <button className="button-with-icon" onClick={handleOnClick}>
            <FontAwesomeIcon icon={faPlusSquare} />
        </button>
    );
}
