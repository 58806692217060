import { MutableRefObject, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user/User";
import TotpFormGroup from "./components/TotpFormGroup";
import SpinnerButton from "../../components/SpinnerButton";

export interface TotpInputProps {
    onSubmitCallbackRef: MutableRefObject<() => void>;
}

function TotpInput(props: TotpInputProps) {
    const dispatch = useDispatch();
    const isUpdatingAuth =
        useSelector((state: ApplicationState) => state.user?.loginIssueParams?.isUpdatingAuth) ?? false;

    const [totp, setTotp] = useState("");

    if (props.onSubmitCallbackRef?.current) {
        props.onSubmitCallbackRef.current = () => dispatch(UserStore.actionCreators.completeTotpChallenge(totp));
    }

    return (
        <div className="text-center">
            <h3>Enter verification code</h3>
            <div>Input the verification code generated by your 2FA device</div>

            <TotpFormGroup totp={totp} setTotp={setTotp} />

            <SpinnerButton
                className="mt-4"
                buttonProps={{
                    type: "submit",
                }}
                label="Confirm"
                isLoading={isUpdatingAuth}
            />
        </div>
    );
}

export default TotpInput;
