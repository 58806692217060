import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import "react-image-crop/src/ReactCrop.scss";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import {
    AnalyserConfigOption,
    AnalyserConfigOptionValue,
    AnalyserConfigOptionsMap,
} from "../../../../models/analysis/Config";
import "./ImportWizard.scss";
import ImportWizardOption from "./ImportWizardOption";
import { ImportWizardPagePropsBase } from "./ImportWizard";

export interface ImportWizardOptionsProps extends ImportWizardPagePropsBase {
    options: AnalyserConfigOptionsMap;
    value?: ImportWizardOptionsResults;
    previewImgUrl: string | undefined;
    previewVidUrl: string | undefined;
}

export interface ImportWizardOptionsResults {
    [key: string]: AnalyserConfigOptionValue;
}

function ImportWizardOptions(props: ImportWizardOptionsProps) {
    const defaults = Object.fromEntries(Object.entries(props.options).map(([key, option]) => [key, option.default]));
    const initialResults = props.value ?? defaults;

    const [results, setResults] = useState<ImportWizardOptionsResults>(initialResults);
    const isLoaded = results !== undefined;
    const displayedOptions: [string, AnalyserConfigOption][] = [];

    if (isLoaded) {
        for (const key in props.options) {
            const option = props.options[key];

            // Skip rendering option based on the dependsOn attribute
            if (option.dependsOn !== undefined && results[option.dependsOn.option] !== option.dependsOn.value) {
                continue;
            }

            displayedOptions.push([key, option]);
        }
    }

    useEffect(() => {
        props.dataChanged(results);
    }, [results]);

    return (
        <section className="gap-3 import-wizard-options d-flex flex-column">
            <ConditionalSpinner isLoading={!isLoaded}>
                <AnimatePresence initial={false}>
                    {displayedOptions.map(([key, option]) => (
                        <motion.div
                            key={option.displayName}
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <ImportWizardOption
                                option={option}
                                value={results[key]}
                                onChanged={(update) => setResults((oldResults) => ({ ...oldResults, [key]: update }))}
                                previewVidUrl={props.previewVidUrl}
                                previewImgUrl={props.previewImgUrl}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </ConditionalSpinner>
        </section>
    );
}

export default ImportWizardOptions;
