import SourceInfo from "../components/MediaSourceInfo";
import MediaList from "./MediaList";
import { useEffect, useMemo, useState } from "react";
import { useGetAllMediaQuery } from "../../../store/api/kinesense";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import NoMediaSourcesErrorPage from "./NoMediaSourcesErrorPage";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";

function MockDem() {
    const activeProjectId = useSelector((state: ApplicationState) => state.general.activeProjectId);
    const { data: allMediaSources, isFetching: isLoadingMediaSources } = useGetAllMediaQuery(
        activeProjectId === undefined
            ? skipToken
            : {
                  projectId: activeProjectId,
              },
    );
    const isMediaSourcesEmpty = allMediaSources?.length == 0;

    const [activeSourceId, setActiveSourceId] = useState<string>(undefined);

    const currentSource = useMemo(() => {
        if (allMediaSources === undefined || allMediaSources.length < 1 || activeSourceId === undefined) {
            return;
        }

        return allMediaSources.find((m) => m.mediaId == activeSourceId);
    }, [activeSourceId]);

    function handleSourceChange(e: React.MouseEvent) {
        const mediaId = e.currentTarget.id;
        setActiveSourceId(mediaId);
    }

    // Display info for first media source by default when component mounts
    useEffect(() => {
        if (isLoadingMediaSources || isMediaSourcesEmpty) {
            return;
        }

        setActiveSourceId(allMediaSources[0].mediaId);
    }, [isLoadingMediaSources, isMediaSourcesEmpty]);

    return (
        <ConditionalSpinner isLoading={isLoadingMediaSources}>
            {isMediaSourcesEmpty ? (
                <NoMediaSourcesErrorPage />
            ) : (
                <div className="container">
                    <div className="row">
                        <div className="col-1 col-sm-4">
                            <MediaList
                                mediaList={allMediaSources}
                                activeSourceId={activeSourceId}
                                clickHandler={handleSourceChange}
                            />
                        </div>
                        <div className="col-2 col-sm-6">
                            {currentSource && <SourceInfo source={currentSource} isDisabled={false} />}
                        </div>
                    </div>
                </div>
            )}
        </ConditionalSpinner>
    );
}

export default MockDem;
